import { ScrollArea } from "@mantine/core";
import React, { useEffect, useState } from "react";
import {
  WikiData,
  getWikipediaPlotData,
} from "../../../utils/wikipedia/wikiapi";
import { getInitialWordsMap, updateMapWithNewWord } from "../utils/wordHelpers";
import GuessedWordsTable from "./GuessedWordsTable";
import Inputs from "./Inputs";
import RedactedSection from "./RedactedSection";
import WinnerScreen from "./WinnerScreen";

const isWinner = (wordsMap: Map<string, number>, movieName: string) => {
  if (movieName === "") {
    return false;
  }
  if (wordsMap.has(movieName.toLowerCase())) {
    return true;
  }

  const movieNameArr = movieName.split(" ");
  for (let i = 0; i < movieNameArr.length; i++) {
    if (!wordsMap.has(movieNameArr[i].toLowerCase())) {
      return false;
    }
  }
  return true;
};

const Description = () => {
  const [wikiMoviePlotData, setwikiMoviePlotData] = useState<WikiData>({
    name: "",
    plotText: [],
    pickedIndex: 0,
    updatedArr: [],
  });
  const [wordsMap, setWordsMap] = useState<Map<string, number>>(
    getInitialWordsMap()
  );
  const [guessedWordsInput, setGuessedWordsInput] = useState<string>("");
  const [recentlyGuessedWord, setRecentlyGuessedWord] = useState<string>("");

  const setup = async () => {
    const wikiMoviePlotDataResult = await getWikipediaPlotData();
    setwikiMoviePlotData(wikiMoviePlotDataResult);
  };

  useEffect(() => {
    setup();
  }, []);

  const resetGame = () => {
    setRecentlyGuessedWord("");
    setGuessedWordsInput("");
    setWordsMap(getInitialWordsMap());
    setup();
  };

  const handleSubmit = () => {
    setWordsMap(
      updateMapWithNewWord(
        guessedWordsInput,
        wordsMap,
        wikiMoviePlotData.plotText
      )
    );
    setRecentlyGuessedWord(guessedWordsInput);
    setGuessedWordsInput("");
  };
  const isWinnerResult = isWinner(wordsMap, wikiMoviePlotData.name);
  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        {!isWinnerResult ? (
          <>
            <div style={{
              width: "80%",
              border: "solid"
            }}>
              <ScrollArea.Autosize maxHeight={"80vh"}
              >
                <h2>
                  <RedactedSection
                    textArr={wikiMoviePlotData.name.split(" ")}
                    wordsMap={wordsMap}
                    recentlyGuessedWord={recentlyGuessedWord}
                  />
                </h2>
                <RedactedSection
                  textArr={wikiMoviePlotData.plotText}
                  wordsMap={wordsMap}
                  recentlyGuessedWord={recentlyGuessedWord}
                />
              </ScrollArea.Autosize>
            </div>
            <GuessedWordsTable wordsMap={wordsMap} />
          </>
        ) : (
          <>
            <WinnerScreen
              movieName={wikiMoviePlotData.name}
              resetGame={resetGame}
            />
          </>
        )}
      </div>
      <footer
        style={{
          bottom: 0,
        }}
      >
        <Inputs
          handleSubmit={handleSubmit}
          setGuessedWordsInput={setGuessedWordsInput}
          guessedWordsInput={guessedWordsInput}
        />
      </footer>
    </>
  );
};

export default Description;
