import { WikiObject } from "./wikiapi";

export const starWarsMovies: WikiObject[] = [
  {
    name: "Star Wars The Phantom Menace",
    wikiPageTitle: "Star_Wars:_Episode_I_–_The_Phantom_Menace",
  },
  {
    name: "Star Wars Attack of the Clones",
    wikiPageTitle: "Star_Wars:_Episode_II_–_Attack_of_the_Clones",
  },
  {
    name: "Star Wars Revenge of the Sith",
    wikiPageTitle: "Star Wars Revenge of the Sith",
  },
  {
    name: "Star Wars A New Hope",
    wikiPageTitle: "Star_Wars_(film)",
  },
  {
    name: "Star Wars The Empire Strikes Back",
    wikiPageTitle: "The_Empire_Strikes_Back",
  },
  {
    name: "Star Wars Return of the Jedi",
    wikiPageTitle: "Return_of_the_Jedi",
  },
  {
    name: "Star Wars The Force Awakens",
    wikiPageTitle: "Star_Wars:_The_Force_Awakens",
  },
  {
    name: "Star Wars The Last Jedi",
    wikiPageTitle: "Star_Wars:_The_Last_Jedi",
  },
  {
    name: "Star Wars The Rise of Skywalker",
    wikiPageTitle: "Star_Wars:_The_Rise_of_Skywalker",
  },
  {
    name: "Rogue One",
    wikiPageTitle: "Rogue_One",
  },
  {
    name: "Solo",
    wikiPageTitle: "Solo:_A_Star_Wars_Story",
  },
];
