import React, { useEffect, useState } from "react";
import {
  getGameStartingNumbers,
  isInteger,
  parseEvaluation,
} from "./countdownUtils";
import CurrentAnswer from "./CurrentAnswer";
import CurrentInput from "./CurrentInput";
import NewGame from "./NewGameButton";
import NumberButtons from "./NumberButtons";
import OperationButtons from "./OperationButtons";
import ResetButton from "./ResetButton";
import TargetNumber from "./TargetNumber";
import WinnerScreen from "./WinnerScreen";

const Countdown = () => {
  const [targetNumber, setTargetNumber] = useState(0);
  const [sixNums, setSixNums] = useState<number[]>([]);
  const [selectedNumIndexes, setSelectedNumIndexes] = useState<number[]>([]);
  const [isWinner, setIsWinner] = useState(false);
  const [currentEval, setCurrentEval] = useState("");
  const [areNumbersDisabled, setNumbersDisabled] = useState(false);
  useEffect(() => {
    initializeGame();
  }, []);

  const initializeGame = () => {
    setIsWinner(false);
    const startingNumbers = getGameStartingNumbers();
    setTargetNumber(startingNumbers.targetNumber);
    setSixNums(startingNumbers.sixNumbers);
    setCurrentEval("");
    setSelectedNumIndexes([]);
  };

  const handleNumClick = (num: number, index: number) => {
    setNumbersDisabled(true);
    const updatedEval = `${currentEval}${num}`;
    setCurrentEval(updatedEval);
    const evaluation = parseEvaluation(updatedEval);
    if (evaluation === targetNumber) {
      setIsWinner(true);
    }
    setSelectedNumIndexes([...selectedNumIndexes, index]);
  };

  const handleOperationButtonClick = (input: string) => {
    setNumbersDisabled(false);
    const updatedEval = `${currentEval}${input}`;
    setCurrentEval(updatedEval);
    const evaluation = parseEvaluation(updatedEval);
    if (evaluation === targetNumber) {
      setIsWinner(true);
    }
  };

  const handleDelete = () => {
    const charToDelete = currentEval.charAt(currentEval.length - 1);
    if (isInteger(charToDelete)) {
      setNumbersDisabled(false);
      const selectedNumIndexesCopy = [...selectedNumIndexes];
      const index = selectedNumIndexesCopy.pop();
      const num = index !== undefined ? sixNums[index] : 0;
      const strNumRemoved = num.toString();
      const len = strNumRemoved ? strNumRemoved.length : 0;
      setSelectedNumIndexes(selectedNumIndexesCopy);
      setCurrentEval(currentEval.slice(0, -1 * len));
    } else {
      setCurrentEval(currentEval.slice(0, -1));
    }
  };

  const handleResetButtonClick = () => {
    setSelectedNumIndexes([]);
    setCurrentEval("");
    setNumbersDisabled(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          textAlign: "center",
          border: "solid",
          padding: "10px",
        }}
      >
        <>
          <h1>countdown</h1>
          {isWinner ? <WinnerScreen /> : <></>}
          <TargetNumber num={targetNumber} isWinner={isWinner} />
          <CurrentAnswer currentAnswer={parseEvaluation(currentEval)} />
          <CurrentInput text={currentEval} />
          {isWinner ? (
            <NewGame handleNewGameClick={initializeGame} />
          ) : (
            <>
              <NumberButtons
                isNumsDisabled={areNumbersDisabled}
                nums={sixNums}
                selectedNumIndexes={selectedNumIndexes}
                handleNumClick={handleNumClick}
              />
              <OperationButtons
                handlePress={handleOperationButtonClick}
                handleDelete={handleDelete}
              />
              <ResetButton
                disabled={currentEval === ""}
                handleResetButtonClick={handleResetButtonClick}
              />
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default Countdown;
