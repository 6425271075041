import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { NavBar } from "./NavBar";
import { Heading } from "./Heading";
import { AboutMe } from "./AboutMe";
import { Projects } from "./Projects";
import { ContactMe } from "./ContactMe";

export const Portfolio = () => {
  return (
    <>
      <NavBar />
      <Heading />
      <AboutMe />
      <Projects />
      <ContactMe />
    </>
  );
};
