export interface state {
  name: string;
  imgSrc: string;
}

export const allFiftyStates: state[] = [
  {
    name: "Alabama",
    imgSrc: "/assets/50states/Alabama-Outline-Map.jpg",
  },
  {
    name: "Alaska",
    imgSrc: "/assets/50states/Alaska-Outline-Map.jpg",
  },
  {
    name: "Arizona",
    imgSrc: "/assets/50states/Arizona-Outline-Map.jpg",
  },
  {
    name: "Arkansas",
    imgSrc: "/assets/50states/Arkansas-Outline-Map.jpg",
  },
  {
    name: "California",
    imgSrc: "/assets/50states/California-Outline-Map.jpg",
  },
  {
    name: "Colorado",
    imgSrc: "/assets/50states/Colorado-Outline-Map.jpg",
  },
  {
    name: "Connecticut",
    imgSrc: "/assets/50states/Connecticut-Outline-Map.jpg",
  },
  {
    name: "Delaware",
    imgSrc: "/assets/50states/Delaware-Outline-Map.jpg",
  },
  {
    name: "Florida",
    imgSrc: "/assets/50states/Florida-Outline-Map.jpg",
  },
  {
    name: "Georgia",
    imgSrc: "/assets/50states/Georgia-Outline-Map.jpg",
  },
  {
    name: "Hawaii",
    imgSrc: "/assets/50states/Hawaii-Outline-Map.jpg",
  },
  {
    name: "Idaho",
    imgSrc: "/assets/50states/Idaho-Outline-Map.jpg",
  },
  {
    name: "Illinois",
    imgSrc: "/assets/50states/Illinois-Outline-Map.jpg",
  },
  {
    name: "Indiana",
    imgSrc: "/assets/50states/Indiana-Outline-Map.jpg",
  },
  {
    name: "Iowa",
    imgSrc: "/assets/50states/Iowa-Outline-Map.jpg",
  },
  {
    name: "Kansas",
    imgSrc: "/assets/50states/Kansas-Outline-Map.jpg",
  },
  {
    name: "Kentucky",
    imgSrc: "/assets/50states/Kentucky-Outline-Map.jpg",
  },
  {
    name: "Louisiana",
    imgSrc: "/assets/50states/Louisiana-Outline-Map.jpg",
  },
  {
    name: "Maine",
    imgSrc: "/assets/50states/Maine-Outline-Map.jpg",
  },
  {
    name: "Maryland",
    imgSrc: "/assets/50states/Maryland-Outline-Map.jpg",
  },
  {
    name: "Massachusetts",
    imgSrc: "/assets/50states/Massachusetts-Outline-Map.jpg",
  },
  {
    name: "Michigan",
    imgSrc: "/assets/50states/Michigan-Outline-Map.jpg",
  },
  {
    name: "Minnesota",
    imgSrc: "/assets/50states/Minnesota-Outline-Map.jpg",
  },
  {
    name: "Mississippi",
    imgSrc: "/assets/50states/Mississippi-Outline-Map.jpg",
  },
  {
    name: "Missouri",
    imgSrc: "/assets/50states/Missouri-Outline-Map.jpg",
  },
  {
    name: "Montana",
    imgSrc: "/assets/50states/Montana-Outline-Map.jpg",
  },
  {
    name: "Nebraska",
    imgSrc: "/assets/50states/Nebraska-Outline-Map.jpg",
  },
  {
    name: "Nevada",
    imgSrc: "/assets/50states/Nevada-Outline-Map.jpg",
  },
  {
    name: "New Hampshire",
    imgSrc: "/assets/50states/New-Hampshire-Outline-Map.jpg",
  },
  {
    name: "New Jersey",
    imgSrc: "/assets/50states/New-Jersey-Outline-Map.jpg",
  },
  {
    name: "New Mexico",
    imgSrc: "/assets/50states/New-Mexico-Outline-Map.jpg",
  },
  {
    name: "New York",
    imgSrc: "/assets/50states/New-York-Outline-Map.jpg",
  },
  {
    name: "North Carolina",
    imgSrc: "/assets/50states/North-Carolina-Outline-Map.jpg",
  },
  {
    name: "North Dakota",
    imgSrc: "/assets/50states/North-Dakota-Outline-Map.jpg",
  },
  {
    name: "Ohio",
    imgSrc: "/assets/50states/Ohio-Outline-Map.jpg",
  },
  {
    name: "Oklahoma",
    imgSrc: "/assets/50states/Oklahoma-Outline-Map.jpg",
  },
  {
    name: "Oregon",
    imgSrc: "/assets/50states/Oregon-Outline-Map.jpg",
  },
  {
    name: "Pennsylvania",
    imgSrc: "/assets/50states/Pennsylvania-Outline-Map.jpg",
  },
  {
    name: "Rhode Island",
    imgSrc: "/assets/50states/Rhode-Island-Outline-Map.jpg",
  },
  {
    name: "South Carolina",
    imgSrc: "/assets/50states/South-Carolina-Outline-Map.jpg",
  },
  {
    name: "South Dakota",
    imgSrc: "/assets/50states/South-Dakota-Outline-Map.jpg",
  },
  {
    name: "Tennessee",
    imgSrc: "/assets/50states/Tennessee-Outline-Map.jpg",
  },
  {
    name: "Texas",
    imgSrc: "/assets/50states/Texas-Outline-Map.jpg",
  },
  {
    name: "Utah",
    imgSrc: "/assets/50states/Utah-Outline-Map.jpg",
  },
  {
    name: "Vermont",
    imgSrc: "/assets/50states/Vermont-Outline-Map.jpg",
  },
  {
    name: "Virginia",
    imgSrc: "/assets/50states/Virginia-Outline-Map.jpg",
  },
  {
    name: "Washington",
    imgSrc: "/assets/50states/Washington-Outline-Map.jpg",
  },
  {
    name: "West Virginia",
    imgSrc: "/assets/50states/West-Virginia-Outline-Map.jpg",
  },
  {
    name: "Wisconsin",
    imgSrc: "/assets/50states/Wisconsin-Outline-Map.jpg",
  },
  {
    name: "Wyoming",
    imgSrc: "/assets/50states/Wyoming-Outline-Map.jpg",
  },
];
