export interface DailyHilo {
  target: number;
  startingNumbers: number[];
}

const dailyHilos: { [key: string]: DailyHilo } = {
  "0-0": { target: 13, startingNumbers: [1, 2, 4, 8] },
  "0-1": { target: -20, startingNumbers: [4, 7, 8, 9] },
  "0-2": { target: 3, startingNumbers: [1, 2, 4, 8] },
  "0-3": { target: -7, startingNumbers: [2, 6, 7, 8] },
  "0-4": { target: -10, startingNumbers: [4, 5, 8, 9] },
  "0-5": { target: 14, startingNumbers: [4, 7, 8, 9] },
  "0-6": { target: -17, startingNumbers: [1, 4, 5, 7] },
  "0-7": { target: -12, startingNumbers: [1, 5, 7, 9] },
  "0-8": { target: -19, startingNumbers: [2, 6, 7, 8] },
  "0-9": { target: 2, startingNumbers: [2, 3, 5, 8] },
  "0-10": { target: -5, startingNumbers: [3, 5, 6, 9] },
  "0-11": { target: 1, startingNumbers: [1, 3, 7, 8] },
  "0-12": { target: -9, startingNumbers: [2, 6, 7, 8] },
  "0-13": { target: 15, startingNumbers: [2, 3, 4, 6] },
  "0-14": { target: 25, startingNumbers: [4, 5, 7, 9] },
  "0-15": { target: 2, startingNumbers: [2, 3, 4, 7] },
  "0-16": { target: -9, startingNumbers: [1, 7, 8, 9] },
  "0-17": { target: -4, startingNumbers: [2, 3, 4, 5] },
  "0-18": { target: -14, startingNumbers: [4, 5, 6, 9] },
  "0-19": { target: 3, startingNumbers: [1, 2, 5, 9] },
  "0-20": { target: -18, startingNumbers: [2, 4, 7, 9] },
  "0-21": { target: 6, startingNumbers: [1, 3, 5, 7] },
  "0-22": { target: -7, startingNumbers: [2, 6, 7, 8] },
  "0-23": { target: -5, startingNumbers: [1, 3, 7, 8] },
  "0-24": { target: 0, startingNumbers: [3, 5, 6, 8] },
  "0-25": { target: 9, startingNumbers: [2, 3, 4, 6] },
  "0-26": { target: 3, startingNumbers: [1, 6, 7, 9] },
  "0-27": { target: -2, startingNumbers: [2, 3, 6, 7] },
  "0-28": { target: 6, startingNumbers: [3, 5, 6, 8] },
  "0-29": { target: 16, startingNumbers: [1, 2, 4, 9] },
  "0-30": { target: 0, startingNumbers: [2, 3, 6, 7] },
  "0-31": { target: -4, startingNumbers: [1, 2, 4, 5] },
  "1-0": { target: 6, startingNumbers: [1, 3, 5, 7] },
  "1-1": { target: -5, startingNumbers: [1, 5, 8, 9] },
  "1-2": { target: -4, startingNumbers: [2, 3, 6, 7] },
  "1-3": { target: 19, startingNumbers: [1, 2, 7, 9] },
  "1-4": { target: -16, startingNumbers: [5, 6, 8, 9] },
  "1-5": { target: -13, startingNumbers: [2, 5, 7, 9] },
  "1-6": { target: -15, startingNumbers: [2, 3, 7, 9] },
  "1-7": { target: -15, startingNumbers: [2, 3, 5, 9] },
  "1-8": { target: -11, startingNumbers: [1, 2, 3, 7] },
  "1-9": { target: -12, startingNumbers: [2, 5, 6, 9] },
  "1-10": { target: 7, startingNumbers: [2, 4, 7, 8] },
  "1-11": { target: 1, startingNumbers: [2, 3, 5, 7] },
  "1-12": { target: 14, startingNumbers: [1, 3, 4, 8] },
  "1-13": { target: 5, startingNumbers: [1, 2, 6, 8] },
  "1-14": { target: 7, startingNumbers: [2, 4, 5, 8] },
  "1-15": { target: 6, startingNumbers: [1, 2, 3, 8] },
  "1-16": { target: -8, startingNumbers: [2, 3, 5, 8] },
  "1-17": { target: -4, startingNumbers: [1, 3, 4, 6] },
  "1-18": { target: -4, startingNumbers: [1, 2, 3, 4] },
  "1-19": { target: -7, startingNumbers: [4, 5, 6, 8] },
  "1-20": { target: -16, startingNumbers: [1, 2, 4, 9] },
  "1-21": { target: -13, startingNumbers: [1, 2, 4, 6] },
  "1-22": { target: 2, startingNumbers: [1, 2, 3, 6] },
  "1-23": { target: 12, startingNumbers: [3, 4, 6, 7] },
  "1-24": { target: -6, startingNumbers: [1, 6, 7, 8] },
  "1-25": { target: -11, startingNumbers: [1, 3, 6, 7] },
  "1-26": { target: 8, startingNumbers: [2, 6, 7, 9] },
  "1-27": { target: 11, startingNumbers: [1, 4, 7, 9] },
  "1-28": { target: 5, startingNumbers: [1, 3, 4, 5] },
  "1-29": { target: -7, startingNumbers: [2, 4, 7, 8] },
  "1-30": { target: 27, startingNumbers: [4, 6, 8, 9] },
  "1-31": { target: 3, startingNumbers: [1, 2, 5, 9] },
  "2-0": { target: 14, startingNumbers: [1, 4, 5, 6] },
  "2-1": { target: -1, startingNumbers: [1, 4, 7, 9] },
  "2-2": { target: 4, startingNumbers: [2, 5, 6, 9] },
  "2-3": { target: -14, startingNumbers: [2, 3, 5, 8] },
  "2-4": { target: 4, startingNumbers: [3, 4, 5, 6] },
  "2-5": { target: 6, startingNumbers: [1, 3, 4, 6] },
  "2-6": { target: 15, startingNumbers: [1, 3, 5, 6] },
  "2-7": { target: -1, startingNumbers: [3, 6, 7, 9] },
  "2-8": { target: 1, startingNumbers: [3, 5, 6, 9] },
  "2-9": { target: -12, startingNumbers: [3, 4, 6, 7] },
  "2-10": { target: 8, startingNumbers: [1, 3, 5, 9] },
  "2-11": { target: 21, startingNumbers: [2, 5, 6, 8] },
  "2-12": { target: -18, startingNumbers: [4, 5, 8, 9] },
  "2-13": { target: -13, startingNumbers: [3, 4, 6, 8] },
  "2-14": { target: 21, startingNumbers: [2, 6, 8, 9] },
  "2-15": { target: 7, startingNumbers: [2, 3, 4, 8] },
  "2-16": { target: -11, startingNumbers: [1, 2, 3, 7] },
  "2-17": { target: 6, startingNumbers: [2, 6, 7, 9] },
  "2-18": { target: 5, startingNumbers: [4, 5, 6, 8] },
  "2-19": { target: 13, startingNumbers: [1, 2, 4, 8] },
  "2-20": { target: 26, startingNumbers: [3, 6, 8, 9] },
  "2-21": { target: 5, startingNumbers: [1, 6, 7, 9] },
  "2-22": { target: 12, startingNumbers: [4, 5, 6, 7] },
  "2-23": { target: 0, startingNumbers: [3, 4, 7, 8] },
  "2-24": { target: -12, startingNumbers: [1, 4, 8, 9] },
  "2-25": { target: -11, startingNumbers: [2, 4, 8, 9] },
  "2-26": { target: 6, startingNumbers: [1, 6, 8, 9] },
  "2-27": { target: 18, startingNumbers: [3, 6, 7, 8] },
  "2-28": { target: 3, startingNumbers: [3, 4, 5, 9] },
  "2-29": { target: 12, startingNumbers: [1, 2, 4, 9] },
  "2-30": { target: 2, startingNumbers: [1, 2, 6, 7] },
  "2-31": { target: 17, startingNumbers: [5, 6, 7, 9] },
  "3-0": { target: -24, startingNumbers: [1, 6, 8, 9] },
  "3-1": { target: 8, startingNumbers: [3, 4, 7, 8] },
  "3-2": { target: -21, startingNumbers: [2, 4, 6, 9] },
  "3-3": { target: 5, startingNumbers: [2, 6, 7, 8] },
  "3-4": { target: 4, startingNumbers: [3, 6, 7, 8] },
  "3-5": { target: 21, startingNumbers: [1, 6, 7, 9] },
  "3-6": { target: 21, startingNumbers: [1, 5, 8, 9] },
  "3-7": { target: -21, startingNumbers: [2, 6, 8, 9] },
  "3-8": { target: -8, startingNumbers: [2, 4, 7, 9] },
  "3-9": { target: 2, startingNumbers: [1, 2, 8, 9] },
  "3-10": { target: -14, startingNumbers: [4, 6, 7, 9] },
  "3-11": { target: -6, startingNumbers: [3, 4, 6, 7] },
  "3-12": { target: 4, startingNumbers: [1, 2, 6, 7] },
  "3-13": { target: 5, startingNumbers: [2, 4, 6, 7] },
  "3-14": { target: 17, startingNumbers: [1, 2, 7, 9] },
  "3-15": { target: 16, startingNumbers: [4, 5, 8, 9] },
  "3-16": { target: -18, startingNumbers: [1, 3, 7, 9] },
  "3-17": { target: 5, startingNumbers: [2, 6, 8, 9] },
  "3-18": { target: 21, startingNumbers: [2, 4, 7, 8] },
  "3-19": { target: 15, startingNumbers: [2, 4, 6, 7] },
  "3-20": { target: 19, startingNumbers: [3, 5, 8, 9] },
  "3-21": { target: -2, startingNumbers: [1, 2, 4, 7] },
  "3-22": { target: -15, startingNumbers: [5, 6, 7, 9] },
  "3-23": { target: -13, startingNumbers: [3, 4, 6, 8] },
  "3-24": { target: 1, startingNumbers: [1, 3, 4, 9] },
  "3-25": { target: -4, startingNumbers: [2, 3, 4, 5] },
  "3-26": { target: -12, startingNumbers: [3, 4, 6, 7] },
  "3-27": { target: 11, startingNumbers: [1, 2, 4, 6] },
  "3-28": { target: -15, startingNumbers: [2, 3, 7, 9] },
  "3-29": { target: -3, startingNumbers: [1, 5, 8, 9] },
  "3-30": { target: 4, startingNumbers: [2, 4, 5, 7] },
  "3-31": { target: -6, startingNumbers: [2, 3, 4, 9] },
  "4-0": { target: 13, startingNumbers: [5, 6, 7, 9] },
  "4-1": { target: -1, startingNumbers: [2, 3, 6, 8] },
  "4-2": { target: -13, startingNumbers: [3, 7, 8, 9] },
  "4-3": { target: 13, startingNumbers: [3, 6, 7, 9] },
  "4-4": { target: 5, startingNumbers: [2, 5, 7, 9] },
  "4-5": { target: 17, startingNumbers: [2, 4, 6, 9] },
  "4-6": { target: -13, startingNumbers: [3, 5, 6, 9] },
  "4-7": { target: 24, startingNumbers: [4, 5, 7, 8] },
  "4-8": { target: -18, startingNumbers: [3, 5, 7, 9] },
  "4-9": { target: -13, startingNumbers: [2, 6, 8, 9] },
  "4-10": { target: 8, startingNumbers: [2, 5, 7, 8] },
  "4-11": { target: 0, startingNumbers: [1, 2, 8, 9] },
  "4-12": { target: -14, startingNumbers: [6, 7, 8, 9] },
  "4-13": { target: 10, startingNumbers: [2, 5, 6, 7] },
  "4-14": { target: 19, startingNumbers: [2, 6, 7, 8] },
  "4-15": { target: -6, startingNumbers: [2, 3, 5, 6] },
  "4-16": { target: -1, startingNumbers: [2, 3, 4, 6] },
  "4-17": { target: 2, startingNumbers: [3, 4, 6, 9] },
  "4-18": { target: 8, startingNumbers: [1, 3, 4, 8] },
  "4-19": { target: 20, startingNumbers: [2, 4, 5, 9] },
  "4-20": { target: -10, startingNumbers: [1, 3, 5, 7] },
  "4-21": { target: 17, startingNumbers: [2, 3, 5, 7] },
  "4-22": { target: -20, startingNumbers: [3, 6, 8, 9] },
  "4-23": { target: 0, startingNumbers: [1, 3, 4, 6] },
  "4-24": { target: -2, startingNumbers: [1, 3, 6, 8] },
  "4-25": { target: 0, startingNumbers: [1, 2, 4, 5] },
  "4-26": { target: -1, startingNumbers: [2, 4, 6, 9] },
  "4-27": { target: 12, startingNumbers: [1, 5, 7, 9] },
  "4-28": { target: 9, startingNumbers: [2, 4, 6, 9] },
  "4-29": { target: -8, startingNumbers: [1, 2, 3, 8] },
  "4-30": { target: -3, startingNumbers: [3, 4, 7, 9] },
  "4-31": { target: 7, startingNumbers: [1, 3, 4, 9] },
  "5-0": { target: 7, startingNumbers: [1, 2, 4, 6] },
  "5-1": { target: 13, startingNumbers: [1, 3, 8, 9] },
  "5-2": { target: -10, startingNumbers: [5, 6, 7, 8] },
  "5-3": { target: -13, startingNumbers: [1, 2, 5, 7] },
  "5-4": { target: 3, startingNumbers: [2, 3, 5, 7] },
  "5-5": { target: -1, startingNumbers: [3, 5, 7, 8] },
  "5-6": { target: 7, startingNumbers: [2, 4, 6, 7] },
  "5-7": { target: 2, startingNumbers: [2, 3, 4, 7] },
  "5-8": { target: -8, startingNumbers: [2, 5, 7, 8] },
  "5-9": { target: 13, startingNumbers: [3, 6, 7, 9] },
  "5-10": { target: 5, startingNumbers: [2, 3, 4, 6] },
  "5-11": { target: 15, startingNumbers: [1, 2, 3, 9] },
  "5-12": { target: 15, startingNumbers: [1, 3, 4, 7] },
  "5-13": { target: 2, startingNumbers: [4, 5, 6, 9] },
  "5-14": { target: 1, startingNumbers: [1, 2, 4, 6] },
  "5-15": { target: 15, startingNumbers: [1, 3, 8, 9] },
  "5-16": { target: 4, startingNumbers: [1, 2, 6, 7] },
  "5-17": { target: 6, startingNumbers: [1, 5, 6, 8] },
  "5-18": { target: -4, startingNumbers: [1, 3, 6, 8] },
  "5-19": { target: -21, startingNumbers: [1, 4, 7, 9] },
  "5-20": { target: 8, startingNumbers: [1, 3, 4, 6] },
  "5-21": { target: 15, startingNumbers: [1, 2, 4, 8] },
  "5-22": { target: -11, startingNumbers: [3, 5, 6, 9] },
  "5-23": { target: 5, startingNumbers: [1, 5, 6, 7] },
  "5-24": { target: -3, startingNumbers: [1, 2, 4, 6] },
  "5-25": { target: -13, startingNumbers: [1, 3, 4, 5] },
  "5-26": { target: -5, startingNumbers: [1, 4, 7, 9] },
  "5-27": { target: -16, startingNumbers: [2, 4, 5, 9] },
  "5-28": { target: 12, startingNumbers: [3, 6, 7, 8] },
  "5-29": { target: -8, startingNumbers: [1, 2, 4, 7] },
  "5-30": { target: 0, startingNumbers: [2, 5, 6, 9] },
  "5-31": { target: -2, startingNumbers: [1, 2, 4, 5] },
  "6-0": { target: -14, startingNumbers: [2, 5, 8, 9] },
  "6-1": { target: -16, startingNumbers: [2, 3, 4, 7] },
  "6-2": { target: 17, startingNumbers: [2, 3, 4, 8] },
  "6-3": { target: 5, startingNumbers: [1, 3, 5, 6] },
  "6-4": { target: 19, startingNumbers: [3, 5, 8, 9] },
  "6-5": { target: -13, startingNumbers: [3, 4, 5, 9] },
  "6-6": { target: 4, startingNumbers: [3, 4, 6, 9] },
  "6-7": { target: -12, startingNumbers: [1, 5, 7, 9] },
  "6-8": { target: 8, startingNumbers: [2, 7, 8, 9] },
  "6-9": { target: -17, startingNumbers: [3, 4, 7, 9] },
  "6-10": { target: 23, startingNumbers: [1, 7, 8, 9] },
  "6-11": { target: -22, startingNumbers: [2, 5, 6, 9] },
  "6-12": { target: -14, startingNumbers: [1, 2, 6, 7] },
  "6-13": { target: -5, startingNumbers: [1, 2, 3, 9] },
  "6-14": { target: 6, startingNumbers: [1, 5, 6, 8] },
  "6-15": { target: 0, startingNumbers: [2, 3, 6, 7] },
  "6-16": { target: -12, startingNumbers: [2, 7, 8, 9] },
  "6-17": { target: 9, startingNumbers: [1, 2, 4, 8] },
  "6-18": { target: -3, startingNumbers: [1, 5, 8, 9] },
  "6-19": { target: 15, startingNumbers: [2, 4, 8, 9] },
  "6-20": { target: -8, startingNumbers: [3, 4, 6, 7] },
  "6-21": { target: 14, startingNumbers: [4, 7, 8, 9] },
  "6-22": { target: -4, startingNumbers: [2, 5, 8, 9] },
  "6-23": { target: 10, startingNumbers: [2, 4, 7, 9] },
  "6-24": { target: 5, startingNumbers: [2, 4, 5, 6] },
  "6-25": { target: 0, startingNumbers: [5, 6, 8, 9] },
  "6-26": { target: 6, startingNumbers: [1, 6, 8, 9] },
  "6-27": { target: -4, startingNumbers: [3, 4, 5, 6] },
  "6-28": { target: 3, startingNumbers: [2, 4, 5, 6] },
  "6-29": { target: 14, startingNumbers: [2, 3, 4, 5] },
  "6-30": { target: 6, startingNumbers: [2, 5, 6, 9] },
  "6-31": { target: -10, startingNumbers: [3, 6, 8, 9] },
  "7-0": { target: 0, startingNumbers: [2, 4, 7, 9] },
  "7-1": { target: -5, startingNumbers: [1, 5, 7, 8] },
  "7-2": { target: -10, startingNumbers: [3, 4, 5, 6] },
  "7-3": { target: -9, startingNumbers: [1, 3, 6, 7] },
  "7-4": { target: -23, startingNumbers: [3, 4, 7, 9] },
  "7-5": { target: 6, startingNumbers: [1, 2, 3, 6] },
  "7-6": { target: 4, startingNumbers: [1, 2, 5, 8] },
  "7-7": { target: -7, startingNumbers: [1, 3, 6, 9] },
  "7-8": { target: -2, startingNumbers: [2, 3, 5, 8] },
  "7-9": { target: -20, startingNumbers: [4, 7, 8, 9] },
  "7-10": { target: -9, startingNumbers: [3, 5, 7, 8] },
  "7-11": { target: -11, startingNumbers: [1, 3, 4, 5] },
  "7-12": { target: 5, startingNumbers: [3, 4, 5, 9] },
  "7-13": { target: -4, startingNumbers: [2, 7, 8, 9] },
  "7-14": { target: -13, startingNumbers: [4, 6, 7, 8] },
  "7-15": { target: 5, startingNumbers: [1, 2, 4, 6] },
  "7-16": { target: -13, startingNumbers: [3, 5, 7, 8] },
  "7-17": { target: -4, startingNumbers: [3, 4, 5, 8] },
  "7-18": { target: -12, startingNumbers: [1, 5, 7, 9] },
  "7-19": { target: -6, startingNumbers: [3, 4, 8, 9] },
  "7-20": { target: -8, startingNumbers: [1, 4, 5, 8] },
  "7-21": { target: -7, startingNumbers: [1, 3, 5, 6] },
  "7-22": { target: -3, startingNumbers: [5, 6, 7, 9] },
  "7-23": { target: 10, startingNumbers: [1, 3, 4, 8] },
  "7-24": { target: -18, startingNumbers: [1, 2, 8, 9] },
  "7-25": { target: -1, startingNumbers: [1, 5, 6, 9] },
  "7-26": { target: 13, startingNumbers: [3, 7, 8, 9] },
  "7-27": { target: 25, startingNumbers: [4, 5, 7, 9] },
  "7-28": { target: -13, startingNumbers: [2, 4, 5, 6] },
  "7-29": { target: -6, startingNumbers: [1, 4, 5, 8] },
  "7-30": { target: -3, startingNumbers: [1, 4, 7, 9] },
  "7-31": { target: -27, startingNumbers: [5, 6, 7, 9] },
  "8-0": { target: 4, startingNumbers: [2, 5, 6, 7] },
  "8-1": { target: 1, startingNumbers: [1, 3, 6, 7] },
  "8-2": { target: -16, startingNumbers: [4, 5, 7, 8] },
  "8-3": { target: 13, startingNumbers: [2, 4, 5, 6] },
  "8-4": { target: 0, startingNumbers: [1, 2, 5, 8] },
  "8-5": { target: -12, startingNumbers: [2, 3, 5, 6] },
  "8-6": { target: -9, startingNumbers: [2, 3, 4, 8] },
  "8-7": { target: -13, startingNumbers: [5, 6, 7, 9] },
  "8-8": { target: -18, startingNumbers: [2, 3, 6, 7] },
  "8-9": { target: 5, startingNumbers: [2, 6, 8, 9] },
  "8-10": { target: 8, startingNumbers: [1, 5, 6, 8] },
  "8-11": { target: -16, startingNumbers: [5, 6, 8, 9] },
  "8-12": { target: -12, startingNumbers: [1, 3, 6, 8] },
  "8-13": { target: 2, startingNumbers: [3, 6, 7, 8] },
  "8-14": { target: -10, startingNumbers: [1, 2, 3, 6] },
  "8-15": { target: -16, startingNumbers: [5, 6, 7, 8] },
  "8-16": { target: -10, startingNumbers: [1, 4, 6, 9] },
  "8-17": { target: -9, startingNumbers: [1, 3, 5, 8] },
  "8-18": { target: -4, startingNumbers: [2, 3, 6, 9] },
  "8-19": { target: -4, startingNumbers: [5, 6, 7, 8] },
  "8-20": { target: 3, startingNumbers: [1, 2, 4, 8] },
  "8-21": { target: 16, startingNumbers: [5, 6, 7, 8] },
  "8-22": { target: 7, startingNumbers: [2, 3, 4, 6] },
  "8-23": { target: 8, startingNumbers: [3, 4, 7, 8] },
  "8-24": { target: -3, startingNumbers: [3, 5, 7, 8] },
  "8-25": { target: -6, startingNumbers: [2, 3, 8, 9] },
  "8-26": { target: -5, startingNumbers: [1, 2, 4, 8] },
  "8-27": { target: 11, startingNumbers: [1, 2, 3, 7] },
  "8-28": { target: -3, startingNumbers: [4, 5, 7, 9] },
  "8-29": { target: -10, startingNumbers: [5, 6, 8, 9] },
  "8-30": { target: -2, startingNumbers: [5, 6, 7, 8] },
  "8-31": { target: -19, startingNumbers: [2, 3, 6, 8] },
  "9-0": { target: -18, startingNumbers: [3, 4, 5, 6] },
  "9-1": { target: -12, startingNumbers: [3, 4, 5, 6] },
  "9-2": { target: 0, startingNumbers: [1, 4, 6, 9] },
  "9-3": { target: 17, startingNumbers: [1, 3, 4, 9] },
  "9-4": { target: -9, startingNumbers: [3, 5, 7, 8] },
  "9-5": { target: 7, startingNumbers: [1, 4, 6, 8] },
  "9-6": { target: 2, startingNumbers: [4, 5, 8, 9] },
  "9-7": { target: -6, startingNumbers: [2, 5, 6, 7] },
  "9-8": { target: 9, startingNumbers: [2, 3, 6, 8] },
  "9-9": { target: -2, startingNumbers: [3, 4, 5, 8] },
  "9-10": { target: -9, startingNumbers: [2, 3, 4, 6] },
  "9-11": { target: -2, startingNumbers: [1, 5, 7, 9] },
  "9-12": { target: 6, startingNumbers: [2, 6, 7, 9] },
  "9-13": { target: -21, startingNumbers: [2, 6, 8, 9] },
  "9-14": { target: 21, startingNumbers: [3, 5, 6, 7] },
  "9-15": { target: -3, startingNumbers: [1, 3, 4, 9] },
  "9-16": { target: -14, startingNumbers: [1, 4, 5, 6] },
  "9-17": { target: 4, startingNumbers: [2, 4, 6, 8] },
  "9-18": { target: 11, startingNumbers: [1, 4, 5, 9] },
  "9-19": { target: 11, startingNumbers: [1, 2, 5, 9] },
  "9-20": { target: -15, startingNumbers: [1, 3, 8, 9] },
  "9-21": { target: 0, startingNumbers: [1, 3, 5, 7] },
  "9-22": { target: -3, startingNumbers: [3, 5, 6, 7] },
  "9-23": { target: 6, startingNumbers: [3, 4, 6, 7] },
  "9-24": { target: 3, startingNumbers: [1, 2, 3, 7] },
  "9-25": { target: 18, startingNumbers: [1, 4, 7, 8] },
  "9-26": { target: -4, startingNumbers: [2, 3, 5, 8] },
  "9-27": { target: 6, startingNumbers: [1, 2, 4, 5] },
  "9-28": { target: 16, startingNumbers: [1, 2, 6, 7] },
  "9-29": { target: 8, startingNumbers: [1, 2, 3, 8] },
  "9-30": { target: -12, startingNumbers: [2, 6, 7, 9] },
  "9-31": { target: 15, startingNumbers: [1, 4, 5, 7] },
  "10-0": { target: 0, startingNumbers: [4, 5, 7, 8] },
  "10-1": { target: 16, startingNumbers: [1, 2, 5, 8] },
  "10-2": { target: -1, startingNumbers: [3, 4, 7, 9] },
  "10-3": { target: -11, startingNumbers: [2, 5, 6, 8] },
  "10-4": { target: 9, startingNumbers: [3, 4, 6, 8] },
  "10-5": { target: 6, startingNumbers: [2, 5, 8, 9] },
  "10-6": { target: -3, startingNumbers: [3, 4, 5, 7] },
  "10-7": { target: 1, startingNumbers: [1, 5, 6, 9] },
  "10-8": { target: 4, startingNumbers: [1, 2, 3, 8] },
  "10-9": { target: 2, startingNumbers: [1, 3, 5, 7] },
  "10-10": { target: 5, startingNumbers: [1, 5, 8, 9] },
  "10-11": { target: -19, startingNumbers: [1, 4, 6, 8] },
  "10-12": { target: 14, startingNumbers: [1, 2, 8, 9] },
  "10-13": { target: 2, startingNumbers: [5, 6, 7, 8] },
  "10-14": { target: 5, startingNumbers: [2, 4, 7, 8] },
  "10-15": { target: 1, startingNumbers: [2, 4, 5, 8] },
  "10-16": { target: -2, startingNumbers: [2, 3, 4, 7] },
  "10-17": { target: 2, startingNumbers: [2, 3, 8, 9] },
  "10-18": { target: 0, startingNumbers: [1, 3, 7, 9] },
  "10-19": { target: 21, startingNumbers: [2, 5, 6, 8] },
  "10-20": { target: 5, startingNumbers: [1, 2, 5, 9] },
  "10-21": { target: 11, startingNumbers: [1, 5, 8, 9] },
  "10-22": { target: -2, startingNumbers: [2, 5, 6, 7] },
  "10-23": { target: -1, startingNumbers: [5, 6, 7, 9] },
  "10-24": { target: -16, startingNumbers: [1, 2, 5, 8] },
  "10-25": { target: 19, startingNumbers: [3, 6, 7, 9] },
  "10-26": { target: -11, startingNumbers: [3, 4, 5, 9] },
  "10-27": { target: 18, startingNumbers: [4, 5, 8, 9] },
  "10-28": { target: -12, startingNumbers: [1, 3, 4, 6] },
  "10-29": { target: 2, startingNumbers: [1, 5, 6, 8] },
  "10-30": { target: -22, startingNumbers: [4, 5, 6, 7] },
  "10-31": { target: 8, startingNumbers: [4, 5, 8, 9] },
  "11-0": { target: -18, startingNumbers: [2, 3, 4, 9] },
  "11-1": { target: -3, startingNumbers: [2, 3, 4, 8] },
  "11-2": { target: 16, startingNumbers: [2, 3, 6, 9] },
  "11-3": { target: -15, startingNumbers: [4, 6, 8, 9] },
  "11-4": { target: -18, startingNumbers: [1, 2, 6, 9] },
  "11-5": { target: 6, startingNumbers: [1, 2, 6, 9] },
  "11-6": { target: -20, startingNumbers: [2, 3, 6, 9] },
  "11-7": { target: 13, startingNumbers: [2, 4, 5, 6] },
  "11-8": { target: -18, startingNumbers: [1, 4, 6, 7] },
  "11-9": { target: 1, startingNumbers: [2, 5, 6, 8] },
  "11-10": { target: 14, startingNumbers: [1, 3, 4, 6] },
  "11-11": { target: 7, startingNumbers: [3, 7, 8, 9] },
  "11-12": { target: -22, startingNumbers: [2, 5, 7, 8] },
  "11-13": { target: 13, startingNumbers: [2, 3, 5, 9] },
  "11-14": { target: 16, startingNumbers: [2, 3, 8, 9] },
  "11-15": { target: 1, startingNumbers: [1, 2, 6, 8] },
  "11-16": { target: -2, startingNumbers: [1, 2, 3, 8] },
  "11-17": { target: -4, startingNumbers: [2, 5, 6, 7] },
  "11-18": { target: 10, startingNumbers: [1, 2, 6, 7] },
  "11-19": { target: -13, startingNumbers: [1, 2, 3, 7] },
  "11-20": { target: 5, startingNumbers: [3, 4, 7, 9] },
  "11-21": { target: -14, startingNumbers: [1, 2, 6, 7] },
  "11-22": { target: -5, startingNumbers: [3, 4, 5, 7] },
  "11-23": { target: 16, startingNumbers: [4, 5, 6, 9] },
  "11-24": { target: -14, startingNumbers: [1, 2, 4, 9] },
  "11-25": { target: -18, startingNumbers: [3, 5, 7, 9] },
  "11-26": { target: -24, startingNumbers: [4, 5, 6, 9] },
  "11-27": { target: 3, startingNumbers: [2, 3, 4, 6] },
  "11-28": { target: -2, startingNumbers: [4, 5, 6, 7] },
  "11-29": { target: -8, startingNumbers: [4, 5, 7, 8] },
  "11-30": { target: -14, startingNumbers: [3, 6, 8, 9] },
  "11-31": { target: -2, startingNumbers: [1, 2, 4, 7] },
};

export const getDailyHilo = () => {
  const key = getDailyHiloKey();
  return dailyHilos[key];
};

export const getDailyHiloKey = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth();
  return `${month}-${day}`;
};
