import React from "react";
import MarketCapComparisonMessage from "./MarketCapComparisonMessage";
import NextTwoCompaniesBtn from "./NextTwoCompaniesBtn";
import NiceJobHeader from "./NiceJobHeader";
import StockCard from "./StockCard";

interface VersusBoxProps {
  vsCompanies: any[];
  setPlaying: Function;
  onNextTwoCompaniesClick: Function;
  userClickedOnTicker: string;
  handleClick: Function;
}

const VersusBox = ({
  vsCompanies,
  setPlaying,
  onNextTwoCompaniesClick,
  userClickedOnTicker,
  handleClick,
}: VersusBoxProps) => {
  if (vsCompanies.length !== 2) {
    return <></>;
  }

  const pickedRightCompany =
    parseInt(vsCompanies[0].MarketCap) > parseInt(vsCompanies[1].MarketCap) &&
    vsCompanies[0].Symbol === userClickedOnTicker;

  return (
    <div>
      <h2>Which Company has the Larger Market Cap?</h2>
      {pickedRightCompany && <NiceJobHeader />}
      <div>
        <StockCard
          name={vsCompanies[0].Name}
          color={
            parseInt(vsCompanies[0].MarketCap) >
            parseInt(vsCompanies[1].MarketCap)
              ? "green"
              : "red"
          }
          ticker={vsCompanies[0].Symbol}
          isRevealed={userClickedOnTicker !== ""}
          marketCap={vsCompanies[0].MarketCap}
          onClick={handleClick}
          isClickedOn={userClickedOnTicker === vsCompanies[0].Symbol}
        />
        <h2>or</h2>
        <StockCard
          name={vsCompanies[1].Name}
          color={
            parseInt(vsCompanies[1].MarketCap) >
            parseInt(vsCompanies[0].MarketCap)
              ? "green"
              : "red"
          }
          ticker={vsCompanies[1].Symbol}
          isRevealed={userClickedOnTicker !== ""}
          marketCap={vsCompanies[1].MarketCap}
          isClickedOn={userClickedOnTicker === vsCompanies[1].Symbol}
          onClick={handleClick}
        />
      </div>
      <NextTwoCompaniesBtn
        findNext2CompaniesFunc={onNextTwoCompaniesClick}
        isRevealed={userClickedOnTicker !== ""}
      />
      <MarketCapComparisonMessage
        company1={vsCompanies[0]}
        company2={vsCompanies[1]}
        isRevealed={userClickedOnTicker !== ""}
      />
    </div>
  );
};

export default VersusBox;
