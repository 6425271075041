import { ScrollArea, Table } from "@mantine/core";
import React from "react";
import { nonHiddenWords, allowedPunctionation } from "../utils/wordHelpers";

interface GuessedWordsTableProps {
  wordsMap: Map<string, number>;
}

const GuessedWordsTable = ({ wordsMap }: GuessedWordsTableProps) => {
  const guessedWords = Array.from(wordsMap.keys()).filter(
    (value) =>
      !nonHiddenWords.includes(value) && !allowedPunctionation.includes(value)
  );
  return (
    <ScrollArea.Autosize maxHeight={"80vh"}>
      <Table striped withBorder>
        <thead>
          <tr>
            <th>Guess #</th>
            <th>Word</th>
            <th>Hits</th>
          </tr>
        </thead>

        <tbody>
          {guessedWords.reverse().map((word, index) => (
            <tr>
              <td>{guessedWords.length - index}</td>
              <td>{word}</td>
              <td>{wordsMap.get(word)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </ScrollArea.Autosize>
  );
};

export default GuessedWordsTable;
