import React from "react";

interface ResetButtonProps {
  handleResetButtonClick: Function;
  disabled: boolean;
}

const ResetButton = ({
  handleResetButtonClick,
  disabled,
}: ResetButtonProps) => {
  return (
    <button
      disabled={disabled}
      style={{
        padding: "5px",
        borderRadius: "5px",
        margin: "5px",
      }}
      onClick={() => handleResetButtonClick()}
    >
      Reset
    </button>
  );
};

export default ResetButton;
