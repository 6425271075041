import { createContext, useContext, useState } from "react";

const initialGameState = {
  difficulty: "",
  isGameOver: false,
  easyBestTime: Number.MAX_VALUE,
  mediumBestTime: Number.MAX_VALUE,
  hardBestTime: Number.MAX_VALUE,
  currentTime: 0,
};

const PokemonMemoryContext = createContext({
  gameState: { ...initialGameState },
  updateGameState: (updatedValues: any) => {},
});

interface IPokemonMemoryGameState {
  difficulty: string;
  isGameOver: boolean;
  easyBestTime: number;
  mediumBestTime: number;
  hardBestTime: number;
  currentTime: number;
}

export const PokemonMemoryContextProvider = ({ children }: any) => {
  const [gameState, setGameState] = useState<IPokemonMemoryGameState>({
    ...initialGameState,
  });
  const updateGameState = (updatedValues: IPokemonMemoryGameState) => {
    setGameState(updatedValues);
  };

  return (
    <PokemonMemoryContext.Provider value={{ gameState, updateGameState }}>
      {children}
    </PokemonMemoryContext.Provider>
  );
};

export const usePokemonMemory = () => useContext(PokemonMemoryContext);
