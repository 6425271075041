import React from "react";
import { godotProjectsList, miniProjectsList, pico8ProjectsList } from "./constants";
import ProjectCard from "./ProjectCard";
import { SimpleGrid, Title } from "@mantine/core";
import { Container } from "react-bootstrap";

const Experiments = () => {
  const experiments = [...miniProjectsList];
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container style={{
        width: "60vw",
        marginTop: "10vh"
      }}>
        <SimpleGrid
          cols={3}
          spacing="md"
          style={{ marginBottom: "30px", textAlign: "center" }}
        >
          {experiments.map((project, i) => (
            <ProjectCard project={project} />
          ))}
        </SimpleGrid>
        <Title order={2} style={{ marginBottom: "30px" }}>
          Pico 8 projects
        </Title>
        <SimpleGrid
          cols={3}
          spacing="md"
          style={{ marginBottom: "30px", textAlign: "center" }}
        >
          {pico8ProjectsList.map((project, i) => (
            <ProjectCard project={project} />
          ))}
        </SimpleGrid>
        <Title order={2} style={{ marginBottom: "30px" }}>
          Godot Projects
        </Title>
        <SimpleGrid
          cols={3}
          spacing="md"
          style={{ marginBottom: "30px", textAlign: "center" }}
        >
          {godotProjectsList.map((project, i) => (
            <ProjectCard project={project} />
          ))}
        </SimpleGrid>
      </Container>
    </div>
  );
};

export default Experiments;
