import { Button } from "@mantine/core";
import React from "react";

interface WinnerScreenProps {
  movieName: string;
  resetGame: Function;
}

const WinnerScreen = ({ movieName, resetGame }: WinnerScreenProps) => {
  return (
    <div>
      <div>Nice job! The answer was : {movieName}</div>
      <Button onClick={() => resetGame()}>Reset Game</Button>
    </div>
  );
};

export default WinnerScreen;
