export const bigProjectsList: Project[] = [
  {
    header: "OneADayGames.com",
    description: "A new game to play - every day!",
    href: "https://oneadaygames.com",
    imageSrc: "/assets/portfolio/oneadaygames.png"
  },
  {
    header: "DC Metro Pub Crawl",
    description: "Create a bar crawl using metro stops in DC",
    href: "https://dcmetropubcrawl.kylekenney.net",
    imageSrc: "/assets/portfolio/DCMetroPubCrawl.PNG",
  },
];

export const miniProjectsList: Project[] = [
  {
    header: "Hilo",
    description: "Try to add and subtract to get to the target number, and don't be too hi or too lo!",
    href: "/hilo",
    imageSrc: "/assets/portfolio/Hilo.PNG"
  },
  {
    header: "Plotle",
    description: "Redactle-like game only with Plots!",
    href: "/plotle",
    imageSrc: "/assets/portfolio/Plotle.PNG",
  },
  {
    header: "Who's that Pokemon?",
    description: "A game based on the segment 'Who's that Pokemon'",
    href: "/whos-that-pokemon",
    imageSrc: "/assets/portfolio/whos-that-pokemon.png",
  },
  {
    header: "Pokemon Memory Game",
    description: "Test your memory by matching pokemon!",
    href: "/pokemon-memory-game",
    imageSrc: "/assets/portfolio/PokemonMemoryGame.PNG",
  },
  {
    header: "Market Cap Comparer",
    description:
      "A guessing game that allows the user to guess which company is has the bigger market cap",
    href: "/market-cap-comparer",
    imageSrc: "/assets/portfolio/MarketCap.png",
  },
  {
    header: "Hangman",
    description: "Hangman game!",
    href: "/hangman",
    imageSrc: "/assets/portfolio/hangman.PNG",
  },
  {
    header: "Countdown",
    description: "A game based off the famous game Countdown",
    href: '/countdown',
    imageSrc: "/assets/portfolio/countdown.png"
  },
  {
    header: "Fifty States",
    description: "Try to guess all 50 states based off of their outlines",
    href: "/50states",
    imageSrc: "/assets/portfolio/fiftyStates.PNG"
  }
];

export const pico8ProjectsList : Project[] = [
  {
    header: "ZombieRunway",
    description: "A game where you can only turn right and shoot zombies",
    href: "https://meeseeksoohwee.itch.io/zombierunway",
    imageSrc: "/assets/portfolio/zombierunway_0.gif"
  }
]

export const godotProjectsList : Project[] = [
  {
    header: "Tanks",
    description: "A tank game where you shoot aliens",
    href: "https://meeseeksoohwee.itch.io/tanks",
    imageSrc: "/assets/portfolio/tanks.png"
  }
]

export interface Project {
  header: string;
  description: string;
  href: string;
  imageSrc: string;
}
