import React from "react";
import { Modal } from "react-bootstrap";

interface WinnerModalProps {
  isShowing: boolean;
}

const WinnerModal = ({ isShowing }: WinnerModalProps) => {
  return (
    <div>
      <Modal show={isShowing}>
        <Modal.Header>
          <Modal.Title>You Win!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Nice job! Look forward to seeing you for tomorrow's puzzle!</Modal.Body>
        <Modal.Footer>{/*TODO:Add ok button */}</Modal.Footer>
      </Modal>
    </div>
  );
};

export default WinnerModal;
