import React from "react";

interface NumberButtonsProps {
  nums: number[];
  handleNumClick: Function;
  selectedNumIndexes: number[];
  isNumsDisabled : boolean;
}

const NumberButtons = ({
  nums,
  handleNumClick,
  selectedNumIndexes,
  isNumsDisabled
}: NumberButtonsProps) => {
  return (
    <>
      {nums.map((num, index) => (
        <button
          key={index}
          style={{
            margin: "5px",
            padding: "5px",
            borderRadius: "8px",
            color: selectedNumIndexes.includes(index) ? "white" : "black",
            backgroundColor: selectedNumIndexes.includes(index)
              ? "green"
              : "white",
          }}
          disabled={isNumsDisabled || selectedNumIndexes.includes(index)}
          onClick={() => handleNumClick(num, index)}
        >
          {num}
        </button>
      ))}
    </>
  );
};

export default NumberButtons;
