import React from "react";
import RecentlyGuessedWord from "./RecentlyGuessedWord";
import RedactedText from "./RedactedText";

interface RedactedSectionProps {
  textArr: string[];
  wordsMap: Map<String, number>;
  recentlyGuessedWord: string;
}

const RedactedSection = ({
  textArr,
  wordsMap,
  recentlyGuessedWord,
}: RedactedSectionProps) => {
  return (
    <>
      {textArr.map((word: string) => {
        const lowerCaseWord = word.toLowerCase();
        return (
          <>
            {" "}
            <span>
              {wordsMap.has(lowerCaseWord) ? (
                <RecentlyGuessedWord
                  recentlyGuessedWord={recentlyGuessedWord}
                  word={lowerCaseWord}
                />
              ) : (
                <RedactedText word={word} />
              )}
            </span>{" "}
          </>
        );
      })}
    </>
  );
};

export default RedactedSection;
