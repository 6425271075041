export const isNidoranException = (
  pokemonName: string,
  guessText: string
): boolean => {
  const pokemonLowerCase = pokemonName.toLowerCase();
  if (pokemonLowerCase === "nidoran-f" || pokemonLowerCase === "nidoran-m") {
    if (guessText.toLowerCase() === "nidoran") {
      return true;
    }
    return false;
  }
  return false;
};
