import React from "react";

interface NextTwoCompaniesBtnProps {
  isRevealed: boolean;
  findNext2CompaniesFunc: Function;
}

const NextTwoCompaniesBtn = ({
  isRevealed,
  findNext2CompaniesFunc,
}: NextTwoCompaniesBtnProps) => {
  if (!isRevealed) {
    return <></>;
  }
  return (
    <button
      onClick={() => {
        findNext2CompaniesFunc();
      }}
      style={{ borderRadius: "12px", margin: "15px" }}
    >
      Next 2 companies
    </button>
  );
};

export default NextTwoCompaniesBtn;
