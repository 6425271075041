import React, { useEffect } from 'react'

const WinnerScreen = () => {
    useEffect(() => {
        const audio = new Audio('/assets/positive_note.mp3');
        audio.play();
    }, [])
  return (
    <h1>You win!</h1>
  )
}

export default WinnerScreen