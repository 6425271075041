import { WikiObject } from "./wikiapi";

export const marvelMovieList: WikiObject[] = [
    {
        name: "Iron Man",
        wikiPageTitle: "Iron_Man_(2008_film)"
    },
    {
        name: "The Incredible Hulk",
        wikiPageTitle: "The_Incredible_Hulk_(film)"
    },
    {
        name: "Iron Man 2",
        wikiPageTitle: "Iron_Man_2"
    },
    {
        name: "Thor",
        wikiPageTitle: "Thor_(film)"
    },
    {
        name: "Captain America The First Avenger",
        wikiPageTitle: "Captain_America:_The_First_Avenger"
    },
    {
        name: "The Avengers",
        wikiPageTitle: "The_Avengers_(2012_film)"
    },
    {
        name: "Iron Man 3",
        wikiPageTitle: "Iron_Man_3"
    },
    {
        name: "Thor The Dark World",
        wikiPageTitle: "Thor:_The_Dark_World"
    },
    {
        name: "Captain America The Winter Soldier",
        wikiPageTitle: "Captain_America:_The_Winter_Soldier"
    },
    {
        name: "Guardians of the Galaxy",
        wikiPageTitle: "Guardians_of_the_Galaxy_(film)"
    },
    {
        name: "Avengers Age of Ultron",
        wikiPageTitle: "Avengers:_Age_of_Ultron"
    },
    {
        name: "Ant Man",
        wikiPageTitle: "Ant-Man_(film)"
    },
    {
        name: "Captain America Civil War",
        wikiPageTitle: "Captain_America:_Civil_War"
    },
    {
        name: "Doctor Strange",
        wikiPageTitle: "Doctor_Strange_(2016_film)"
    },
    {
        name: "Guardians of the Galaxy Vol 2",
        wikiPageTitle: "Guardians_of_the_Galaxy_Vol._2"
    },
    {
        name: "Spider-Man: Homecoming",
        wikiPageTitle: "Spider-Man:_Homecoming"
    },
    {
        name: "Thor: Ragnarok",
        wikiPageTitle: "Thor:_Ragnarok"
    },
    {
        name: "Black Panther",
        wikiPageTitle: "Black_Panther_(film)"
    },
    {
        name: "Avengers Infinity War",
        wikiPageTitle: "Avengers:_Infinity_War"
    },
    {
        name: "Ant Man and the Wasp",
        wikiPageTitle: "Ant-Man_and_the_Wasp"
    },
    {
        name: "Captain Marvel",
        wikiPageTitle: "Captain_Marvel_(film)"
    },
    {
        name: "Avengers Endgame",
        wikiPageTitle: "Avengers:_Endgame"
    },
    {
        name: "Spider Man Far From Home",
        wikiPageTitle: "Spider-Man:_Far_From_Home"
    },
    {
        name: "Black Widow",
        wikiPageTitle: "Black_Widow_(2021_film)"
    },
    {
        name: "Shang Chi and the Legend of the Ten Rings",
        wikiPageTitle: "Shang-Chi_and_the_Legend_of_the_Ten_Rings"
    },
    {
        name: "Eternals",
        wikiPageTitle: "Eternals_(film)"
    },
    {
        name: "Spider Man No Way Home",
        wikiPageTitle: "Spider-Man:_No_Way_Home"
    },
    {
        name: "Doctor Strange in the Multiverse of Madness",
        wikiPageTitle: "Doctor_Strange_in_the_Multiverse_of_Madness"
    },
    {
       name: "Thor Love and Thunder",
       wikiPageTitle: "Thor:_Love_and_Thunder" 
    },
    {
        name: "Black Panther Wakanda Forever",
        wikiPageTitle: "Black_Panther:_Wakanda_Forever"
    }
];