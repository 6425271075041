export const solvableNumbers = [
  [1, 2, 3, 4, 5, 100],
  [1, 2, 3, 4, 9, 75],
  [1, 2, 3, 4, 10, 75],
  [1, 2, 3, 4, 10, 100],
  [1, 2, 3, 5, 9, 100],
  [1, 2, 3, 6, 9, 100],
  [1, 2, 3, 7, 9, 100],
  [1, 2, 3, 7, 10, 75],
  [1, 2, 3, 7, 10, 100],
  [1, 2, 3, 8, 10, 50],
  [1, 2, 3, 8, 10, 100],
  [1, 2, 3, 9, 10, 75],
  [1, 2, 3, 10, 75, 100],
  [1, 2, 4, 5, 7, 100],
  [1, 2, 4, 5, 8, 100],
  [1, 2, 4, 5, 9, 100],
  [1, 2, 4, 5, 50, 75],
  [1, 2, 4, 6, 7, 75],
  [1, 2, 4, 6, 9, 50],
  [1, 2, 4, 6, 9, 100],
  [1, 2, 4, 7, 9, 50],
  [1, 2, 4, 7, 9, 75],
  [1, 2, 4, 7, 9, 100],
  [1, 2, 4, 7, 10, 100],
  [1, 2, 4, 9, 10, 75],
  [1, 2, 4, 9, 10, 100],
  [1, 2, 4, 9, 25, 75],
  [1, 2, 4, 9, 25, 100],
  [1, 2, 4, 9, 50, 75],
  [1, 2, 4, 9, 75, 100],
  [1, 2, 4, 10, 25, 100],
  [1, 2, 4, 10, 75, 100],
  [1, 2, 5, 6, 8, 75],
  [1, 2, 5, 6, 8, 100],
  [1, 2, 5, 6, 9, 100],
  [1, 2, 5, 6, 25, 75],
  [1, 2, 5, 7, 8, 75],
  [1, 2, 5, 7, 8, 100],
  [1, 2, 5, 7, 9, 100],
  [1, 2, 5, 7, 10, 75],
  [1, 2, 5, 7, 25, 75],
  [1, 2, 5, 7, 75, 100],
  [1, 2, 5, 8, 8, 100],
  [1, 2, 5, 8, 9, 50],
  [1, 2, 5, 8, 9, 100],
  [1, 2, 5, 8, 50, 100],
  [1, 2, 5, 8, 75, 100],
  [1, 2, 5, 9, 25, 50],
  [1, 2, 5, 9, 25, 100],
  [1, 2, 5, 9, 50, 100],
  [1, 2, 5, 9, 75, 100],
  [1, 2, 6, 7, 8, 100],
  [1, 2, 6, 7, 9, 100],
  [1, 2, 6, 7, 10, 75],
  [1, 2, 6, 7, 10, 100],
  [1, 2, 6, 8, 9, 50],
  [1, 2, 6, 8, 9, 100],
  [1, 2, 6, 8, 10, 100],
  [1, 2, 6, 8, 25, 75],
  [1, 2, 6, 8, 25, 100],
  [1, 2, 6, 8, 50, 75],
  [1, 2, 6, 9, 10, 75],
  [1, 2, 6, 9, 10, 100],
  [1, 2, 6, 9, 25, 100],
  [1, 2, 6, 9, 50, 75],
  [1, 2, 6, 9, 75, 100],
  [1, 2, 6, 10, 25, 75],
  [1, 2, 6, 10, 75, 100],
  [1, 2, 7, 7, 10, 100],
  [1, 2, 7, 8, 9, 50],
  [1, 2, 7, 8, 10, 50],
  [1, 2, 7, 8, 10, 100],
  [1, 2, 7, 9, 10, 50],
  [1, 2, 7, 9, 10, 75],
  [1, 2, 7, 9, 10, 100],
  [1, 2, 7, 9, 50, 100],
  [1, 2, 7, 10, 50, 75],
  [1, 2, 7, 10, 50, 100],
  [1, 2, 7, 10, 75, 100],
  [1, 2, 8, 9, 50, 100],
  [1, 2, 8, 10, 25, 50],
  [1, 2, 8, 10, 50, 75],
  [1, 2, 8, 10, 75, 100],
  [1, 2, 9, 10, 25, 50],
  [1, 2, 9, 10, 75, 100],
  [1, 3, 4, 5, 9, 100],
  [1, 3, 4, 5, 50, 75],
  [1, 3, 4, 6, 9, 50],
  [1, 3, 4, 6, 9, 75],
  [1, 3, 4, 7, 9, 75],
  [1, 3, 4, 7, 9, 100],
  [1, 3, 4, 7, 10, 25],
  [1, 3, 4, 7, 10, 75],
  [1, 3, 4, 8, 9, 75],
  [1, 3, 4, 8, 10, 25],
  [1, 3, 4, 8, 10, 75],
  [1, 3, 4, 8, 10, 100],
  [1, 3, 4, 8, 50, 75],
  [1, 3, 4, 9, 10, 75],
  [1, 3, 4, 9, 75, 100],
  [1, 3, 4, 10, 25, 75],
  [1, 3, 4, 10, 50, 75],
  [1, 3, 4, 10, 75, 100],
  [1, 3, 5, 6, 8, 100],
  [1, 3, 5, 6, 9, 100],
  [1, 3, 5, 7, 9, 25],
  [1, 3, 5, 7, 9, 100],
  [1, 3, 5, 8, 9, 50],
  [1, 3, 5, 8, 9, 100],
  [1, 3, 5, 8, 10, 100],
  [1, 3, 5, 8, 50, 100],
  [1, 3, 5, 9, 75, 100],
  [1, 3, 6, 7, 8, 100],
  [1, 3, 6, 7, 10, 75],
  [1, 3, 6, 7, 10, 100],
  [1, 3, 6, 8, 9, 100],
  [1, 3, 6, 8, 10, 50],
  [1, 3, 6, 8, 10, 100],
  [1, 3, 6, 9, 10, 100],
  [1, 3, 6, 10, 25, 50],
  [1, 3, 6, 10, 25, 100],
  [1, 3, 6, 10, 50, 75],
  [1, 3, 6, 10, 75, 100],
  [1, 3, 7, 8, 9, 100],
  [1, 3, 7, 8, 10, 100],
  [1, 3, 7, 8, 25, 100],
  [1, 3, 7, 9, 10, 100],
  [1, 3, 7, 9, 25, 100],
  [1, 3, 7, 9, 50, 100],
  [1, 3, 7, 10, 25, 100],
  [1, 3, 7, 10, 75, 100],
  [1, 3, 8, 9, 10, 100],
  [1, 3, 8, 9, 75, 100],
  [1, 3, 8, 10, 50, 100],
  [1, 3, 8, 10, 75, 100],
  [1, 3, 9, 10, 75, 100],
  [1, 4, 4, 7, 9, 100],
  [1, 4, 5, 7, 9, 100],
  [1, 4, 5, 7, 10, 100],
  [1, 4, 5, 7, 50, 100],
  [1, 4, 6, 7, 8, 25],
  [1, 4, 6, 7, 8, 100],
  [1, 4, 6, 7, 9, 100],
  [1, 4, 6, 7, 10, 100],
  [1, 4, 6, 9, 10, 100],
  [1, 4, 6, 9, 25, 100],
  [1, 4, 6, 9, 75, 100],
  [1, 4, 7, 7, 9, 100],
  [1, 4, 7, 8, 9, 100],
  [1, 4, 7, 8, 10, 75],
  [1, 4, 7, 8, 10, 100],
  [1, 4, 7, 8, 25, 100],
  [1, 4, 7, 9, 10, 75],
  [1, 4, 7, 9, 10, 100],
  [1, 4, 7, 9, 25, 50],
  [1, 4, 7, 9, 25, 100],
  [1, 4, 7, 9, 75, 100],
  [1, 4, 8, 9, 10, 75],
  [1, 4, 8, 9, 25, 100],
  [1, 4, 8, 10, 25, 75],
  [1, 5, 6, 7, 9, 100],
  [1, 5, 6, 7, 10, 100],
  [1, 5, 6, 8, 9, 100],
  [1, 5, 6, 8, 25, 100],
  [1, 5, 7, 7, 9, 100],
  [1, 5, 7, 8, 9, 100],
  [1, 5, 7, 8, 10, 100],
  [1, 5, 7, 8, 25, 100],
  [1, 5, 7, 9, 9, 100],
  [1, 5, 7, 9, 10, 100],
  [1, 5, 7, 9, 25, 100],
  [1, 5, 7, 9, 50, 100],
  [1, 5, 7, 9, 75, 100],
  [1, 5, 7, 10, 25, 100],
  [1, 5, 8, 10, 25, 75],
  [1, 6, 7, 8, 9, 100],
  [1, 6, 7, 8, 10, 100],
  [1, 6, 7, 9, 10, 100],
  [1, 6, 7, 9, 25, 100],
  [1, 6, 7, 9, 75, 100],
  [1, 6, 7, 10, 25, 100],
  [1, 6, 8, 9, 25, 100],
  [1, 6, 8, 9, 50, 100],
  [1, 6, 8, 10, 25, 100],
  [1, 6, 8, 10, 75, 100],
  [1, 7, 8, 10, 25, 100],
  [1, 7, 9, 10, 25, 75],
  [1, 7, 9, 10, 75, 100],
  [2, 2, 3, 5, 9, 100],
  [2, 2, 3, 7, 9, 100],
  [2, 2, 3, 7, 10, 100],
  [2, 2, 3, 8, 9, 100],
  [2, 2, 3, 9, 10, 50],
  [2, 2, 3, 9, 10, 75],
  [2, 2, 3, 9, 50, 100],
  [2, 2, 4, 5, 9, 100],
  [2, 2, 4, 6, 9, 75],
  [2, 2, 4, 7, 9, 100],
  [2, 2, 4, 9, 10, 75],
  [2, 2, 4, 9, 75, 100],
  [2, 2, 5, 6, 9, 100],
  [2, 2, 5, 7, 8, 100],
  [2, 2, 5, 7, 9, 100],
  [2, 2, 5, 8, 9, 50],
  [2, 2, 5, 8, 9, 100],
  [2, 2, 5, 8, 25, 100],
  [2, 2, 5, 9, 10, 50],
  [2, 2, 5, 9, 50, 75],
  [2, 2, 6, 7, 9, 100],
  [2, 2, 6, 7, 75, 100],
  [2, 2, 6, 8, 9, 100],
  [2, 2, 6, 9, 10, 100],
  [2, 2, 6, 9, 25, 100],
  [2, 2, 6, 9, 75, 100],
  [2, 2, 7, 8, 9, 50],
  [2, 2, 7, 8, 9, 100],
  [2, 2, 7, 8, 25, 100],
  [2, 2, 7, 9, 10, 50],
  [2, 2, 7, 9, 10, 100],
  [2, 2, 8, 9, 75, 100],
  [2, 2, 9, 10, 25, 50],
  [2, 3, 3, 5, 9, 100],
  [2, 3, 3, 6, 7, 100],
  [2, 3, 3, 7, 8, 100],
  [2, 3, 3, 7, 9, 100],
  [2, 3, 3, 7, 10, 100],
  [2, 3, 3, 8, 10, 75],
  [2, 3, 3, 8, 10, 100],
  [2, 3, 3, 9, 10, 100],
  [2, 3, 3, 10, 75, 100],
  [2, 3, 4, 5, 6, 75],
  [2, 3, 4, 5, 6, 100],
  [2, 3, 4, 5, 7, 50],
  [2, 3, 4, 5, 7, 75],
  [2, 3, 4, 5, 7, 100],
  [2, 3, 4, 5, 8, 75],
  [2, 3, 4, 5, 8, 100],
  [2, 3, 4, 5, 9, 50],
  [2, 3, 4, 5, 9, 75],
  [2, 3, 4, 5, 9, 100],
  [2, 3, 4, 5, 10, 75],
  [2, 3, 4, 5, 10, 100],
  [2, 3, 4, 5, 25, 100],
  [2, 3, 4, 5, 50, 75],
  [2, 3, 4, 5, 75, 100],
  [2, 3, 4, 6, 7, 100],
  [2, 3, 4, 6, 9, 50],
  [2, 3, 4, 6, 9, 100],
  [2, 3, 4, 6, 10, 75],
  [2, 3, 4, 6, 25, 75],
  [2, 3, 4, 6, 50, 75],
  [2, 3, 4, 7, 7, 75],
  [2, 3, 4, 7, 8, 100],
  [2, 3, 4, 7, 9, 50],
  [2, 3, 4, 7, 9, 75],
  [2, 3, 4, 7, 9, 100],
  [2, 3, 4, 7, 10, 25],
  [2, 3, 4, 7, 10, 75],
  [2, 3, 4, 7, 10, 100],
  [2, 3, 4, 7, 25, 75],
  [2, 3, 4, 7, 50, 75],
  [2, 3, 4, 8, 9, 25],
  [2, 3, 4, 8, 9, 50],
  [2, 3, 4, 8, 9, 75],
  [2, 3, 4, 8, 9, 100],
  [2, 3, 4, 8, 10, 75],
  [2, 3, 4, 8, 25, 50],
  [2, 3, 4, 8, 25, 75],
  [2, 3, 4, 8, 50, 75],
  [2, 3, 4, 8, 75, 100],
  [2, 3, 4, 9, 9, 100],
  [2, 3, 4, 9, 10, 25],
  [2, 3, 4, 9, 10, 50],
  [2, 3, 4, 9, 10, 75],
  [2, 3, 4, 9, 10, 100],
  [2, 3, 4, 9, 25, 75],
  [2, 3, 4, 9, 25, 100],
  [2, 3, 4, 9, 50, 100],
  [2, 3, 4, 9, 75, 100],
  [2, 3, 4, 10, 25, 75],
  [2, 3, 4, 10, 25, 100],
  [2, 3, 4, 10, 75, 100],
  [2, 3, 5, 5, 9, 100],
  [2, 3, 5, 6, 7, 75],
  [2, 3, 5, 6, 7, 100],
  [2, 3, 5, 6, 8, 50],
  [2, 3, 5, 6, 8, 75],
  [2, 3, 5, 6, 8, 100],
  [2, 3, 5, 6, 9, 50],
  [2, 3, 5, 6, 9, 75],
  [2, 3, 5, 6, 9, 100],
  [2, 3, 5, 6, 10, 50],
  [2, 3, 5, 6, 10, 75],
  [2, 3, 5, 6, 25, 50],
  [2, 3, 5, 6, 25, 100],
  [2, 3, 5, 6, 75, 100],
  [2, 3, 5, 7, 8, 50],
  [2, 3, 5, 7, 8, 75],
  [2, 3, 5, 7, 8, 100],
  [2, 3, 5, 7, 9, 25],
  [2, 3, 5, 7, 9, 50],
  [2, 3, 5, 7, 9, 100],
  [2, 3, 5, 7, 10, 50],
  [2, 3, 5, 7, 10, 75],
  [2, 3, 5, 7, 25, 50],
  [2, 3, 5, 7, 25, 100],
  [2, 3, 5, 7, 50, 100],
  [2, 3, 5, 7, 75, 100],
  [2, 3, 5, 8, 9, 50],
  [2, 3, 5, 8, 9, 75],
  [2, 3, 5, 8, 9, 100],
  [2, 3, 5, 8, 10, 50],
  [2, 3, 5, 8, 10, 75],
  [2, 3, 5, 8, 10, 100],
  [2, 3, 5, 8, 25, 100],
  [2, 3, 5, 8, 50, 100],
  [2, 3, 5, 9, 9, 100],
  [2, 3, 5, 9, 10, 50],
  [2, 3, 5, 9, 10, 75],
  [2, 3, 5, 9, 10, 100],
  [2, 3, 5, 9, 25, 50],
  [2, 3, 5, 9, 50, 75],
  [2, 3, 5, 9, 50, 100],
  [2, 3, 5, 9, 75, 100],
  [2, 3, 5, 10, 75, 100],
  [2, 3, 6, 7, 8, 50],
  [2, 3, 6, 7, 8, 75],
  [2, 3, 6, 7, 8, 100],
  [2, 3, 6, 7, 9, 75],
  [2, 3, 6, 7, 9, 100],
  [2, 3, 6, 7, 10, 50],
  [2, 3, 6, 7, 10, 75],
  [2, 3, 6, 7, 10, 100],
  [2, 3, 6, 7, 25, 50],
  [2, 3, 6, 7, 25, 75],
  [2, 3, 6, 7, 50, 75],
  [2, 3, 6, 7, 50, 100],
  [2, 3, 6, 7, 75, 100],
  [2, 3, 6, 8, 9, 100],
  [2, 3, 6, 8, 10, 75],
  [2, 3, 6, 8, 10, 100],
  [2, 3, 6, 8, 25, 50],
  [2, 3, 6, 8, 25, 100],
  [2, 3, 6, 8, 50, 75],
  [2, 3, 6, 8, 75, 100],
  [2, 3, 6, 9, 10, 50],
  [2, 3, 6, 9, 10, 75],
  [2, 3, 6, 9, 10, 100],
  [2, 3, 6, 9, 25, 50],
  [2, 3, 6, 9, 25, 100],
  [2, 3, 6, 9, 75, 100],
  [2, 3, 6, 10, 25, 50],
  [2, 3, 6, 10, 25, 75],
  [2, 3, 6, 10, 25, 100],
  [2, 3, 6, 10, 75, 100],
  [2, 3, 7, 7, 8, 100],
  [2, 3, 7, 8, 8, 100],
  [2, 3, 7, 8, 9, 50],
  [2, 3, 7, 8, 9, 75],
  [2, 3, 7, 8, 9, 100],
  [2, 3, 7, 8, 10, 25],
  [2, 3, 7, 8, 10, 50],
  [2, 3, 7, 8, 10, 75],
  [2, 3, 7, 8, 10, 100],
  [2, 3, 7, 8, 25, 50],
  [2, 3, 7, 8, 25, 100],
  [2, 3, 7, 8, 50, 75],
  [2, 3, 7, 8, 50, 100],
  [2, 3, 7, 8, 75, 100],
  [2, 3, 7, 9, 9, 100],
  [2, 3, 7, 9, 10, 50],
  [2, 3, 7, 9, 10, 75],
  [2, 3, 7, 9, 10, 100],
  [2, 3, 7, 9, 25, 50],
  [2, 3, 7, 9, 25, 75],
  [2, 3, 7, 9, 25, 100],
  [2, 3, 7, 9, 50, 75],
  [2, 3, 7, 9, 50, 100],
  [2, 3, 7, 9, 75, 100],
  [2, 3, 7, 10, 25, 50],
  [2, 3, 7, 10, 25, 75],
  [2, 3, 7, 10, 25, 100],
  [2, 3, 7, 10, 50, 75],
  [2, 3, 7, 10, 50, 100],
  [2, 3, 7, 10, 75, 100],
  [2, 3, 8, 8, 9, 100],
  [2, 3, 8, 9, 10, 25],
  [2, 3, 8, 9, 10, 50],
  [2, 3, 8, 9, 10, 100],
  [2, 3, 8, 9, 25, 50],
  [2, 3, 8, 9, 25, 75],
  [2, 3, 8, 9, 25, 100],
  [2, 3, 8, 9, 50, 75],
  [2, 3, 8, 9, 50, 100],
  [2, 3, 8, 9, 75, 100],
  [2, 3, 8, 10, 25, 50],
  [2, 3, 8, 10, 25, 75],
  [2, 3, 8, 10, 25, 100],
  [2, 3, 8, 10, 75, 100],
  [2, 3, 9, 9, 10, 100],
  [2, 3, 9, 10, 10, 50],
  [2, 3, 9, 10, 25, 50],
  [2, 3, 9, 10, 25, 75],
  [2, 3, 9, 10, 25, 100],
  [2, 3, 9, 10, 50, 75],
  [2, 3, 9, 10, 50, 100],
  [2, 3, 9, 10, 75, 100],
  [2, 4, 4, 5, 9, 100],
  [2, 4, 4, 7, 9, 100],
  [2, 4, 4, 9, 25, 100],
  [2, 4, 4, 9, 75, 100],
  [2, 4, 5, 5, 6, 100],
  [2, 4, 5, 5, 7, 100],
  [2, 4, 5, 5, 8, 100],
  [2, 4, 5, 6, 7, 25],
  [2, 4, 5, 6, 7, 75],
  [2, 4, 5, 6, 7, 100],
  [2, 4, 5, 6, 8, 75],
  [2, 4, 5, 6, 9, 75],
  [2, 4, 5, 6, 9, 100],
  [2, 4, 5, 6, 10, 75],
  [2, 4, 5, 6, 25, 75],
  [2, 4, 5, 6, 25, 100],
  [2, 4, 5, 6, 75, 100],
  [2, 4, 5, 7, 8, 25],
  [2, 4, 5, 7, 8, 50],
  [2, 4, 5, 7, 8, 75],
  [2, 4, 5, 7, 8, 100],
  [2, 4, 5, 7, 9, 25],
  [2, 4, 5, 7, 9, 50],
  [2, 4, 5, 7, 9, 75],
  [2, 4, 5, 7, 9, 100],
  [2, 4, 5, 7, 10, 100],
  [2, 4, 5, 7, 25, 100],
  [2, 4, 5, 7, 50, 75],
  [2, 4, 5, 7, 50, 100],
  [2, 4, 5, 7, 75, 100],
  [2, 4, 5, 8, 9, 25],
  [2, 4, 5, 8, 9, 75],
  [2, 4, 5, 8, 9, 100],
  [2, 4, 5, 8, 10, 75],
  [2, 4, 5, 8, 10, 100],
  [2, 4, 5, 8, 25, 50],
  [2, 4, 5, 8, 25, 75],
  [2, 4, 5, 8, 25, 100],
  [2, 4, 5, 8, 50, 75],
  [2, 4, 5, 8, 75, 100],
  [2, 4, 5, 9, 10, 100],
  [2, 4, 5, 9, 25, 50],
  [2, 4, 5, 9, 25, 75],
  [2, 4, 5, 9, 25, 100],
  [2, 4, 5, 9, 50, 75],
  [2, 4, 5, 9, 50, 100],
  [2, 4, 5, 9, 75, 100],
  [2, 4, 6, 6, 9, 100],
  [2, 4, 6, 7, 8, 75],
  [2, 4, 6, 7, 9, 25],
  [2, 4, 6, 7, 9, 50],
  [2, 4, 6, 7, 9, 75],
  [2, 4, 6, 7, 9, 100],
  [2, 4, 6, 7, 10, 75],
  [2, 4, 6, 7, 25, 50],
  [2, 4, 6, 7, 25, 75],
  [2, 4, 6, 7, 25, 100],
  [2, 4, 6, 7, 50, 75],
  [2, 4, 6, 7, 75, 100],
  [2, 4, 6, 8, 9, 75],
  [2, 4, 6, 9, 9, 100],
  [2, 4, 6, 9, 10, 25],
  [2, 4, 6, 9, 10, 75],
  [2, 4, 6, 9, 10, 100],
  [2, 4, 6, 9, 25, 50],
  [2, 4, 6, 9, 25, 100],
  [2, 4, 6, 9, 50, 75],
  [2, 4, 6, 9, 75, 100],
  [2, 4, 6, 10, 25, 75],
  [2, 4, 6, 10, 25, 100],
  [2, 4, 6, 10, 75, 100],
  [2, 4, 7, 7, 9, 100],
  [2, 4, 7, 7, 10, 100],
  [2, 4, 7, 8, 9, 25],
  [2, 4, 7, 8, 9, 50],
  [2, 4, 7, 8, 9, 75],
  [2, 4, 7, 8, 9, 100],
  [2, 4, 7, 8, 10, 75],
  [2, 4, 7, 8, 10, 100],
  [2, 4, 7, 8, 25, 50],
  [2, 4, 7, 8, 25, 100],
  [2, 4, 7, 8, 50, 75],
  [2, 4, 7, 8, 75, 100],
  [2, 4, 7, 9, 9, 50],
  [2, 4, 7, 9, 9, 100],
  [2, 4, 7, 9, 10, 25],
  [2, 4, 7, 9, 10, 50],
  [2, 4, 7, 9, 10, 75],
  [2, 4, 7, 9, 10, 100],
  [2, 4, 7, 9, 25, 75],
  [2, 4, 7, 9, 25, 100],
  [2, 4, 7, 9, 50, 75],
  [2, 4, 7, 9, 50, 100],
  [2, 4, 7, 9, 75, 100],
  [2, 4, 7, 10, 25, 50],
  [2, 4, 7, 10, 25, 75],
  [2, 4, 7, 10, 25, 100],
  [2, 4, 7, 10, 50, 75],
  [2, 4, 7, 10, 75, 100],
  [2, 4, 8, 9, 10, 75],
  [2, 4, 8, 9, 10, 100],
  [2, 4, 8, 9, 25, 50],
  [2, 4, 8, 9, 25, 75],
  [2, 4, 8, 9, 25, 100],
  [2, 4, 8, 9, 50, 75],
  [2, 4, 8, 9, 50, 100],
  [2, 4, 8, 9, 75, 100],
  [2, 4, 8, 10, 25, 75],
  [2, 4, 8, 10, 25, 100],
  [2, 4, 8, 10, 75, 100],
  [2, 4, 9, 9, 10, 100],
  [2, 4, 9, 9, 75, 100],
  [2, 4, 9, 10, 25, 50],
  [2, 4, 9, 10, 25, 75],
  [2, 4, 9, 10, 25, 100],
  [2, 4, 9, 10, 50, 75],
  [2, 4, 9, 10, 75, 100],
  [2, 5, 5, 6, 9, 100],
  [2, 5, 5, 8, 9, 50],
  [2, 5, 5, 8, 9, 100],
  [2, 5, 6, 6, 9, 100],
  [2, 5, 6, 6, 75, 100],
  [2, 5, 6, 7, 8, 25],
  [2, 5, 6, 7, 8, 75],
  [2, 5, 6, 7, 8, 100],
  [2, 5, 6, 7, 9, 25],
  [2, 5, 6, 7, 9, 50],
  [2, 5, 6, 7, 9, 75],
  [2, 5, 6, 7, 9, 100],
  [2, 5, 6, 7, 10, 50],
  [2, 5, 6, 7, 10, 75],
  [2, 5, 6, 7, 10, 100],
  [2, 5, 6, 7, 25, 50],
  [2, 5, 6, 7, 25, 75],
  [2, 5, 6, 7, 25, 100],
  [2, 5, 6, 7, 50, 75],
  [2, 5, 6, 7, 50, 100],
  [2, 5, 6, 7, 75, 100],
  [2, 5, 6, 8, 8, 75],
  [2, 5, 6, 8, 9, 25],
  [2, 5, 6, 8, 9, 75],
  [2, 5, 6, 8, 9, 100],
  [2, 5, 6, 8, 10, 75],
  [2, 5, 6, 8, 10, 100],
  [2, 5, 6, 8, 25, 50],
  [2, 5, 6, 8, 25, 75],
  [2, 5, 6, 8, 25, 100],
  [2, 5, 6, 8, 50, 75],
  [2, 5, 6, 8, 75, 100],
  [2, 5, 6, 9, 9, 100],
  [2, 5, 6, 9, 10, 50],
  [2, 5, 6, 9, 10, 75],
  [2, 5, 6, 9, 10, 100],
  [2, 5, 6, 9, 25, 75],
  [2, 5, 6, 9, 25, 100],
  [2, 5, 6, 9, 50, 100],
  [2, 5, 6, 9, 75, 100],
  [2, 5, 6, 10, 75, 100],
  [2, 5, 7, 7, 9, 100],
  [2, 5, 7, 8, 8, 100],
  [2, 5, 7, 8, 9, 10],
  [2, 5, 7, 8, 9, 75],
  [2, 5, 7, 8, 9, 100],
  [2, 5, 7, 8, 10, 75],
  [2, 5, 7, 8, 10, 100],
  [2, 5, 7, 8, 25, 50],
  [2, 5, 7, 8, 25, 75],
  [2, 5, 7, 8, 25, 100],
  [2, 5, 7, 8, 50, 75],
  [2, 5, 7, 8, 75, 100],
  [2, 5, 7, 9, 9, 100],
  [2, 5, 7, 9, 10, 50],
  [2, 5, 7, 9, 10, 100],
  [2, 5, 7, 9, 25, 50],
  [2, 5, 7, 9, 25, 75],
  [2, 5, 7, 9, 25, 100],
  [2, 5, 7, 9, 50, 75],
  [2, 5, 7, 9, 75, 100],
  [2, 5, 7, 10, 25, 100],
  [2, 5, 7, 10, 75, 100],
  [2, 5, 8, 8, 9, 50],
  [2, 5, 8, 8, 9, 100],
  [2, 5, 8, 8, 25, 100],
  [2, 5, 8, 9, 9, 100],
  [2, 5, 8, 9, 10, 50],
  [2, 5, 8, 9, 10, 100],
  [2, 5, 8, 9, 25, 50],
  [2, 5, 8, 9, 25, 75],
  [2, 5, 8, 9, 25, 100],
  [2, 5, 8, 9, 50, 75],
  [2, 5, 8, 9, 50, 100],
  [2, 5, 8, 9, 75, 100],
  [2, 5, 8, 10, 25, 75],
  [2, 5, 9, 9, 10, 100],
  [2, 5, 9, 9, 50, 100],
  [2, 5, 9, 9, 75, 100],
  [2, 6, 6, 7, 8, 75],
  [2, 6, 6, 7, 9, 100],
  [2, 6, 6, 7, 10, 75],
  [2, 6, 7, 7, 10, 75],
  [2, 6, 7, 8, 8, 75],
  [2, 6, 7, 8, 9, 25],
  [2, 6, 7, 8, 9, 50],
  [2, 6, 7, 8, 9, 75],
  [2, 6, 7, 8, 9, 100],
  [2, 6, 7, 8, 10, 75],
  [2, 6, 7, 8, 10, 100],
  [2, 6, 7, 8, 25, 50],
  [2, 6, 7, 8, 25, 75],
  [2, 6, 7, 8, 25, 100],
  [2, 6, 7, 8, 50, 75],
  [2, 6, 7, 8, 75, 100],
  [2, 6, 7, 9, 10, 25],
  [2, 6, 7, 9, 10, 50],
  [2, 6, 7, 9, 10, 75],
  [2, 6, 7, 9, 10, 100],
  [2, 6, 7, 9, 25, 50],
  [2, 6, 7, 9, 25, 75],
  [2, 6, 7, 9, 25, 100],
  [2, 6, 7, 9, 50, 75],
  [2, 6, 7, 9, 50, 100],
  [2, 6, 7, 9, 75, 100],
  [2, 6, 7, 10, 25, 50],
  [2, 6, 7, 10, 25, 75],
  [2, 6, 7, 10, 25, 100],
  [2, 6, 7, 10, 50, 75],
  [2, 6, 7, 10, 50, 100],
  [2, 6, 7, 10, 75, 100],
  [2, 6, 8, 8, 9, 100],
  [2, 6, 8, 9, 9, 100],
  [2, 6, 8, 9, 10, 50],
  [2, 6, 8, 9, 10, 75],
  [2, 6, 8, 9, 10, 100],
  [2, 6, 8, 9, 25, 50],
  [2, 6, 8, 9, 25, 75],
  [2, 6, 8, 9, 25, 100],
  [2, 6, 8, 9, 50, 75],
  [2, 6, 8, 9, 50, 100],
  [2, 6, 8, 9, 75, 100],
  [2, 6, 8, 10, 25, 50],
  [2, 6, 8, 10, 25, 75],
  [2, 6, 8, 10, 25, 100],
  [2, 6, 8, 10, 75, 100],
  [2, 6, 9, 9, 10, 50],
  [2, 6, 9, 9, 10, 100],
  [2, 6, 9, 9, 75, 100],
  [2, 6, 9, 10, 25, 50],
  [2, 6, 9, 10, 25, 75],
  [2, 6, 9, 10, 25, 100],
  [2, 6, 9, 10, 50, 75],
  [2, 6, 9, 10, 50, 100],
  [2, 6, 9, 10, 75, 100],
  [2, 7, 7, 8, 9, 100],
  [2, 7, 7, 8, 10, 100],
  [2, 7, 7, 8, 25, 100],
  [2, 7, 7, 9, 10, 100],
  [2, 7, 8, 8, 9, 100],
  [2, 7, 8, 8, 50, 75],
  [2, 7, 8, 8, 75, 100],
  [2, 7, 8, 9, 9, 50],
  [2, 7, 8, 9, 9, 100],
  [2, 7, 8, 9, 10, 50],
  [2, 7, 8, 9, 10, 75],
  [2, 7, 8, 9, 10, 100],
  [2, 7, 8, 9, 25, 50],
  [2, 7, 8, 9, 25, 75],
  [2, 7, 8, 9, 25, 100],
  [2, 7, 8, 9, 50, 75],
  [2, 7, 8, 9, 50, 100],
  [2, 7, 8, 9, 75, 100],
  [2, 7, 8, 10, 25, 50],
  [2, 7, 8, 10, 25, 75],
  [2, 7, 8, 10, 25, 100],
  [2, 7, 8, 10, 50, 75],
  [2, 7, 8, 10, 50, 100],
  [2, 7, 8, 10, 75, 100],
  [2, 7, 9, 9, 10, 50],
  [2, 7, 9, 9, 10, 100],
  [2, 7, 9, 10, 10, 50],
  [2, 7, 9, 10, 10, 75],
  [2, 7, 9, 10, 10, 100],
  [2, 7, 9, 10, 25, 50],
  [2, 7, 9, 10, 25, 75],
  [2, 7, 9, 10, 25, 100],
  [2, 7, 9, 10, 50, 75],
  [2, 7, 9, 10, 50, 100],
  [2, 7, 9, 10, 75, 100],
  [2, 8, 8, 9, 75, 100],
  [2, 8, 9, 10, 25, 50],
  [2, 8, 9, 10, 25, 75],
  [2, 8, 9, 10, 25, 100],
  [2, 8, 9, 10, 50, 75],
  [2, 8, 9, 10, 75, 100],
  [2, 8, 9, 25, 75, 100],
  [2, 8, 9, 50, 75, 100],
  [3, 3, 4, 5, 7, 75],
  [3, 3, 4, 5, 7, 100],
  [3, 3, 4, 5, 8, 75],
  [3, 3, 4, 5, 10, 100],
  [3, 3, 4, 7, 9, 100],
  [3, 3, 4, 7, 10, 75],
  [3, 3, 4, 10, 50, 75],
  [3, 3, 4, 10, 50, 100],
  [3, 3, 5, 6, 7, 100],
  [3, 3, 5, 6, 8, 100],
  [3, 3, 5, 7, 8, 100],
  [3, 3, 5, 7, 9, 100],
  [3, 3, 5, 7, 10, 100],
  [3, 3, 5, 8, 10, 100],
  [3, 3, 6, 7, 8, 100],
  [3, 3, 6, 8, 10, 100],
  [3, 3, 6, 10, 75, 100],
  [3, 3, 7, 8, 9, 100],
  [3, 3, 8, 9, 10, 75],
  [3, 3, 8, 10, 75, 100],
  [3, 4, 4, 5, 50, 75],
  [3, 4, 4, 7, 10, 75],
  [3, 4, 4, 9, 10, 75],
  [3, 4, 4, 10, 25, 75],
  [3, 4, 4, 10, 50, 75],
  [3, 4, 4, 10, 75, 100],
  [3, 4, 5, 6, 7, 25],
  [3, 4, 5, 6, 7, 50],
  [3, 4, 5, 6, 7, 75],
  [3, 4, 5, 6, 7, 100],
  [3, 4, 5, 6, 8, 75],
  [3, 4, 5, 6, 8, 100],
  [3, 4, 5, 6, 9, 50],
  [3, 4, 5, 6, 9, 75],
  [3, 4, 5, 6, 9, 100],
  [3, 4, 5, 6, 10, 25],
  [3, 4, 5, 6, 10, 75],
  [3, 4, 5, 6, 25, 50],
  [3, 4, 5, 6, 50, 75],
  [3, 4, 5, 6, 75, 100],
  [3, 4, 5, 7, 7, 75],
  [3, 4, 5, 7, 8, 25],
  [3, 4, 5, 7, 8, 50],
  [3, 4, 5, 7, 9, 25],
  [3, 4, 5, 7, 9, 50],
  [3, 4, 5, 7, 9, 75],
  [3, 4, 5, 7, 9, 100],
  [3, 4, 5, 7, 10, 25],
  [3, 4, 5, 7, 10, 75],
  [3, 4, 5, 7, 10, 100],
  [3, 4, 5, 7, 25, 50],
  [3, 4, 5, 7, 25, 75],
  [3, 4, 5, 7, 50, 75],
  [3, 4, 5, 7, 75, 100],
  [3, 4, 5, 8, 9, 25],
  [3, 4, 5, 8, 9, 100],
  [3, 4, 5, 8, 10, 25],
  [3, 4, 5, 8, 10, 75],
  [3, 4, 5, 8, 10, 100],
  [3, 4, 5, 8, 25, 50],
  [3, 4, 5, 8, 25, 75],
  [3, 4, 5, 8, 50, 75],
  [3, 4, 5, 8, 75, 100],
  [3, 4, 5, 9, 10, 50],
  [3, 4, 5, 9, 10, 75],
  [3, 4, 5, 9, 10, 100],
  [3, 4, 5, 9, 25, 50],
  [3, 4, 5, 9, 25, 75],
  [3, 4, 5, 9, 25, 100],
  [3, 4, 5, 9, 50, 75],
  [3, 4, 5, 9, 50, 100],
  [3, 4, 5, 9, 75, 100],
  [3, 4, 6, 7, 8, 25],
  [3, 4, 6, 7, 8, 50],
  [3, 4, 6, 7, 8, 75],
  [3, 4, 6, 7, 8, 100],
  [3, 4, 6, 7, 9, 75],
  [3, 4, 6, 7, 9, 100],
  [3, 4, 6, 7, 10, 25],
  [3, 4, 6, 7, 10, 50],
  [3, 4, 6, 7, 10, 75],
  [3, 4, 6, 7, 25, 75],
  [3, 4, 6, 7, 25, 100],
  [3, 4, 6, 8, 9, 25],
  [3, 4, 6, 8, 9, 75],
  [3, 4, 6, 8, 9, 100],
  [3, 4, 6, 8, 10, 75],
  [3, 4, 6, 8, 10, 100],
  [3, 4, 6, 8, 25, 50],
  [3, 4, 6, 8, 50, 75],
  [3, 4, 6, 8, 75, 100],
  [3, 4, 6, 9, 10, 25],
  [3, 4, 6, 9, 10, 50],
  [3, 4, 6, 9, 10, 75],
  [3, 4, 6, 9, 10, 100],
  [3, 4, 6, 9, 25, 50],
  [3, 4, 6, 9, 25, 100],
  [3, 4, 6, 9, 50, 75],
  [3, 4, 6, 9, 50, 100],
  [3, 4, 6, 10, 25, 50],
  [3, 4, 6, 10, 25, 75],
  [3, 4, 6, 10, 25, 100],
  [3, 4, 6, 10, 75, 100],
  [3, 4, 7, 7, 9, 100],
  [3, 4, 7, 8, 9, 10],
  [3, 4, 7, 8, 9, 25],
  [3, 4, 7, 8, 9, 50],
  [3, 4, 7, 8, 9, 75],
  [3, 4, 7, 8, 9, 100],
  [3, 4, 7, 8, 10, 75],
  [3, 4, 7, 8, 10, 100],
  [3, 4, 7, 8, 25, 50],
  [3, 4, 7, 8, 25, 75],
  [3, 4, 7, 8, 50, 75],
  [3, 4, 7, 8, 75, 100],
  [3, 4, 7, 9, 9, 100],
  [3, 4, 7, 9, 10, 25],
  [3, 4, 7, 9, 10, 75],
  [3, 4, 7, 9, 10, 100],
  [3, 4, 7, 9, 25, 50],
  [3, 4, 7, 9, 25, 100],
  [3, 4, 7, 9, 50, 100],
  [3, 4, 7, 9, 75, 100],
  [3, 4, 7, 10, 10, 75],
  [3, 4, 7, 10, 25, 75],
  [3, 4, 7, 10, 25, 100],
  [3, 4, 7, 10, 50, 75],
  [3, 4, 7, 10, 50, 100],
  [3, 4, 7, 10, 75, 100],
  [3, 4, 8, 9, 10, 25],
  [3, 4, 8, 9, 10, 75],
  [3, 4, 8, 9, 10, 100],
  [3, 4, 8, 9, 25, 50],
  [3, 4, 8, 9, 25, 100],
  [3, 4, 8, 9, 50, 75],
  [3, 4, 8, 9, 75, 100],
  [3, 4, 8, 10, 25, 75],
  [3, 4, 8, 10, 25, 100],
  [3, 4, 8, 10, 50, 75],
  [3, 4, 8, 10, 75, 100],
  [3, 4, 9, 9, 10, 100],
  [3, 4, 9, 10, 25, 50],
  [3, 4, 9, 10, 25, 75],
  [3, 4, 9, 10, 25, 100],
  [3, 4, 9, 10, 50, 75],
  [3, 4, 9, 10, 75, 100],
  [3, 5, 6, 7, 7, 50],
  [3, 5, 6, 7, 8, 25],
  [3, 5, 6, 7, 8, 50],
  [3, 5, 6, 7, 8, 75],
  [3, 5, 6, 7, 8, 100],
  [3, 5, 6, 7, 9, 25],
  [3, 5, 6, 7, 9, 50],
  [3, 5, 6, 7, 9, 75],
  [3, 5, 6, 7, 9, 100],
  [3, 5, 6, 7, 10, 50],
  [3, 5, 6, 7, 10, 75],
  [3, 5, 6, 7, 10, 100],
  [3, 5, 6, 7, 50, 100],
  [3, 5, 6, 8, 9, 25],
  [3, 5, 6, 8, 9, 50],
  [3, 5, 6, 8, 9, 75],
  [3, 5, 6, 8, 9, 100],
  [3, 5, 6, 8, 10, 75],
  [3, 5, 6, 8, 10, 100],
  [3, 5, 6, 8, 25, 50],
  [3, 5, 6, 8, 25, 75],
  [3, 5, 6, 8, 25, 100],
  [3, 5, 6, 8, 75, 100],
  [3, 5, 6, 9, 10, 50],
  [3, 5, 6, 9, 10, 75],
  [3, 5, 6, 9, 10, 100],
  [3, 5, 6, 9, 25, 50],
  [3, 5, 6, 9, 50, 100],
  [3, 5, 6, 9, 75, 100],
  [3, 5, 6, 10, 75, 100],
  [3, 5, 7, 7, 9, 50],
  [3, 5, 7, 7, 9, 100],
  [3, 5, 7, 8, 9, 25],
  [3, 5, 7, 8, 9, 50],
  [3, 5, 7, 8, 9, 75],
  [3, 5, 7, 8, 9, 100],
  [3, 5, 7, 8, 10, 100],
  [3, 5, 7, 8, 25, 100],
  [3, 5, 7, 8, 50, 75],
  [3, 5, 7, 9, 9, 100],
  [3, 5, 7, 9, 10, 50],
  [3, 5, 7, 9, 10, 75],
  [3, 5, 7, 9, 10, 100],
  [3, 5, 7, 9, 25, 50],
  [3, 5, 7, 9, 25, 75],
  [3, 5, 7, 9, 50, 75],
  [3, 5, 7, 9, 50, 100],
  [3, 5, 7, 9, 75, 100],
  [3, 5, 8, 8, 10, 100],
  [3, 5, 8, 9, 10, 75],
  [3, 5, 8, 9, 10, 100],
  [3, 5, 8, 9, 25, 50],
  [3, 5, 8, 9, 25, 75],
  [3, 5, 8, 9, 25, 100],
  [3, 5, 8, 9, 50, 75],
  [3, 5, 8, 9, 50, 100],
  [3, 5, 8, 9, 75, 100],
  [3, 5, 9, 10, 75, 100],
  [3, 6, 7, 7, 9, 100],
  [3, 6, 7, 7, 10, 100],
  [3, 6, 7, 8, 9, 50],
  [3, 6, 7, 8, 9, 100],
  [3, 6, 7, 8, 10, 25],
  [3, 6, 7, 8, 10, 50],
  [3, 6, 7, 8, 10, 75],
  [3, 6, 7, 8, 10, 100],
  [3, 6, 7, 8, 25, 50],
  [3, 6, 7, 8, 25, 75],
  [3, 6, 7, 8, 25, 100],
  [3, 6, 7, 8, 50, 75],
  [3, 6, 7, 8, 50, 100],
  [3, 6, 7, 8, 75, 100],
  [3, 6, 7, 9, 10, 100],
  [3, 6, 7, 9, 25, 50],
  [3, 6, 7, 9, 50, 100],
  [3, 6, 7, 10, 25, 50],
  [3, 6, 7, 10, 25, 75],
  [3, 6, 7, 10, 25, 100],
  [3, 6, 7, 10, 50, 75],
  [3, 6, 7, 10, 50, 100],
  [3, 6, 7, 10, 75, 100],
  [3, 6, 8, 8, 9, 100],
  [3, 6, 8, 8, 10, 75],
  [3, 6, 8, 9, 10, 50],
  [3, 6, 8, 9, 10, 100],
  [3, 6, 8, 9, 25, 50],
  [3, 6, 8, 9, 25, 100],
  [3, 6, 8, 9, 50, 75],
  [3, 6, 8, 9, 75, 100],
  [3, 6, 8, 10, 10, 75],
  [3, 6, 8, 10, 25, 50],
  [3, 6, 8, 10, 25, 75],
  [3, 6, 8, 10, 25, 100],
  [3, 6, 8, 10, 50, 75],
  [3, 6, 8, 10, 75, 100],
  [3, 6, 9, 10, 25, 50],
  [3, 6, 9, 10, 25, 75],
  [3, 6, 9, 10, 25, 100],
  [3, 6, 9, 10, 50, 75],
  [3, 6, 9, 10, 50, 100],
  [3, 7, 7, 8, 9, 100],
  [3, 7, 7, 8, 10, 100],
  [3, 7, 8, 9, 10, 25],
  [3, 7, 8, 9, 10, 50],
  [3, 7, 8, 9, 10, 75],
  [3, 7, 8, 9, 10, 100],
  [3, 7, 8, 9, 25, 50],
  [3, 7, 8, 9, 25, 100],
  [3, 7, 8, 9, 50, 75],
  [3, 7, 8, 9, 75, 100],
  [3, 7, 8, 10, 25, 50],
  [3, 7, 8, 10, 25, 100],
  [3, 7, 8, 10, 50, 75],
  [3, 7, 8, 10, 50, 100],
  [3, 7, 8, 10, 75, 100],
  [3, 7, 9, 10, 25, 75],
  [3, 7, 9, 10, 25, 100],
  [3, 7, 9, 10, 75, 100],
  [3, 8, 8, 9, 10, 100],
  [3, 8, 8, 10, 75, 100],
  [3, 8, 9, 10, 10, 75],
  [3, 8, 9, 10, 25, 50],
  [3, 8, 9, 10, 25, 75],
  [3, 8, 9, 10, 50, 75],
  [3, 8, 9, 10, 50, 100],
  [3, 8, 9, 10, 75, 100],
  [4, 4, 6, 7, 9, 100],
  [4, 4, 7, 9, 10, 25],
  [4, 4, 7, 9, 10, 100],
  [4, 4, 7, 10, 25, 100],
  [4, 5, 5, 7, 9, 100],
  [4, 5, 6, 7, 8, 25],
  [4, 5, 6, 7, 8, 75],
  [4, 5, 6, 7, 8, 100],
  [4, 5, 6, 7, 9, 25],
  [4, 5, 6, 7, 9, 75],
  [4, 5, 6, 7, 9, 100],
  [4, 5, 6, 7, 10, 25],
  [4, 5, 6, 7, 10, 50],
  [4, 5, 6, 7, 10, 100],
  [4, 5, 6, 7, 50, 75],
  [4, 5, 6, 7, 75, 100],
  [4, 5, 6, 8, 9, 25],
  [4, 5, 6, 8, 9, 75],
  [4, 5, 6, 8, 9, 100],
  [4, 5, 6, 8, 10, 75],
  [4, 5, 6, 8, 10, 100],
  [4, 5, 6, 8, 25, 50],
  [4, 5, 6, 8, 25, 75],
  [4, 5, 6, 8, 25, 100],
  [4, 5, 6, 8, 50, 75],
  [4, 5, 6, 8, 75, 100],
  [4, 5, 7, 7, 8, 25],
  [4, 5, 7, 7, 8, 100],
  [4, 5, 7, 7, 9, 100],
  [4, 5, 7, 8, 9, 25],
  [4, 5, 7, 8, 9, 75],
  [4, 5, 7, 8, 9, 100],
  [4, 5, 7, 8, 10, 25],
  [4, 5, 7, 8, 10, 75],
  [4, 5, 7, 8, 10, 100],
  [4, 5, 7, 8, 50, 75],
  [4, 5, 7, 8, 50, 100],
  [4, 5, 7, 8, 75, 100],
  [4, 5, 7, 9, 9, 100],
  [4, 5, 7, 9, 10, 25],
  [4, 5, 7, 9, 10, 50],
  [4, 5, 7, 9, 10, 75],
  [4, 5, 7, 9, 10, 100],
  [4, 5, 7, 9, 25, 50],
  [4, 5, 7, 9, 25, 100],
  [4, 5, 7, 9, 50, 75],
  [4, 5, 7, 9, 50, 100],
  [4, 5, 7, 9, 75, 100],
  [4, 5, 7, 10, 25, 100],
  [4, 5, 7, 10, 75, 100],
  [4, 5, 8, 9, 10, 25],
  [4, 5, 8, 9, 10, 75],
  [4, 5, 8, 9, 10, 100],
  [4, 5, 8, 9, 25, 50],
  [4, 5, 8, 9, 25, 75],
  [4, 5, 8, 9, 25, 100],
  [4, 5, 8, 9, 50, 100],
  [4, 5, 8, 9, 75, 100],
  [4, 5, 8, 10, 25, 75],
  [4, 6, 6, 7, 9, 25],
  [4, 6, 6, 7, 9, 100],
  [4, 6, 7, 7, 9, 100],
  [4, 6, 7, 8, 9, 25],
  [4, 6, 7, 8, 9, 50],
  [4, 6, 7, 8, 9, 75],
  [4, 6, 7, 8, 9, 100],
  [4, 6, 7, 8, 10, 75],
  [4, 6, 7, 8, 25, 50],
  [4, 6, 7, 8, 25, 100],
  [4, 6, 7, 8, 50, 75],
  [4, 6, 7, 8, 75, 100],
  [4, 6, 7, 9, 9, 100],
  [4, 6, 7, 9, 10, 25],
  [4, 6, 7, 9, 10, 50],
  [4, 6, 7, 9, 10, 100],
  [4, 6, 7, 9, 25, 50],
  [4, 6, 7, 9, 25, 75],
  [4, 6, 7, 9, 25, 100],
  [4, 6, 7, 9, 50, 75],
  [4, 6, 7, 9, 50, 100],
  [4, 6, 7, 9, 75, 100],
  [4, 6, 7, 10, 25, 50],
  [4, 6, 7, 10, 50, 75],
  [4, 6, 7, 10, 50, 100],
  [4, 6, 8, 9, 9, 100],
  [4, 6, 8, 9, 10, 75],
  [4, 6, 8, 9, 10, 100],
  [4, 6, 8, 9, 25, 50],
  [4, 6, 8, 9, 25, 75],
  [4, 6, 8, 9, 25, 100],
  [4, 6, 8, 9, 50, 75],
  [4, 6, 8, 9, 75, 100],
  [4, 6, 8, 10, 75, 100],
  [4, 7, 7, 8, 9, 100],
  [4, 7, 7, 8, 10, 25],
  [4, 7, 7, 9, 10, 75],
  [4, 7, 7, 9, 10, 100],
  [4, 7, 7, 10, 25, 100],
  [4, 7, 8, 9, 9, 100],
  [4, 7, 8, 9, 10, 25],
  [4, 7, 8, 9, 10, 50],
  [4, 7, 8, 9, 10, 75],
  [4, 7, 8, 9, 10, 100],
  [4, 7, 8, 9, 25, 100],
  [4, 7, 8, 9, 50, 100],
  [4, 7, 8, 9, 75, 100],
  [4, 7, 8, 10, 25, 75],
  [4, 7, 8, 10, 25, 100],
  [4, 7, 8, 10, 50, 75],
  [4, 7, 8, 10, 50, 100],
  [4, 7, 8, 10, 75, 100],
  [4, 7, 9, 9, 10, 50],
  [4, 7, 9, 9, 10, 100],
  [4, 7, 9, 9, 75, 100],
  [4, 7, 9, 10, 10, 100],
  [4, 7, 9, 10, 25, 50],
  [4, 7, 9, 10, 25, 75],
  [4, 7, 9, 10, 25, 100],
  [4, 7, 9, 10, 50, 75],
  [4, 7, 9, 10, 50, 100],
  [4, 7, 9, 10, 75, 100],
  [4, 8, 8, 9, 10, 75],
  [4, 8, 9, 9, 10, 25],
  [4, 8, 9, 10, 25, 50],
  [4, 8, 9, 10, 25, 75],
  [4, 8, 9, 10, 25, 100],
  [4, 8, 9, 10, 50, 75],
  [4, 8, 9, 10, 75, 100],
  [5, 5, 6, 7, 8, 100],
  [5, 5, 6, 7, 9, 100],
  [5, 5, 8, 9, 75, 100],
  [5, 6, 6, 7, 9, 100],
  [5, 6, 6, 7, 50, 100],
  [5, 6, 6, 8, 9, 100],
  [5, 6, 6, 8, 10, 75],
  [5, 6, 7, 7, 9, 100],
  [5, 6, 7, 7, 10, 100],
  [5, 6, 7, 8, 9, 10],
  [5, 6, 7, 8, 9, 25],
  [5, 6, 7, 8, 9, 75],
  [5, 6, 7, 8, 9, 100],
  [5, 6, 7, 8, 10, 25],
  [5, 6, 7, 8, 10, 75],
  [5, 6, 7, 8, 10, 100],
  [5, 6, 7, 8, 25, 50],
  [5, 6, 7, 8, 25, 75],
  [5, 6, 7, 8, 50, 100],
  [5, 6, 7, 8, 75, 100],
  [5, 6, 7, 9, 9, 100],
  [5, 6, 7, 9, 10, 50],
  [5, 6, 7, 9, 10, 75],
  [5, 6, 7, 9, 10, 100],
  [5, 6, 7, 9, 25, 50],
  [5, 6, 7, 9, 25, 75],
  [5, 6, 7, 9, 25, 100],
  [5, 6, 7, 9, 50, 100],
  [5, 6, 7, 9, 75, 100],
  [5, 6, 7, 10, 25, 100],
  [5, 6, 7, 10, 75, 100],
  [5, 6, 8, 8, 9, 100],
  [5, 6, 8, 8, 10, 75],
  [5, 6, 8, 8, 25, 100],
  [5, 6, 8, 8, 75, 100],
  [5, 6, 8, 9, 9, 100],
  [5, 6, 8, 9, 10, 25],
  [5, 6, 8, 9, 10, 100],
  [5, 6, 8, 9, 25, 50],
  [5, 6, 8, 9, 25, 75],
  [5, 6, 8, 9, 25, 100],
  [5, 6, 8, 9, 50, 75],
  [5, 6, 8, 9, 50, 100],
  [5, 6, 8, 9, 75, 100],
  [5, 6, 8, 10, 25, 75],
  [5, 6, 8, 10, 25, 100],
  [5, 7, 7, 8, 9, 100],
  [5, 7, 7, 9, 10, 100],
  [5, 7, 7, 9, 75, 100],
  [5, 7, 8, 8, 9, 100],
  [5, 7, 8, 9, 9, 100],
  [5, 7, 8, 9, 10, 50],
  [5, 7, 8, 9, 10, 75],
  [5, 7, 8, 9, 10, 100],
  [5, 7, 8, 9, 25, 50],
  [5, 7, 8, 9, 25, 75],
  [5, 7, 8, 9, 25, 100],
  [5, 7, 8, 9, 50, 100],
  [5, 7, 8, 9, 75, 100],
  [5, 7, 8, 10, 25, 100],
  [5, 7, 9, 9, 10, 100],
  [5, 7, 9, 9, 50, 100],
  [5, 7, 9, 9, 75, 100],
  [5, 7, 9, 10, 75, 100],
  [5, 8, 9, 9, 25, 100],
  [5, 8, 9, 9, 75, 100],
  [5, 8, 9, 10, 75, 100],
  [6, 6, 7, 8, 9, 100],
  [6, 6, 7, 9, 10, 25],
  [6, 6, 7, 9, 10, 100],
  [6, 6, 7, 9, 25, 100],
  [6, 6, 8, 9, 25, 50],
  [6, 6, 8, 9, 25, 100],
  [6, 7, 7, 8, 9, 100],
  [6, 7, 7, 8, 10, 100],
  [6, 7, 7, 9, 10, 25],
  [6, 7, 7, 9, 10, 75],
  [6, 7, 7, 9, 10, 100],
  [6, 7, 7, 10, 25, 100],
  [6, 7, 8, 8, 9, 10],
  [6, 7, 8, 8, 9, 75],
  [6, 7, 8, 8, 10, 100],
  [6, 7, 8, 8, 25, 100],
  [6, 7, 8, 9, 9, 100],
  [6, 7, 8, 9, 10, 10],
  [6, 7, 8, 9, 10, 50],
  [6, 7, 8, 9, 10, 75],
  [6, 7, 8, 9, 10, 100],
  [6, 7, 8, 9, 25, 50],
  [6, 7, 8, 9, 25, 75],
  [6, 7, 8, 9, 25, 100],
  [6, 7, 8, 9, 50, 75],
  [6, 7, 8, 9, 50, 100],
  [6, 7, 8, 9, 75, 100],
  [6, 7, 8, 10, 25, 50],
  [6, 7, 8, 10, 25, 75],
  [6, 7, 8, 10, 25, 100],
  [6, 7, 8, 10, 50, 75],
  [6, 7, 8, 10, 75, 100],
  [6, 7, 9, 9, 10, 50],
  [6, 7, 9, 9, 10, 100],
  [6, 7, 9, 9, 25, 100],
  [6, 7, 9, 10, 25, 50],
  [6, 7, 9, 10, 25, 75],
  [6, 7, 9, 10, 25, 100],
  [6, 7, 9, 10, 50, 100],
  [6, 7, 9, 10, 75, 100],
  [6, 7, 10, 25, 50, 100],
  [6, 8, 8, 9, 75, 100],
  [6, 8, 9, 9, 10, 100],
  [6, 8, 9, 10, 25, 50],
  [6, 8, 9, 10, 25, 75],
  [6, 8, 9, 10, 50, 75],
  [6, 8, 9, 10, 50, 100],
  [6, 8, 9, 10, 75, 100],
  [6, 8, 9, 25, 75, 100],
  [7, 7, 8, 9, 10, 100],
  [7, 8, 8, 10, 25, 100],
  [7, 8, 9, 10, 25, 50],
  [7, 8, 9, 10, 25, 75],
  [7, 8, 9, 10, 25, 100],
  [7, 8, 9, 10, 50, 100],
  [7, 8, 9, 10, 75, 100],
  [8, 9, 9, 10, 25, 75],
];
