import React from "react";

interface CurrentInputProps {
  text: string;
}

const CurrentInput = ({ text }: CurrentInputProps) => {
  if (text.length === 0) {
    return <></>;
  }
  return (
    <div>
      <div>Input</div>
      <h5>{text}</h5>
    </div>
  );
};

export default CurrentInput;
