/**
 * Returns a map of all the guessed words
 * @param guessedWords
 */
export const getInitialWordsMap = (): Map<string, number> => {
  const nonHiddenWordsMap = new Map<string, number>();
  for (let i = 0; i < nonHiddenWords.length; i++) {
    nonHiddenWordsMap.set(nonHiddenWords[i].toLowerCase(), 0);
  }
  for (let i = 0; i < allowedPunctionation.length; i++) {
    nonHiddenWordsMap.set(allowedPunctionation[i], 0);
  }
  return nonHiddenWordsMap;
};

/**
 * Returns a map of all the guessed words
 * @param guessedWords
 */
export const updateMapWithNewWord = (
  newWord: string,
  wordsMap: Map<string, number>,
  biologyText: string[]
): Map<string, number> => {
  const lowerCaseWord = newWord.toLowerCase();
  const updatedMap = new Map(wordsMap);
  updatedMap.set(newWord, 0);
  for (let i = 0; i < biologyText.length; i++) {
    if (biologyText[i].toLowerCase() === lowerCaseWord) {
      //@ts-ignore
      updatedMap.set(lowerCaseWord, updatedMap.get(lowerCaseWord) + 1);
    }
  }

  return updatedMap;
};

export const nonHiddenWords = [
  "a",
  "about",
  "across",
  "after",
  "against",
  "along",
  "among",
  "an",
  "and",
  "around",
  "as",
  "at",
  "behind",
  "between",
  "beyond",
  "but",
  "below",
  "because",
  "beyond",
  "body",
  "by",
  "down",
  "during",
  "except",
  "for",
  "from",
  "has",
  "if",
  "in",
  "into",
  "is",
  "it",
  "its",
  "like",
  "of",
  "off",
  "on",
  "or",
  "over",
  "since",
  "some",
  "the",
  "than",
  "through",
  "to",
  "under",
  "until",
  "was",
  "will",
  "with",
  "within",
];

export const allowedPunctionation = [", ", ". ", " ", "-", "'"];
