import { Amplify, Analytics } from "aws-amplify";
import awsconfig from "./aws-exports";

const localhost = "localhost";

export const configureAnalytics = (hostname : string) => {
  if (hostname !== localhost) {
    trackAnalytics();
  }
};

const trackAnalytics = () => {
  Amplify.configure(awsconfig);
  Analytics.autoTrack("session", {
    enable: true,
  });

  Analytics.autoTrack("pageView", {
    enable: true,
    type: "SPA",
    getUrl: () => {
      return window.location.origin + window.location.pathname;
    },
  });
};
