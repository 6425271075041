import React from "react";

interface MarketCapComparisonMessageProps {
  company1: any;
  company2: any;
  isRevealed: boolean;
}

const MarketCapComparisonMessage = ({
  company1,
  company2,
  isRevealed,
}: MarketCapComparisonMessageProps) => {
  if (!isRevealed) {
    return <></>;
  }
  let largerMarketCapCompany = company2;
  let smallerMarketCapCompany = company1;
  let difference = parseInt(company2.MarketCap) - parseInt(company1.MarketCap);
  if (company1.MarketCap > company2.MarketCap) {
    largerMarketCapCompany = company1;
    smallerMarketCapCompany = company2;
    difference = parseInt(company1.MarketCap) - parseInt(company2.MarketCap);
  }

  return (
    <div>
      {largerMarketCapCompany.Name}'s market cap is larger than{" "}
      {smallerMarketCapCompany.Name}'s Market cap by $
      {difference.toLocaleString()}
    </div>
  );
};

export default MarketCapComparisonMessage;
