import { Container } from "@mantine/core";
import React from "react";
import Description from "./components/Description";


const Plotle = () => {

  return (
    <Container fluid>
      <h1>Plotle</h1>
<Description />
    </Container>
  );
};

export default Plotle;
