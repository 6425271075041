import React from "react";
import { VSCompany } from "../market-cap-comparer";
import MarketCapComparisonMessage from "./MarketCapComparisonMessage";

interface LoserComponentProps {
  streak: number;
  handlePlayAgain: Function;
  vsCompanies: VSCompany[];
}

const LoserComponent = ({
  streak,
  handlePlayAgain,
  vsCompanies,
}: LoserComponentProps) => {
  return (
    <div
      style={{
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>
        <h1>You Lost!</h1>
        <MarketCapComparisonMessage
          company1={vsCompanies[0]}
          company2={vsCompanies[1]}
          isRevealed={true}
        />
        <div>Your streak was {streak}</div>
        <button
          onClick={() => {
            handlePlayAgain();
          }}
        >
          Play Again
        </button>
      </div>
    </div>
  );
};

export default LoserComponent;
