import React from "react";

interface TimerProps {
  currentTime: number;
}

const Timer = ({ currentTime }: TimerProps) => {
  const minutes = Math.floor(currentTime / 60);
  const seconds = Math.floor(currentTime % 60);
  return (
    <div style={{ margin: "10vh", border: "dashed red" }}>
      <h3>Current Time:</h3>
      <span>
        {minutes}:{seconds}
      </span>
    </div>
  );
};

export default Timer;
