import React, { useState } from "react";
import AllCardContainer from "./AllCardContainer";
import Timer from "./Timer";

interface GameContainerProps {
  difficulty: string;
}

const GameContainer = ({ difficulty }: GameContainerProps) => {
  const [currentSeconds, setCurrentSeconds] = useState(0);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AllCardContainer
        difficulty={difficulty}
        setCurrentSeconds={setCurrentSeconds}
        currentSeconds={currentSeconds}
      />
      <Timer currentTime={currentSeconds} />
    </div>
  );
};

export default GameContainer;
