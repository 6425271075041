import React from "react";
import DifficultyMenu from "./DifficultyMenu";
import GameContainer from "./GameContainer";
import GameOverComponent from "./GameOverComponent";
import { usePokemonMemory } from "../context/PokemonMemoryContext";

const PokemonMemoryGameInner = () => {
  const { gameState } = usePokemonMemory();
  return (
    <div style={{ textAlign: "center" }}>
      <div>
        <h1>Pokemon Memory Game</h1>
        <DifficultyMenu />
        {gameState.difficulty !== "" && !gameState.isGameOver && (
          <GameContainer difficulty={gameState.difficulty} />
        )}
        {gameState.isGameOver && <GameOverComponent />}
      </div>
    </div>
  );
};

export default PokemonMemoryGameInner;
