import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

interface PokemonImageProps {
  isPokemonDancing: boolean;
  isHidden: boolean;
  pokemon: any;
}

const PokemonImage = ({
  isHidden,
  pokemon,
  isPokemonDancing,
}: PokemonImageProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const displayValue = !imageLoaded ? { display: "none" } : {};

  return (
    <Row>
      <Col>
        <img
          style={{
            width: "80%",
            height: "auto",
            maxWidth: 250,
            ...displayValue,
          }}
          className={`${isHidden ? "no-brightness" : ""} ${
            isPokemonDancing ? "dancing" : ""
          }`}
          data-testid="PokemonImage"
          alt="pokemon here"
          src={pokemon?.sprites?.front_default}
          onLoad={() => {
            setImageLoaded(true);
          }}
        />
      </Col>
    </Row>
  );
};

export default PokemonImage;
