// https://nickjanetakis.com/blog/insert-multiple-cursors-at-the-start-of-every-line-with-vscode
export const animalWords = [
  "albatross",
  "alligator",
  "ant",
  "anteater",
  "antelope",
  "armadillo",
  "baboon",
  "badger",
  "bandicoot",
  "bat",
  "beagle",
  "bear",
  "beaver",
  "bee",
  "beetle",
  "bird",
  "bison",
  "boar",
  "bobcat",
  "buffalo",
  "bulldog",
  "bullfrog",
  "butterfly",
  "camel",
  "capybara",
  "cat",
  "caterpillar",
  "catfish",
  "centipede",
  "chameleon",
  "cheetah",
  "chicken",
  "chihuahua",
  "chimpanzee",
  "chinchilla",
  "chipmunk",
  "cockroach",
  "cougar",
  "cow",
  "coyote",
  "crab",
  "crane",
  "cuttlefish",
  "deer",
  "dodo",
  "dog",
  "dolphin",
  "donkey",
  "dragon",
  "dragonfly",
  "duck",
  "eagle",
  "earwig",
  "eel",
  "elephant",
  "emu",
  "falcon",
  "ferret",
  "fish",
  "flamingo",
  "flounder",
  "fox",
  "frog",
  "gecko",
  "gerbil",
  "giraffe",
  "goat",
  "goose",
  "gopher",
  "gorilla",
  "grasshopper",
  "greyhound",
  "hamster",
  "hedgehog",
  "heron",
  "hippopotamus",
  "horse",
  "hound",
  "hummingbird",
  "hyena",
  "iguana",
  "jackal",
  "jaguar",
  "jellyfish",
  "kangaroo",
  "kiwi",
  "koala",
  "lemming",
  "lemur",
  "leopard",
  "lion",
  "lizard",
  "llama",
  "lobster",
  "macaque",
  "macaw",
  "mammoth",
  "manatee",
  "meerkat",
  "millipede",
  "mole",
  "mongoose",
  "mongrel",
  "monkey",
  "moose",
  "moth",
  "mouse",
  "mule",
  "newt",
  "nightingale",
  "ocelot",
  "octopus",
  "opossum",
  "orangutan",
  "oriole",
  "ostrich",
  "otter",
  "owl",
  "oyster",
  "panther",
  "parrot",
  "peacock",
  "pelican",
  "penguin",
  "pheasant",
  "pig",
  "pike",
  "piranha",
  "platypus",
  "porcupine",
  "possum",
  "prawn",
  "puffin",
  "puma",
  "quail",
  "rabbit",
  "raccoon",
  "rat",
  "rattlesnake",
  "reindeer",
  "rhinoceros",
  "robin",
  "salamander",
  "scorpion",
  "seahorse",
  "seal",
  "shark",
  "sheep",
  "shrimp",
  "skunk",
  "sloth",
  "snail",
  "snake",
  "sparrow",
  "squid",
  "squirrel",
  "stingray",
  "swan",
  "tarantula",
  "termite",
  "tiger",
  "toad",
  "tortoise",
  "toucan",
  "turkey",
  "turtle",
  "vulture",
  "walrus",
  "warthog",
  "wasp",
  "weasel",
  "whale",
  "wildebeest",
  "wolf",
  "wolverine",
  "wombat",
  "woodpecker",
  "yak",
  "zebra",
];
