import PokemonMemoryGameInner from "./components/PokemonMemoryGameInner";
import { PokemonMemoryContextProvider } from "./context/PokemonMemoryContext";

const PokemonMemoryGame = () => {
  return (
    <PokemonMemoryContextProvider>
      <PokemonMemoryGameInner />
    </PokemonMemoryContextProvider>
  );
};

export default PokemonMemoryGame;
