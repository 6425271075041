export const wellKnownConstituents = [
  {
    Name: "3M",
    Sector: "Industrials",
    Symbol: "MMM",
  },
  {
    Name: "Accenture",
    Sector: "Information Technology",
    Symbol: "ACN",
  },
  {
    Name: "Activision Blizzard",
    Sector: "Communication Services",
    Symbol: "ATVI",
  },
  {
    Name: "Adobe",
    Sector: "Information Technology",
    Symbol: "ADBE",
  },
  {
    Name: "Advance Auto Parts",
    Sector: "Consumer Discretionary",
    Symbol: "AAP",
  },
  {
    Name: "Advanced Micro Devices",
    Sector: "Information Technology",
    Symbol: "AMD",
  },
  {
    Name: "Aflac",
    Sector: "Financials",
    Symbol: "AFL",
  },
  {
    Name: "Alaska Air Group",
    Sector: "Industrials",
    Symbol: "ALK",
  },
  {
    Name: "Alphabet",
    Sector: "Communication Services",
    Symbol: "GOOGL",
  },
  {
    Name: "Amazon",
    Sector: "Consumer Discretionary",
    Symbol: "AMZN",
  },
  {
    Name: "American Airlines Group",
    Sector: "Industrials",
    Symbol: "AAL",
  },
  {
    Name: "American Express",
    Sector: "Financials",
    Symbol: "AXP",
  },
  {
    Name: "Amgen",
    Sector: "Health Care",
    Symbol: "AMGN",
  },
  {
    Name: "Anthem",
    Sector: "Health Care",
    Symbol: "ANTM",
  },
  {
    Name: "Apple",
    Sector: "Information Technology",
    Symbol: "AAPL",
  },
  {
    Name: "AT&T",
    Sector: "Communication Services",
    Symbol: "T",
  },
  {
    Name: "AutoZone",
    Sector: "Consumer Discretionary",
    Symbol: "AZO",
  },
  {
    Name: "Bank of America",
    Sector: "Financials",
    Symbol: "BAC",
  },
  {
    Name: "Berkshire Hathaway",
    Sector: "Financials",
    Symbol: "BRK.B",
  },
  {
    Name: "Best Buy",
    Sector: "Consumer Discretionary",
    Symbol: "BBY",
  },
  {
    Name: "BlackRock",
    Sector: "Financials",
    Symbol: "BLK",
  },
  {
    Name: "Boeing",
    Sector: "Industrials",
    Symbol: "BA",
  },
  {
    Name: "Campbell Soup",
    Sector: "Consumer Staples",
    Symbol: "CPB",
  },
  {
    Name: "Capital One",
    Sector: "Financials",
    Symbol: "COF",
  },
  {
    Name: "Caterpillar",
    Sector: "Industrials",
    Symbol: "CAT",
  },
  {
    Name: "Centene Corporation",
    Sector: "Health Care",
    Symbol: "CNC",
  },
  {
    Name: "Charles Schwab Corporation",
    Sector: "Financials",
    Symbol: "SCHW",
  },
  {
    Name: "Charter Communications",
    Sector: "Communication Services",
    Symbol: "CHTR",
  },
  {
    Name: "Chevron Corporation",
    Sector: "Energy",
    Symbol: "CVX",
  },
  {
    Name: "Chipotle Mexican Grill",
    Sector: "Consumer Discretionary",
    Symbol: "CMG",
  },
  {
    Name: "Cisco Systems",
    Sector: "Information Technology",
    Symbol: "CSCO",
  },
  {
    Name: "Clorox",
    Sector: "Consumer Staples",
    Symbol: "CLX",
  },
  {
    Name: "Coca-Cola Company",
    Sector: "Consumer Staples",
    Symbol: "KO",
  },
  {
    Name: "Comcast",
    Sector: "Communication Services",
    Symbol: "CMCSA",
  },
  {
    Name: "Costco",
    Sector: "Consumer Staples",
    Symbol: "COST",
  },
  {
    Name: "CVS Health",
    Sector: "Health Care",
    Symbol: "CVS",
  },
  {
    Name: "Delta Air Lines",
    Sector: "Industrials",
    Symbol: "DAL",
  },
  {
    Name: "Discover Financial Services",
    Sector: "Financials",
    Symbol: "DFS",
  },
  {
    Name: "Dish Network",
    Sector: "Communication Services",
    Symbol: "DISH",
  },
  {
    Name: "Dollar General",
    Sector: "Consumer Discretionary",
    Symbol: "DG",
  },
  {
    Name: "Dollar Tree",
    Sector: "Consumer Discretionary",
    Symbol: "DLTR",
  },
  {
    Name: "Dominion Energy",
    Sector: "Utilities",
    Symbol: "D",
  },
  {
    Name: "Domino's Pizza",
    Sector: "Consumer Discretionary",
    Symbol: "DPZ",
  },
  {
    Name: "eBay",
    Sector: "Consumer Discretionary",
    Symbol: "EBAY",
  },
  {
    Name: "Electronic Arts",
    Sector: "Communication Services",
    Symbol: "EA",
  },
  {
    Name: "Etsy",
    Sector: "Consumer Discretionary",
    Symbol: "ETSY",
  },
  {
    Name: "Expedia Group",
    Sector: "Consumer Discretionary",
    Symbol: "EXPE",
  },
  {
    Name: "Expeditors",
    Sector: "Industrials",
    Symbol: "EXPD",
  },
  {
    Name: "ExxonMobil",
    Sector: "Energy",
    Symbol: "XOM",
  },
  {
    Name: "F5 Networks",
    Sector: "Information Technology",
    Symbol: "FFIV",
  },
  {
    Name: "Meta",
    Sector: "Communication Services",
    Symbol: "META",
  },
  {
    Name: "FedEx",
    Sector: "Industrials",
    Symbol: "FDX",
  },
  {
    Name: "Fidelity National Information Services",
    Sector: "Information Technology",
    Symbol: "FIS",
  },
  {
    Name: "Ford",
    Sector: "Consumer Discretionary",
    Symbol: "F",
  },
  {
    Name: "Fox Corporation",
    Sector: "Communication Services",
    Symbol: "FOX",
  },
  {
    Name: "Gap",
    Sector: "Consumer Discretionary",
    Symbol: "GPS",
  },
  {
    Name: "Garmin",
    Sector: "Consumer Discretionary",
    Symbol: "GRMN",
  },
  {
    Name: "General Electric",
    Sector: "Industrials",
    Symbol: "GE",
  },
  {
    Name: "General Mills",
    Sector: "Consumer Staples",
    Symbol: "GIS",
  },
  {
    Name: "General Motors",
    Sector: "Consumer Discretionary",
    Symbol: "GM",
  },
  {
    Name: "Goldman Sachs",
    Sector: "Financials",
    Symbol: "GS",
  },
  {
    Name: "Hanesbrands",
    Sector: "Consumer Discretionary",
    Symbol: "HBI",
  },
  {
    Name: "Hasbro",
    Sector: "Consumer Discretionary",
    Symbol: "HAS",
  },
  {
    Name: "Hewlett Packard Enterprise",
    Sector: "Information Technology",
    Symbol: "HPE",
  },
  {
    Name: "Hilton Worldwide",
    Sector: "Consumer Discretionary",
    Symbol: "HLT",
  },
  {
    Name: "Home Depot",
    Sector: "Consumer Discretionary",
    Symbol: "HD",
  },
  {
    Name: "HP",
    Sector: "Information Technology",
    Symbol: "HPQ",
  },
  {
    Name: "IBM",
    Sector: "Information Technology",
    Symbol: "IBM",
  },
  {
    Name: "Intel",
    Sector: "Information Technology",
    Symbol: "INTC",
  },
  {
    Name: "Intuit",
    Sector: "Information Technology",
    Symbol: "INTU",
  },
  {
    Name: "JM Smucker",
    Sector: "Consumer Staples",
    Symbol: "SJM",
  },
  {
    Name: "Johnson & Johnson",
    Sector: "Health Care",
    Symbol: "JNJ",
  },
  {
    Name: "JPMorgan Chase",
    Sector: "Financials",
    Symbol: "JPM",
  },
  {
    Name: "Kellogg's",
    Sector: "Consumer Staples",
    Symbol: "K",
  },
  {
    Name: "Kraft Heinz",
    Sector: "Consumer Staples",
    Symbol: "KHC",
  },
  {
    Name: "Kroger",
    Sector: "Consumer Staples",
    Symbol: "KR",
  },
  {
    Name: "Leidos",
    Sector: "Industrials",
    Symbol: "LDOS",
  },
  {
    Name: "Live Nation Entertainment",
    Sector: "Communication Services",
    Symbol: "LYV",
  },
  {
    Name: "Lockheed Martin",
    Sector: "Industrials",
    Symbol: "LMT",
  },
  {
    Name: "Lowe's",
    Sector: "Consumer Discretionary",
    Symbol: "LOW",
  },
  {
    Name: "Mastercard",
    Sector: "Information Technology",
    Symbol: "MA",
  },
  {
    Name: "Match Group",
    Sector: "Communication Services",
    Symbol: "MTCH",
  },
  {
    Name: "McDonald's",
    Sector: "Consumer Discretionary",
    Symbol: "MCD",
  },
  {
    Name: "MetLife",
    Sector: "Financials",
    Symbol: "MET",
  },
  {
    Name: "MGM Resorts International",
    Sector: "Consumer Discretionary",
    Symbol: "MGM",
  },
  {
    Name: "Microsoft",
    Sector: "Information Technology",
    Symbol: "MSFT",
  },
  {
    Name: "Moderna",
    Sector: "Health Care",
    Symbol: "MRNA",
  },
  {
    Name: "Monster Beverage",
    Sector: "Consumer Staples",
    Symbol: "MNST",
  },
  {
    Name: "Morgan Stanley",
    Sector: "Financials",
    Symbol: "MS",
  },
  {
    Name: "Motorola Solutions",
    Sector: "Information Technology",
    Symbol: "MSI",
  },
  {
    Name: "Netflix",
    Sector: "Communication Services",
    Symbol: "NFLX",
  },
  {
    Name: "Nike",
    Sector: "Consumer Discretionary",
    Symbol: "NKE",
  },
  {
    Name: "Nvidia",
    Sector: "Information Technology",
    Symbol: "NVDA",
  },
  {
    Name: "Oracle",
    Sector: "Information Technology",
    Symbol: "ORCL",
  },
  {
    Name: "PayPal",
    Sector: "Information Technology",
    Symbol: "PYPL",
  },
  {
    Name: "PepsiCo",
    Sector: "Consumer Staples",
    Symbol: "PEP",
  },
  {
    Name: "Pfizer",
    Sector: "Health Care",
    Symbol: "PFE",
  },
  {
    Name: "Principal Financial Group",
    Sector: "Financials",
    Symbol: "PFG",
  },
  {
    Name: "Procter & Gamble",
    Sector: "Consumer Staples",
    Symbol: "PG",
  },
  {
    Name: "Prudential Financial",
    Sector: "Financials",
    Symbol: "PRU",
  },
  {
    Name: "Ralph Lauren Corporation",
    Sector: "Consumer Discretionary",
    Symbol: "RL",
  },
  {
    Name: "Royal Caribbean Group",
    Sector: "Consumer Discretionary",
    Symbol: "RCL",
  },
  {
    Name: "Salesforce",
    Sector: "Information Technology",
    Symbol: "CRM",
  },
  {
    Name: "Sherwin-Williams",
    Sector: "Materials",
    Symbol: "SHW",
  },
  {
    Name: "Southwest Airlines",
    Sector: "Industrials",
    Symbol: "LUV",
  },
  {
    Name: "Stanley Black & Decker",
    Sector: "Industrials",
    Symbol: "SWK",
  },
  {
    Name: "Starbucks",
    Sector: "Consumer Discretionary",
    Symbol: "SBUX",
  },
  {
    Name: "T-Mobile US",
    Sector: "Communication Services",
    Symbol: "TMUS",
  },
  {
    Name: "T. Rowe Price",
    Sector: "Financials",
    Symbol: "TROW",
  },
  {
    Name: "Take-Two Interactive",
    Sector: "Communication Services",
    Symbol: "TTWO",
  },
  {
    Name: "Target Corporation",
    Sector: "Consumer Discretionary",
    Symbol: "TGT",
  },
  {
    Name: "Tesla",
    Sector: "Consumer Discretionary",
    Symbol: "TSLA",
  },
  {
    Name: "Texas Instruments",
    Sector: "Information Technology",
    Symbol: "TXN",
  },
  {
    Name: "The Hershey Company",
    Sector: "Consumer Staples",
    Symbol: "HSY",
  },
  {
    Name: "The Walt Disney Company",
    Sector: "Communication Services",
    Symbol: "DIS",
  },
  {
    Name: "Twitter",
    Sector: "Communication Services",
    Symbol: "TWTR",
  },
  {
    Name: "Tyson Foods",
    Sector: "Consumer Staples",
    Symbol: "TSN",
  },
  {
    Name: "Ulta Beauty",
    Sector: "Consumer Discretionary",
    Symbol: "ULTA",
  },
  {
    Name: "Under Armour",
    Sector: "Consumer Discretionary",
    Symbol: "UAA",
  },
  {
    Name: "United Airlines",
    Sector: "Industrials",
    Symbol: "UAL",
  },
  {
    Name: "United Parcel Service",
    Sector: "Industrials",
    Symbol: "UPS",
  },
  {
    Name: "UnitedHealth Group",
    Sector: "Health Care",
    Symbol: "UNH",
  },
  {
    Name: "Verizon Communications",
    Sector: "Communication Services",
    Symbol: "VZ",
  },
  {
    Name: "Visa",
    Sector: "Information Technology",
    Symbol: "V",
  },
  {
    Name: "Walmart",
    Sector: "Consumer Staples",
    Symbol: "WMT",
  },
  {
    Name: "Wells Fargo",
    Sector: "Financials",
    Symbol: "WFC",
  },
  {
    Name: "Whirlpool Corporation",
    Sector: "Consumer Discretionary",
    Symbol: "WHR",
  },
];
