import React from "react";

interface TargetNumberProps {
  num: number;
  isWinner: boolean;
}

const TargetNumber = ({ num, isWinner }: TargetNumberProps) => {
  return (
    <div
      style={{
        border: "solid",
        backgroundColor: isWinner ? "green" : "white",
        color: isWinner ? "white" : "black",
      }}
    >
      <div>Target</div>
      <h1>{num}</h1>
    </div>
  );
};

export default TargetNumber;
