import React from "react";

export const Heading = () => {
  return (
    <section
      id="Home"
      style={{
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2rem",
        marginBottom: "2rem",
      }}
    >
      <img
        alt="My face is supposed to be here"
        style={{
          height: "auto",
          width: "50%",
          maxWidth: "300px",
          paddingBottom: "1rem",
          borderRadius: "50%",
        }}
        src="/assets/portfolio/kyle.jpeg"
      />
      <h1>Kyle Kenney</h1>
      <h3>Software Engineer in Vienna, VA</h3>
    </section>
  );
};
