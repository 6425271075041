import React, { useEffect, useState } from "react";
import { allFiftyStates, state } from "./constants";
import MainGame from "./MainGame";

const FiftyStates = () => {
  const [stateInput, setStateInput] = useState("");
  const [fiftyStates, setFiftyStates] = useState<state[]>([...allFiftyStates]);
  const [streak, setStreak] = useState(0);
  const [bestStreak, setBestStreak] = useState(0);
  const [currentState, setCurrentState] = useState<state>({
    name: "",
    imgSrc: "",
  });
  const [isGameOver, setIsGameOver] = useState(false);

  useEffect(() => {
    resetGame();
  }, []);

  const resetGame = () => {
    setIsGameOver(false);
    setStreak(0);
    const statePicked = pickRandomState([...allFiftyStates]);
    setCurrentState(statePicked);
    const updatedStateList = allFiftyStates.filter(
      (state) => state.name !== currentState.name
    );
    setFiftyStates(updatedStateList);
  };

  const pickRandomState = (states: state[]): state => {
    const randomIndex = Math.floor(Math.random() * states.length);
    return states[randomIndex];
  };

  const textChange = (stateInput: string) => {
    const stateInputLowerCase = stateInput.toLowerCase();
    if (stateInputLowerCase === currentState.name.toLowerCase()) {
      const updatedStateList = fiftyStates.filter(
        (state) => state.name !== currentState.name
      );
      const randomState = pickRandomState(updatedStateList);
      setCurrentState(randomState);
      setFiftyStates(updatedStateList);
      const updatedStreak = streak + 1;
      setStreak(updatedStreak);
      if (updatedStreak > bestStreak) {
        setBestStreak(updatedStreak);
      }
      setStateInput("");
    } else {
      setStateInput(stateInput);
    }
  };

  const giveUpHandler = () => {
    alert(`Game over! It was ${currentState.name}`);
    if (streak > bestStreak) {
      setBestStreak(streak);
    }
    setIsGameOver(true);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>
        <MainGame
          bestStreak={bestStreak}
          imgSrc={currentState.imgSrc}
          streak={streak}
          isGameOver={isGameOver}
          stateInput={stateInput}
          textChange={textChange}
          giveUpHandler={giveUpHandler}
        />
        {isGameOver && <button onClick={() => resetGame()}>Play Again</button>}
        <footer
          style={{
            textAlign: "center",
          }}
        >
          Map data from <a href="https://gisgeography.com/">GisGeography</a>
        </footer>
      </div>
    </div>
  );
};

export default FiftyStates;
