import React, { SyntheticEvent } from "react";
import { Button } from "react-bootstrap";

interface LetterButtonProps {
  letter?: string;
  handleLetterPressed: Function;
  color?: string;
  isDisabled?: boolean;
}
export const LetterButton = ({
  letter,
  color,
  isDisabled,
  handleLetterPressed,
}: LetterButtonProps) => {
  const handleOnClick = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    handleLetterPressed(target.innerText);
  };
  return (
    <>
      <Button
        disabled={isDisabled}
        onClick={handleOnClick}
        style={{ padding: "10px 10px", margin: "5px" }}
        variant={color}
      >
        {letter}
      </Button>
    </>
  );
};
