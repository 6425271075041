import React from "react";

interface CurrentAnswerProps {
  currentAnswer: number;
}

const CurrentAnswer = ({ currentAnswer }: CurrentAnswerProps) => {
  return (
    <div>
      <div>Current Answer</div>
      <h3>{currentAnswer}</h3>
    </div>
  );
};

export default CurrentAnswer;
