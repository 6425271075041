import React from "react";

interface PokemonDetailsProps {
  name: string;
  imageUrl: string;
  isRevealed: boolean;
}

const PokemonDetails = ({
  name,
  imageUrl,
  isRevealed,
}: PokemonDetailsProps) => {
  if (!isRevealed) {
    return <></>;
  }
  return (
    <div>
      <img
        src={imageUrl}
        alt={"pokemon here"}
        style={{ height: "auto", width: "5vw" }}
      />
      <h6 style={{ fontSize: "1vw" }}>{name}</h6>
    </div>
  );
};

export default PokemonDetails;
