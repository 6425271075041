import React from "react";

interface CurrentWordProps {
  currentWord: string;
  category: string;
  guessedLetters: string[];
}
const CurrentWord = ({
  currentWord,
  category,
  guessedLetters,
}: CurrentWordProps) => {
  const currentWordArr = currentWord.split("");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {currentWordArr.map((letter) => (
        <div
          style={{
            margin: "15px 15px",
          }}
        >
          {guessedLetters.includes(letter) ? <h5>{letter}</h5> : <h5>_</h5>}
        </div>
      ))}
    </div>
  );
};

export default CurrentWord;
