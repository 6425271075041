import React from "react";

interface StockCardProps {
  color: string;
  ticker: string;
  name: string;
  isRevealed: boolean;
  marketCap: string;
  onClick: Function;
  isClickedOn: Boolean;
}

const StockCard = ({
  color,
  ticker,
  name,
  isRevealed,
  marketCap,
  onClick,
  isClickedOn,
}: StockCardProps) => {
  return (
    <button
      disabled={isRevealed}
      type="button"
      onClick={(event) => {
        onClick(event, ticker);
      }}
      style={{
        padding: "15px",
        backgroundColor: isClickedOn ? color : "lightgrey",
        color: isRevealed ? "white" : "black",
      }}
    >
      <div>
        <h2>{name}</h2>
        <h4>({ticker})</h4>
        {isRevealed ? <h4>${marketCap.toLocaleString()}</h4> : <></>}
      </div>
    </button>
  );
};

export default StockCard;
