import React from "react";

//7x5 squares
// add section to display answer
// symbol
interface GameDisplayProps {
  gameState: string[][];
}
const GameDisplay = ({ gameState }: GameDisplayProps) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "repeat(5, 1fr)",
        boxSizing: "border-box",
        rowGap: "5px",
      }}
    >
      {gameState.map((rowList, i) => (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(7, 1fr)",
            boxSizing: "border-box",
            columnGap: "5px",
          }}
        >
          {rowList.map((item) => (
            <div
              style={{
                border: "solid",
                width: "100%",
                padding: "5px",
              }}
            >
              {item}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default GameDisplay;
