import React, { useEffect, useState } from "react";
import Inputs from "./components/Inputs";
import GameDisplay from "./components/GameDisplay";
import { getDailyHilo, getDailyHiloKey } from "./dailyHilo";
import { getLocalStorageData } from "./utils";
import WinnerModal from "./components/WinnerModal";
import LoserModal from "./components/LoserModal";
import ResultDisplay from "./components/ResultDisplay";
import EqualsColumn from "./components/EqualsColumn";

const initGameState = () => {
  const list: string[][] = [[], [], [], [], []];
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < 7; j++) {
      list[i].push("_");
    }
  }
  return list;
};

const Hilo = () => {
  const [gameState, setGameState] = useState(initGameState());
  const [currentSquare, setCurrentSquare] = useState(0);
  const [currentRow, setCurrentRow] = useState(0);
  const [target, setTarget] = useState(-1);
  const [numbers, setNumbers] = useState<number[]>([]);
  const [resultCol, setResultCol] = useState<string[]>([
    "_",
    "_",
    "_",
    "_",
    "_",
  ]);
  const [isWinner, setWinner] = useState<boolean>(false);

  useEffect(() => {
    window.location.href = "https://oneadaygames.com/hilo";
    //init();
  }, []);

  useEffect(() => {
    const localHiloData = {
      gameState,
      currentSquare,
      currentRow,
      resultCol,
      isWinner,
    };
    localStorage.setItem(getDailyHiloKey(), JSON.stringify(localHiloData));
  }, [gameState, currentSquare, currentRow, resultCol, isWinner]);

  const init = () => {
    const localStorageData = getLocalStorageData();
    if (localStorageData) {
      setGameState(localStorageData.gameState);
      setCurrentSquare(localStorageData.currentSquare);
      setCurrentRow(localStorageData.currentRow);
      setResultCol(localStorageData.resultCol);
      setWinner(localStorageData.isWinner);
    }
    const dailyHilo = getDailyHilo();
    setTarget(dailyHilo.target);
    setNumbers(dailyHilo.startingNumbers);
  };

  const handleNumPress = (num: number) => {
    if (currentSquare < 7) {
      const updatedGameState = [...gameState];
      updatedGameState[currentRow][currentSquare] = num.toString();
      setGameState(updatedGameState);
      setCurrentSquare(currentSquare + 1);
    }
  };

  const handleOperationPress = (op: string) => {
    if (currentSquare < 7) {
      const updatedGameState = [...gameState];
      updatedGameState[currentRow][currentSquare] = op;
      setGameState(updatedGameState);
      if (currentSquare === 6) {
        setCurrentRow(currentRow + 1);
      } else {
        setCurrentSquare(currentSquare + 1);
      }
    }
  };
  const handleDelete = () => {
    if (currentSquare > 0) {
      const updatedGameState = [...gameState];
      updatedGameState[currentRow][currentSquare - 1] = "_";
      setGameState(updatedGameState);
      setCurrentSquare(currentSquare - 1);
    }
  };

  const handleSolve = () => {
    let answer = 0;
    for (let i = 0; i < gameState[currentRow].length; i += 2) {
      const num = parseInt(gameState[currentRow][i]);
      if (i === 0) {
        answer += num;
      } else if (gameState[currentRow][i - 1] === "-") {
        answer -= num;
      } else {
        answer += num;
      }
    }
    const resultColCopy = [...resultCol];
    if (answer < target) {
      resultColCopy[currentRow] = `${answer} - Too Lo`;
    } else if (answer > target) {
      resultColCopy[currentRow] = `${answer} - Too Hi`;
    } else {
      resultColCopy[currentRow] = `${answer} - Win!`;
      setWinner(true);
    }
    setResultCol(resultColCopy);
    setCurrentRow(currentRow + 1);
    setCurrentSquare(0);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "100vh",
      }}
    >
      <div>
        <LoserModal target={target} isShowing={!isWinner && currentRow === 5} />
        <WinnerModal isShowing={isWinner} />
        <h1>Hilo</h1>
        <div
          style={{
            display: "flex",
          }}
        >
          <GameDisplay gameState={gameState} />
          <EqualsColumn />
          <ResultDisplay resultCol={resultCol} />
        </div>

        <Inputs
          handleNumPress={handleNumPress}
          handleOperationPress={handleOperationPress}
          handleDelete={handleDelete}
          handleSolve={handleSolve}
          numbers={numbers}
          currentSquare={currentSquare}
          gameStateRow={gameState[currentRow]}
          isWinner={isWinner}
        />
      </div>
    </div>
  );
};

export default Hilo;
