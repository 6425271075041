import { Form, Modal, Button } from "react-bootstrap";
import React, { useState } from "react";

interface CategoryModalProps {
  generateNewWord: Function;
}

export const CategoryModal = ({ generateNewWord }: CategoryModalProps) => {
  const [category, setCategory] = useState("Animals");
  const [isShowing, setIsShowing] = useState(true);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    generateNewWord(category);
    setIsShowing(false);
  };
  return (
    <>
      <Modal show={isShowing}>
        <Modal.Header>
          <Modal.Title>Choose a Category:</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Check>
              <input
                type="radio"
                checked={category === "Animals"}
                value="Animals"
                onClick={() => {
                  setCategory("Animals");
                }}
              />{" "}
              Animals
            </Form.Check>
            <Form.Check>
              <input
                type="radio"
                checked={category === "Car Manufacturers"}
                value="Car Manufacturers"
                onClick={() => {
                  setCategory("Car Manufacturers");
                }}
              />{" "}
              Car Manufacturers
            </Form.Check>
            <Form.Check>
              <input
                type="radio"
                checked={category === "Pokemon"}
                value="Pokemon"
                onClick={() => {
                  setCategory("Pokemon");
                }}
              />{" "}
              Pokemon
            </Form.Check>
            <Form.Check>
              <input
                type="radio"
                checked={category === "Colors"}
                value="Colors"
                onClick={() => {
                  setCategory("Colors");
                }}
              />{" "}
              Colors
            </Form.Check>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">Submit</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
