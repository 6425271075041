import React from "react";

const Winner = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          textAlign: "center",
        }}
      >
        <h1>You win!</h1>
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/RZKiwexU_fU?autoplay=1`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  );
};

export default Winner;
