import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./App.css";
import { gen1Pokemon } from "./constants/pokemonNames";
import { Container } from "react-bootstrap";
import PokemonImage from "./components/PokemonImage";
import Streaks from "./components/Streaks";
import GameOverScreen from "./components/GameOverScreen";
import WinnerScreen from "./components/WinnerScreen";
import PokemonNavbar from "./components/PokemonNavbar";
import PokemonInput from "./components/PokemonInput";
import { Title } from "@mantine/core";
import { isNidoranException } from "./pokemonUtils";

function WhosThatPokemon() {
  const [pokemon, setPokemon] = useState<any>({});
  const [isHidden, setIsHidden] = useState(true);
  const [isNextPokemonButtonShowing, setIsNextPokemonButtonShowing] =
    useState(false);
  const [streakNumber, setStreakNumber] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);
  const [isWinner, setIsWinner] = useState(false);
  const [pokemonList, setPokemonList] = useState<string[]>([...gen1Pokemon]);
  const [isPokemonDancing, setIsPokemonDancing] = useState(false);
  const [guessText, setGuessText] = useState<string>("");

  useEffect(() => {
    getRandomPokemonData();
  }, []);

  const handleSubmit = (guessText: string) => {
    if (
      pokemon.name === guessText.toLowerCase() ||
      isNidoranException(pokemon.name, guessText)
    ) {
      setIsHidden(false);
      setStreakNumber(streakNumber + 1);
      setIsNextPokemonButtonShowing(true);
      setIsPokemonDancing(true);
      setTimeout(() => {
        handleNextPokemon();
        setGuessText("");
      }, 1500);
      if (pokemonList.length === 0) {
        setIsWinner(true);
      }
    }
    setGuessText(guessText);
  };
  const getRandomPokemonData = async () => {
    const randomIndex = Math.floor(pokemonList.length * Math.random());
    const chosenPokemon = pokemonList[randomIndex].toLowerCase();
    const updatedList = pokemonList.filter(
      (item) => pokemonList[randomIndex] !== item
    );
    setPokemonList(updatedList);
    const pokeAPIResponse = await Axios.get(
      `https://pokeapi.co/api/v2/pokemon/${chosenPokemon}`
    );
    const data = pokeAPIResponse.data;
    setPokemon(data);
  };

  const handlePlayAgain = () => {
    setIsHidden(true);
    setStreakNumber(0);
    setIsNextPokemonButtonShowing(false);
    getRandomPokemonData();
    setIsGameOver(false);
    setPokemonList([...gen1Pokemon]);
  };

  const handleNextPokemon = () => {
    setIsPokemonDancing(false);
    setIsHidden(true);
    getRandomPokemonData();
    setIsNextPokemonButtonShowing(false);
  };

  return (
    <>
      <PokemonNavbar />
      <Container className="text-center align-items-center justify-content-center">
        {isWinner ? (
          <WinnerScreen />
        ) : (
          <div>
            <img
              style={{
                width: "60%",
                maxWidth: "400px",
              }}
              src="/assets/portfolio/whos-that-pokemon.png"
              alt="logo"
            />
            {pokemon && (
              <PokemonImage
                isHidden={isHidden}
                pokemon={pokemon}
                isPokemonDancing={isPokemonDancing}
              />
            )}

            {isGameOver ? (
              <GameOverScreen
                pokemon={pokemon}
                streakNumber={streakNumber}
                handlePlayAgain={handlePlayAgain}
              />
            ) : (
              <>
                <Streaks streakNumber={streakNumber} />
                <PokemonInput
                  handleSubmit={handleSubmit}
                  isNextPokemonButtonShowing={isNextPokemonButtonShowing}
                  guessText={guessText}
                  setIsGameOver={setIsGameOver}
                />
              </>
            )}
          </div>
        )}
      </Container>
      <footer style={{ minHeight: "10vh", textAlign: "center" }}>
        <Title order={5}>Pokemon retrieved from pokeapi</Title>
      </footer>
    </>
  );
}

export default WhosThatPokemon;
