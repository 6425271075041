import React from "react";
import { Alert } from "react-bootstrap";

interface NotificationProps {
  notification: {
    message: string;
    variant: string;
  };
}
const Notification = ({ notification }: NotificationProps) => {
  if (notification.message === "") {
    return <></>;
  }
  return <Alert variant={notification.variant}>{notification.message}</Alert>;
};

export default Notification;
