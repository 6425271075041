import React from "react";

interface OperationButtonProps {
  handlePress: Function;
  handleDelete: Function;
}

const OperationButtons = ({
  handlePress,
  handleDelete,
}: OperationButtonProps) => {
  const operations = ["+", "-", "*", "/", "(", ")"];
  return (
    <div>
      {operations.map((operation, i) => (
        <button
          key={i}
          style={{
            padding: "5px",
            borderRadius: "5px",
            margin: "5px",
          }}
          onClick={() => handlePress(operation)}
        >
          {operation}
        </button>
      ))}
      <button
        style={{
          padding: "5px",
          borderRadius: "5px",
          margin: "5px",
        }}
        onClick={() => handleDelete()}
      >
        Delete
      </button>
    </div>
  );
};

export default OperationButtons;
