import { WikiObject } from "./wikiapi";

export const disneyMovieList: WikiObject[] = [
  {
    name: "A Goofy Movie",
    wikiPageTitle: "A_Goofy_Movie",
  },
  {
    name: "A Bug's Life",
    wikiPageTitle: "A_Bug%27s_Life",
  },
  {
    name: "Aladdin",
    wikiPageTitle: "Aladdin_(2019_film)",
  },
  {
    name: "Alice in Wonderland",
    wikiPageTitle: "Alice_in_Wonderland_(1951_film)",
  },
  {
    name: "Bambi",
    wikiPageTitle: "Bambi",
  },
  {
    name: "Beauty and the Beast",
    wikiPageTitle: "Beauty_and_the_Beast_(1991_film)",
  },
  {
    name: "Big hero 6",
    wikiPageTitle: "Big_Hero_6_(film)",
  },
  {
    name: "Bolt",
    wikiPageTitle: "Bolt_(2008_film)",
  },
  {
    name: "Brave",
    wikiPageTitle: "Brave_(2012_film)",
  },
  {
    name: "Brother Bear",
    wikiPageTitle: "Brother_Bear",
  },
  {
    name: "Cars",
    wikiPageTitle: "Cars_(film)",
  },
  {
    name: "Cars 2",
    wikiPageTitle: "Cars_2",
  },
  {
    name: "Cars 3",
    wikiPageTitle: "Cars_3",
  },
  {
    name: "Chicken Little",
    wikiPageTitle: "Chicken_Little_(2005_film)",
  },
  {
    name: "Coco",
    wikiPageTitle: "Coco_(2017_film)",
  },
  {
    name: "Dumbo",
    wikiPageTitle: "Dumbo",
  },
  {
    name: "Cinderella",
    wikiPageTitle: "Cinderella_(1950_film)",
  },
  {
    name: "Encanto",
    wikiPageTitle: "Encanto",
  },
  {
    name: "Finding Dory",
    wikiPageTitle: "Finding_Dory",
  },
  {
    name: "Finding Nemo",
    wikiPageTitle: "Finding_Nemo",
  },
  {
    name: "Frozen",
    wikiPageTitle: "Frozen_(2013_film)",
  },
  {
    name: "Frozen 2",
    wikiPageTitle: "Frozen_II",
  },
  {
    name: "Hercules",
    wikiPageTitle: "Hercules_(1997_film)",
  },
  {
    name: "Incredibles",
    wikiPageTitle: "The_Incredibles",
  },
  {
    name: "Incredibles 2",
    wikiPageTitle: "Incredibles_2",
  },
  {
    name: "Inside Out",
    wikiPageTitle: "Inside_Out_(2015_film)",
  },
  {
    name: "Lady and the Tramp",
    wikiPageTitle: "Lady_and_the_Tramp",
  },
  {
    name: "Lightyear",
    wikiPageTitle: "Lightyear_(film)",
  },
  {
    name: "Lilo and Stitch",
    wikiPageTitle: "Lilo_%26_Stitch",
  },
  {
    name: "Luca",
    wikiPageTitle: "Luca_(2021_film)",
  },
  {
    name: "Meet the Robinsons",
    wikiPageTitle: "Meet_the_Robinsons",
  },
  {
    name: "Moana",
    wikiPageTitle: "Moana_(2016_film)",
  },
  {
    name: "Monsters Inc",
    wikiPageTitle: "Monsters,_Inc.",
  },
  {
    name: "Monsters University",
    wikiPageTitle: "Monsters_University",
  },
  {
    name: "Mulan",
    wikiPageTitle: "Mulan_(1998_film)",
  },
  {
    name: "Oliver and Company",
    wikiPageTitle: "Oliver_%26_Company",
  },
  {
    name: "One Hundred and One Dalmatians",
    wikiPageTitle: "One_Hundred_and_One_Dalmatians",
  },
  {
    name: "Onward",
    wikiPageTitle: "Onward_(film)",
  },
  {
    name: "Peter Pan",
    wikiPageTitle: "Peter_Pan_(1953_film)",
  },
  {
    name: "Pinocchio",
    wikiPageTitle: "Pinocchio_(1940_film)",
  },
  {
    name: "Planes",
    wikiPageTitle: "Planes_(film)",
  },
  {
    name: "Pocahontas",
    wikiPageTitle: "Pocahontas_(1995_film)",
  },
  {
    name: "Ralph Breaks the Internet",
    wikiPageTitle: "Ralph_Breaks_the_Internet",
  },
  {
    name: "Ratatouille",
    wikiPageTitle: "Ratatouille_(film)",
  },
  {
    name: "Raya and the Last Dragon",
    wikiPageTitle: "Raya_and_the_Last_Dragon",
  },
  {
    name: "Robin Hood",
    wikiPageTitle: "Robin_Hood_(1973_film)",
  },
  {
    name: "Ron's Gone Wrong",
    wikiPageTitle: "Ron%27s_Gone_Wrong",
  },
  {
    name: "Sleeping Beauty",
    wikiPageTitle: "Sleeping_Beauty_(1959_film)",
  },
  {
    name: "Soul",
    wikiPageTitle: "Soul_(2020_film)",
  },
  {
    name: "Snow White and the Seven Dwarfs",
    wikiPageTitle: "Snow_White_and_the_Seven_Dwarfs_(1937_film)",
  },
  {
    name: "Tangled",
    wikiPageTitle: "Tangled",
  },
  {
    name: "Tarzan",
    wikiPageTitle: "Tarzan_(1999_film)",
  },
  {
    name: "The Princess and the Frog",
    wikiPageTitle: "The_Princess_and_the_Frog",
  },
  {
    name: "Treasure Planet",
    wikiPageTitle: "Treasure_Planet",
  },
  {
    name: "The Aristocats",
    wikiPageTitle: "The_Aristocats",
  },
  {
    name: "The Fox and the Hound",
    wikiPageTitle: "The_Fox_and_the_Hound",
  },

  {
    name: "The Brave Little Toaster",
    wikiPageTitle: "The_Brave_Little_Toaster",
  },
  {
    name: "The Emperor's New Groove",
    wikiPageTitle: "The_Emperor%27s_New_Groove",
  },
  {
    name: "The Good Dinosaur",
    wikiPageTitle: "The_Good_Dinosaur",
  },
  {
    name: "The Lion King",
    wikiPageTitle: "The_Lion_King",
  },
  {
    name: "The Little Mermaid",
    wikiPageTitle: "The_Little_Mermaid_(1989_film)",
  },
  {
    name: "The Jungle Book",
    wikiPageTitle: "The_Jungle_Book_(1967_film)",
  },
  {
    name: "The Hunchback of Notre Dame",
    wikiPageTitle: "The_Hunchback_of_Notre_Dame_(1996_film)",
  },
  {
    name: "The Rescuers",
    wikiPageTitle: "The_Rescuers",
  },
  {
    name: "The Tigger Movie",
    wikiPageTitle: "The_Tigger_Movie",
  },
  {
    name: "Toy Story",
    wikiPageTitle: "Toy_Story",
  },
  {
    name: "Toy Story 2",
    wikiPageTitle: "Toy_Story_2",
  },
  {
    name: "Toy Story 3",
    wikiPageTitle: "Toy_Story_3",
  },
  {
    name: "Toy Story 4",
    wikiPageTitle: "Toy_Story_4",
  },
  {
    name: "Turning Red",
    wikiPageTitle: "Turning_Red",
  },
  {
    name: "Who Framed Roger Rabbit",
    wikiPageTitle: "Who_Framed_Roger_Rabbit",
  },
  {
    name: "Up",
    wikiPageTitle: "Up_(2009_film)",
  },
  {
    name: "WALL E",
    wikiPageTitle: "WALL-E",
  },
  {
    name: "Wreck It Ralph",
    wikiPageTitle: "Wreck-It_Ralph",
  },
  {
    name: "Zootopia",
    wikiPageTitle: "Zootopia",
  },
];
