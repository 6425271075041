import { Container, Text, Title } from "@mantine/core";
import React from "react";

export const AboutMe = () => {
  return (
    <section
      id="About_Me"
      style={{
        backgroundColor: "black",
        color: "white",
        padding: "5em",
        margin: "2em 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <Title order={1} style={{ marginBottom: "1vh" }}>
          Hi! I'm Kyle.
        </Title>
        <Text fz="lg">
          I have a passion to build and solve problems through software
          development.
        </Text>
        <br />
        <Text fz="lg">
          I've worked for a wide array of companies to both gain experience and
          solve hard problems in their respective domains. I have taken this
          experience and in my own time I love working on web development{" "}
          <a href="#Projects">projects</a> that interest me.
        </Text>
        <br />
        <Text fz="lg">I reside in Vienna, VA where I work for AWS.</Text>
      </Container>
    </section>
  );
};
