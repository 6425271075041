import { Card, Center, Image } from "@mantine/core";
import React from "react";

export const ContactMe = () => {
  return (
    <footer
      id="Contact_Me"
      style={{
        padding: "30px 0",
        backgroundColor: "black",
        color: "white",
      }}
    >
      <div
        style={{
          textAlign: "center",
        }}
      >
        <h1>Contact Me</h1>
        <p style={{ fontSize: "1.33em" }}>
          If you would like to contact me, feel free to message me on LinkedIn!
        </p>
        <Center>
          <Card
            style={{
              width: 160,
              textAlign: "center",
            }}
            p="lg"
            component="a"
            href="https://www.linkedin.com/in/kenneyky/"
            radius="md"
            withBorder
          >
            <Card.Section>
              <Image
                width={160}
                alt="linkedin"
                src="/assets/portfolio/LI-In-Bug.png"
              />
            </Card.Section>
          </Card>
        </Center>
      </div>
    </footer>
  );
};
