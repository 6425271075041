import React from "react";
import { Col, Row } from "react-bootstrap";

interface PokemonInputProps {
  isNextPokemonButtonShowing: boolean;
  handleSubmit: Function;
  guessText: string;
  setIsGameOver : Function;
}

const PokemonInput = ({
  isNextPokemonButtonShowing,
  handleSubmit,
  guessText,
  setIsGameOver
}: PokemonInputProps) => {
  return (
    <Row>
      <Col>
        {isNextPokemonButtonShowing ? (
          <h1>Nice job!</h1>
        ) : (
          <>
            <input
              type="text"
              aria-label="pokemon-input"
              autoFocus
              placeholder="Enter Pokemon name"
              hidden={isNextPokemonButtonShowing}
              style={{ margin: "1em", borderRadius: "12px" }}
              value={guessText}
              onChange={(e) => {
                console.log("Calling handle submit!")
                handleSubmit(e.target.value)}}
            />
            <button onClick={() => {setIsGameOver(true)}}>Give up?</button>
          </>
        )}
      </Col>
    </Row>
  );
};

export default PokemonInput;
