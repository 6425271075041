import React from "react";
import { Modal } from "react-bootstrap";

interface LoserModalProps {
  isShowing: boolean;
  target: number;
}

const LoserModal = ({ isShowing, target }: LoserModalProps) => {
  return (
    <div>
      <Modal show={isShowing}>
        <Modal.Header>
          <Modal.Title>You Lost</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>The number was {target}</div>
        </Modal.Body>
        <Modal.Footer>{/*TODO:Add ok button */}</Modal.Footer>
      </Modal>
    </div>
  );
};

export default LoserModal;
