import React, { useState } from "react";
import HangmanBody from "./components/HangmanBody";
import CurrentWord from "./components/CurrentWord";
import Notification from "./components/Notification";
import { Button } from "react-bootstrap";
import { animalWords as importedAnimalWords } from "./constants/animalwords";
import { carManufacturers as importedCarManuFacturers } from "./constants/car_manufacturers";
import { pokemonNames as importedPokemonNames } from "./constants/pokemonNames";
import { colors as importedColors } from "./constants/colors";
import { Letters } from "./components/Letters";
import { CategoryModal } from "./components/CategoryModal";

export interface ButtonState {
  color?: string;
  isDisabled?: boolean;
}
const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

const generateInitialButtonState = () => {
  const alphabetMap = new Map<string, ButtonState>();
  for (let i = 0; i < alphabet.length; i++) {
    alphabetMap.set(alphabet[i], {
      color: "light",
      isDisabled: false,
    });
  }
  return alphabetMap;
};

function Hangman() {
  const [lettersGuessed, setLettersGuessed] = useState<Array<string>>([]);
  const [wordToGuess, setWordToGuess] = useState("");
  const [gameOver, setGameOver] = useState(false);
  const [notification, setNotification] = useState({
    message: "",
    variant: "",
  });
  const [buttonsStateMap, setButtonsStateMap] = useState(
    generateInitialButtonState()
  );
  const [category, setCategory] = useState("");
  let animalWords = importedAnimalWords;
  let carManufacturers = importedCarManuFacturers;
  let pokemonNames = importedPokemonNames;
  let colors = importedColors;

  const generateNewWord = (category: string) => {
    let random = 0;
    let word = "";
    if (category === "Animals") {
      random = Math.floor(Math.random() * animalWords.length);
      word = animalWords[random];
      animalWords.splice(random, 1);
    } else if (category === "Car Manufacturers") {
      random = Math.floor(Math.random() * carManufacturers.length);
      word = carManufacturers[random];
      carManufacturers.splice(random, 1);
    } else if (category === "Pokemon") {
      random = Math.floor(Math.random() * pokemonNames.length);
      word = pokemonNames[random];
      pokemonNames.splice(random, 1);
    } else {
      random = Math.floor(Math.random() * colors.length);
      word = colors[random];
      colors.splice(random, 1);
    }
    setCategory(category);
    setWordToGuess(word.toUpperCase());
  };

  const isWinner = (lettersGuessedAppended: string[]): boolean => {
    for (let i = 0; i < wordToGuess.length; i++) {
      if (!lettersGuessedAppended.includes(wordToGuess[i])) {
        return false;
      }
    }
    return true;
  };

  const isLoser = (lettersGuessedAppended: string[]): boolean => {
    let wrongLettersGuessed = 0;
    for (let i = 0; i < lettersGuessedAppended.length; i++) {
      if (!wordToGuess.includes(lettersGuessedAppended[i])) {
        wrongLettersGuessed++;
      }
    }
    return wrongLettersGuessed >= 6;
  };

  const handleLetterGuessed = (letterGuessed: string) => {
    const lettersGuessedAppended = lettersGuessed.concat(letterGuessed);
    setLettersGuessed(lettersGuessedAppended);
    const button: ButtonState = {
      ...buttonsStateMap.get(letterGuessed),
    };
    button.isDisabled = true;
    if (wordToGuess.includes(letterGuessed)) {
      button.color = "success";
    } else {
      button.color = "danger";
    }
    const updatedMap = new Map(buttonsStateMap);
    updatedMap.set(letterGuessed, button);
    setButtonsStateMap(updatedMap);
    if (isWinner(lettersGuessedAppended)) {
      handleWinner();
    } else if (isLoser(lettersGuessedAppended)) {
      handleLoser();
    }
  };

  const handleWinner = () => {
    setGameOver(true);
    disableButtons();
    setNotification({
      message: "You Win!",
      variant: "success",
    });
  };

  const handleLoser = () => {
    setGameOver(true);
    disableButtons();
    setNotification({
      message: `You Lost, the word was ${wordToGuess}`,
      variant: "danger",
    });
  };

  const disableButtons = () => {
    const updatedButtonsStateMap = new Map(buttonsStateMap);
    for (let i = 0; i < alphabet.length; i++) {
      updatedButtonsStateMap.set(alphabet[i], {
        ...buttonsStateMap.get(alphabet[i]),
        isDisabled: true,
      });
    }
    setButtonsStateMap(updatedButtonsStateMap);
  };

  const reset = () => {
    generateNewWord(category);
    setLettersGuessed([]);
    setButtonsStateMap(generateInitialButtonState());
    setGameOver(false);
    setNotification({
      message: "",
      variant: "",
    });
  };

  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <CategoryModal generateNewWord={generateNewWord} />
      <header>
        <h1>Hangman</h1>
      </header>
      <main>
        <Notification notification={notification} />
        <HangmanBody
          lettersGuessed={lettersGuessed}
          currentWord={wordToGuess}
        />
        <CurrentWord
          currentWord={wordToGuess}
          category={category}
          guessedLetters={lettersGuessed}
        />
        {gameOver && (
          <Button onClick={reset} className="mt-4">
            Play Again
          </Button>
        )}
        <h3>Category: {category} </h3>
        <Letters
          buttonStateMap={buttonsStateMap}
          handleLetterPressed={handleLetterGuessed}
        />
      </main>
    </div>
  );
}

export default Hangman;
