import { Button, TextInput } from "@mantine/core";
import React from "react";

interface ActlInputProps {
  handleSubmit: Function;
  setGuessedWordsInput: Function;
  guessedWordsInput: string;
}

const Inputs = ({
  handleSubmit,
  guessedWordsInput,
  setGuessedWordsInput,
}: ActlInputProps) => {
  return (
    <div
      style={{
        display: "flex",
        paddingTop: "10px",
      }}
    >
      <TextInput
        style={{
          paddingRight: "10px",
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSubmit();
          }
        }}
        value={guessedWordsInput}
        onChange={(e) => setGuessedWordsInput(e.currentTarget.value)}
      />
      <Button onClick={() => handleSubmit()}>Submit</Button>
    </div>
  );
};

export default Inputs;
