import React from "react";
import { usePokemonMemory } from "../context/PokemonMemoryContext";

const GameOverComponent = () => {
  const { updateGameState, gameState } = usePokemonMemory();
  const currentDifficulty = gameState.difficulty;
  let bestTime = gameState.easyBestTime;
  if (currentDifficulty === "Medium") {
    bestTime = gameState.mediumBestTime;
  } else if (currentDifficulty === "Hard") {
    bestTime = gameState.hardBestTime;
  }

  return (
    <div>
      <h1>Nice job!</h1>
      <div>Your time was {gameState.currentTime} seconds.</div>
      <div>
        Your best time for {gameState.difficulty} difficulty is {bestTime}{" "}
        seconds.
      </div>
      <button
        onClick={() => {
          updateGameState({
            ...gameState,
            difficulty: "",
            isGameOver: false,
            currentTime: 0,
          });
        }}
      >
        Play Again
      </button>
    </div>
  );
};

export default GameOverComponent;
