import React from "react";

interface RecentlyGuessedWordProps {
  recentlyGuessedWord: string;
  word: string;
}

const RecentlyGuessedWord = ({ recentlyGuessedWord, word } : RecentlyGuessedWordProps) => {
  return (
    <span
      style={{
        backgroundColor:
          recentlyGuessedWord.toLowerCase() !== word ? "white" : "green",

        color: recentlyGuessedWord.toLowerCase() !== word ? "black" : "white",
        fontSize: "18px",
      }}
    >
      {word}
    </span>
  );
};

export default RecentlyGuessedWord;
