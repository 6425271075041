import Axios from "axios";
import { disneyMovieList } from "./disneyMovies";
import { marvelMovieList } from "./marvelMovies";
import { starWarsMovies } from "./starwarsMovies";

export interface WikiData {
  name: string;
  plotText: string[];
  pickedIndex: number;
  updatedArr: WikiObject[];
}

export const Plots = {
  Marvel: "marvel",
  Disney: "disney",
  Musicals: "musicals",
};

export const PlotLists = {
  Marvel: [...marvelMovieList],
  Disney: [...disneyMovieList, ...marvelMovieList, ...starWarsMovies],
};

export const getPlotList = () => {
    return PlotLists.Disney;

};

export const getWikipediaPlotData = async (): Promise<WikiData> => {
  const plotList = getPlotList();
  const randomIndex = Math.floor(Math.random() * plotList.length);
  const result = await Axios.get(
    `https://en.wikipedia.org/w/api.php?action=parse&page=${
      plotList[randomIndex].wikiPageTitle
    }&section=${
      plotList[randomIndex].section ? plotList[randomIndex].section : 1
    }&format=json&disabletoc=1&origin=*`
  );
  const plotText: string = result.data?.parse?.text["*"];
  const parsedStr = new DOMParser().parseFromString(plotText, "text/html").body
    .textContent;
  const copiedList = [...plotList];
  copiedList.splice(randomIndex, 1);

  return {
    name: plotList[randomIndex].name,
    plotText: getStringArr(parsedStr),
    pickedIndex: randomIndex,
    updatedArr: copiedList,
  };
};

const getStringArr = (plotText: string | null): string[] => {
  if (plotText === null) {
    return [];
  }
  plotText = plotText.replace(/(\r\n|\n|\r|\f)/gm, " ");

  return plotText.split(/\b/);
};

export interface WikiObject {
  name: string;
  wikiPageTitle: string;
  imageUrl?: string;
  section?: number;
}
