import axios from "axios";
import React, { useEffect, useState } from "react";
import LoserComponent from "./components/LoserComponent";
import VersusBox from "./components/VersusBox";
import { wellKnownConstituents } from "./easyConstants";

interface Company {
  Name: string;
  Sector: string;
  Symbol: string;
}

export interface VSCompany {
  Name: string;
  Symbol: string;
  MarketCap: string;
}

const token = "pk_613b9396ed4b4411bf0573f462158555";

const MarketCapComparer = () => {
  const [streak, setStreak] = useState(0);
  const [allCompanies, setAllCompanies] = useState([...wellKnownConstituents]);
  const [vsCompanies, setVSCompanies] = useState<any>([]);
  const [isPlaying, setPlaying] = useState(true);
  const [userClickedOnTicker, setUserClickedOnTicker] = useState<string>("");

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    ticker: string
  ) => {
    setUserClickedOnTicker(ticker);
  };

  const pick2RandomCompanies = (companies: Company[]): Company[] => {
    const updatedCompanies = [...companies];
    const randomCompanies: Company[] = [];
    for (let i = 0; i < 2; i++) {
      const randNum = Math.floor(Math.random() * companies.length);
      randomCompanies.push(updatedCompanies[randNum]);
      updatedCompanies.splice(randNum, 1);
    }
    setAllCompanies(updatedCompanies);
    return randomCompanies;
  };

  const getMarketData = () => {
    const twoRandomCompanies = pick2RandomCompanies(allCompanies);
    const stockSymbol1 = twoRandomCompanies[0].Symbol.toLowerCase();
    const stockSymbol2 = twoRandomCompanies[1].Symbol.toLowerCase();
    const url = `https://cloud.iexapis.com/stable/stock/market/batch?symbols=${stockSymbol1},${stockSymbol2}&types=stats&token=${token}`;
    axios.get(url).then((response) => {
      const retrievedData: VSCompany[] = [];
      const dataKeys = Object.keys(response.data);
      for (let i = 0; i < dataKeys.length; i++) {
        retrievedData.push({
          Name: response.data[dataKeys[i]]["stats"]["companyName"],
          Symbol: dataKeys[i],
          MarketCap: response.data[dataKeys[i]]["stats"]["marketcap"],
        });
      }
      setVSCompanies(retrievedData);
    });
  };

  useEffect(() => {
    getMarketData();
  }, []);

  const onNextTwoCompaniesClick = () => {
    setUserClickedOnTicker("");
    getMarketData();
    setStreak(streak + 1);
  };

  const pickedRightCompany =
    vsCompanies.length === 2 &&
    ((parseInt(vsCompanies[0].MarketCap) > parseInt(vsCompanies[1].MarketCap) &&
      vsCompanies[0].Symbol === userClickedOnTicker) ||
      (parseInt(vsCompanies[1].MarketCap) >
        parseInt(vsCompanies[0].MarketCap) &&
        vsCompanies[1].Symbol === userClickedOnTicker));

  if (userClickedOnTicker && !pickedRightCompany) {
    return (
      <LoserComponent
        streak={streak}
        vsCompanies={vsCompanies}
        handlePlayAgain={() => {
          setStreak(0);
          setUserClickedOnTicker("");
          getMarketData();
        }}
      />
    );
  }

  return (
    <div
      style={{
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <VersusBox
        vsCompanies={vsCompanies}
        setPlaying={setPlaying}
        onNextTwoCompaniesClick={onNextTwoCompaniesClick}
        userClickedOnTicker={userClickedOnTicker}
        handleClick={handleClick}
      />
    </div>
  );
};

export default MarketCapComparer;
