import { Card } from "react-bootstrap";
import React from "react";
import { LetterButton } from "./LetterButton";
import { ButtonState } from "../Hangman";

interface LettersProps {
  handleLetterPressed: Function;
  buttonStateMap: Map<string, ButtonState>;
}

export const Letters = ({
  handleLetterPressed,
  buttonStateMap,
}: LettersProps) => {
  const keysArr = Array.from(buttonStateMap.keys());

  return (
    <Card style={{ textAlign: "center" }}>
      <Card.Body>
        {keysArr.map((letter) => (
          <LetterButton
            handleLetterPressed={handleLetterPressed}
            letter={letter}
            color={buttonStateMap?.get(letter)?.color}
            isDisabled={buttonStateMap?.get(letter)?.isDisabled}
          />
        ))}
      </Card.Body>
    </Card>
  );
};
