import React from "react";
import PokemonDetails from "./PokemonDetails";

interface PokemonCardButtonProps {
  isRevealed: boolean;
  imageUrl: string;
  name: string;
  onClickHandler: Function;
  index: number;
  isRevealingTwoCards: boolean;
  isMatched: boolean;
}

const PokemonCardButton = ({
  isRevealed,
  imageUrl,
  name,
  onClickHandler,
  index,
  isRevealingTwoCards,
  isMatched,
}: PokemonCardButtonProps) => {
  return (
    <button
      style={{
        backgroundColor: isMatched ? "#3CB371" : "",
        color: isMatched ? "black" : "",
      }}
      onClick={() => {
        if (!isRevealingTwoCards) {
          onClickHandler(index);
        }
      }}
      disabled={isRevealed}
    >
      <PokemonDetails name={name} imageUrl={imageUrl} isRevealed={isRevealed} />
    </button>
  );
};

export default PokemonCardButton;
