import React from "react";
import { usePokemonMemory } from "../context/PokemonMemoryContext";

const DifficultyMenu = () => {
  const { gameState, updateGameState } = usePokemonMemory();
  if (gameState.difficulty !== "") {
    return <></>;
  }

  const difficulties = ["Easy", "Medium", "Hard"];

  return (
    <div
      style={{
        display: "grid",
        height: "30vh",
        width: "30vh",
        margin: "0 auto",
        rowGap: "1vh",
        gridTemplateColumns: "1fr",
      }}
    >
      {difficulties.map((difficulty) => (
        <button
          onClick={() => {
            updateGameState({ ...gameState, difficulty: difficulty });
          }}
        >
          {difficulty}
        </button>
      ))}
    </div>
  );
};

export default DifficultyMenu;
