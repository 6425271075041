import { solvableNumbers } from "./solvableNumbers";

interface StartingNumbers {
  targetNumber: number;
  sixNumbers: number[];
}

export const getGameStartingNumbers = (): StartingNumbers => {
  return {
    targetNumber: getRandNum(100, 1000),
    sixNumbers: generateSixRandomNumbers(),
  };
};

export const parseEvaluation = (str: string): number => {
  try {
    const answer = eval(str);
    if (answer) {
      return answer;
    }
    return 0;
  } catch (error) {
    return 0;
  }
};

export const isInteger = (value: string) => {
  return /^\d+$/.test(value);
};

const getRandNum = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min) + min);
};

const generateSixRandomNumbers = (): number[] => {
  const randNum = getRandNum(0, solvableNumbers.length - 1);
  return solvableNumbers[randNum];
};

export const updatedCurrentAnswer = (
  currentAnswer: number,
  operand: string,
  num: number
): number => {
  if (operand === "+") {
    return currentAnswer + num;
  } else if (operand === "-") {
    return currentAnswer - num;
  } else if (operand === "*") {
    return currentAnswer * num;
  } else {
    return currentAnswer / num;
  }
};
