import React from "react";
import { Col, Row } from "react-bootstrap";
import { Button, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";

interface GameOverScreenProps {
  streakNumber: number;
  handlePlayAgain: Function;
  pokemon: any;
}
const GameOverScreen = ({
  streakNumber,
  handlePlayAgain,
  pokemon,
}: GameOverScreenProps) => {
  const navigate = useNavigate();
  const handleOnClick = (e: any) => {
    e.preventDefault();
    handlePlayAgain();
  };

  return (
    <Row>
      <Col>
        <Title order={1}>Game Over!</Title>
        <Title order={3} color="dimmed">
          {" "}
          The pokemon was {pokemon.name}
        </Title>
        <Title order={3} color="dimmed">
          Pokemon guessed correctly: {streakNumber}
        </Title>
        <Title order={3}>Play again?</Title>
        <Button onClick={handleOnClick}>Play Again</Button>
        <Title order={3}>or</Title>
        <Button color="gray" onClick={() => navigate("/")}>
          Navigate back to projects
        </Button>
      </Col>
    </Row>
  );
};

export default GameOverScreen;
