import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

const PokemonNavbar = () => {
  const baseUrl = "https://kylekenney.net";
  return (
    <Navbar className="flex-md-nowrap" bg="danger" variant="dark">
      <Container>
        <Navbar.Brand className="ml-3" href={`${baseUrl}/#Home`}>
          Back To Home
        </Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link href={`${baseUrl}/#Projects`}>Other Projects</Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default PokemonNavbar;
