import React from "react";

const Head = () => {
  return (
    <circle
      cx="200"
      cy="150"
      r="30"
      stroke="black"
      fill="transparent"
      stroke-width="5"
    />
  );
};

const Body = () => {
  return (
    <line x1="200" x2="200" y1="180" y2="300" stroke="black" stroke-width="5" />
  );
};

const RightArm = () => {
  return (
    <line x1="200" x2="275" y1="220" y2="150" stroke="black" stroke-width="5" />
  );
};

const LeftArm = () => {
  return (
    <line x1="125" x2="200" y1="150" y2="220" stroke="black" stroke-width="5" />
  );
};

const RightLeg = () => {
  return (
    <line x1="200" x2="275" y1="300" y2="375" stroke="black" stroke-width="5" />
  );
};

const LeftLeg = () => {
  return (
    <line x1="125" x2="200" y1="375" y2="300" stroke="black" stroke-width="5" />
  );
};

const Base = () => {
  return (
    <>
      <line
        x1="300"
        x2="300"
        y1="80"
        y2="385"
        stroke="black"
        stroke-width="5"
      />
      <line x1="200" x2="300" y1="80" y2="80" stroke="black" stroke-width="5" />
      <line
        x1="200"
        x2="200"
        y1="80"
        y2="120"
        stroke="black"
        stroke-width="5"
      />
    </>
  );
};

interface HangmanBodyProps {
  lettersGuessed: string[];
  currentWord: string;
}
const HangmanBody = ({ lettersGuessed, currentWord }: HangmanBodyProps) => {
  let incorrectLettersGuessedLength = 0;
  const calculateIncorrectLettersGuessed = () => {
    lettersGuessed.forEach((letter) => {
      if (!currentWord.includes(letter)) {
        incorrectLettersGuessedLength++;
      }
    });
  };
  calculateIncorrectLettersGuessed();

  return (
    <div>
      <svg width="250px" height="250px" viewBox="0 60 400 400">
        <Base />
        {incorrectLettersGuessedLength >= 1 && <Head />}
        {incorrectLettersGuessedLength >= 2 && <Body />}
        {incorrectLettersGuessedLength >= 3 && <RightArm />}
        {incorrectLettersGuessedLength >= 4 && <LeftArm />}
        {incorrectLettersGuessedLength >= 5 && <RightLeg />}
        {incorrectLettersGuessedLength >= 6 && <LeftLeg />}
      </svg>
    </div>
  );
};

export default HangmanBody;
