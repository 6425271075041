import { Card, Container, SimpleGrid, Text, Title } from "@mantine/core";
import React from "react";
import {
  bigProjectsList,
} from "./constants";
import ProjectCard from "./ProjectCard";

export const Projects = () => {
  const bigProjects = [...bigProjectsList];
  return (
    <section id="Projects" style={{ paddingBottom: "5vh" }}>
      <Container>
        <Title
          order={1}
          style={{
            marginBottom: "30px",
          }}
        >
          Projects
        </Title>
        <Title order={2} style={{ marginBottom: "30px" }}>
          Big Projects
        </Title>
        <SimpleGrid
          cols={3}
          spacing="md"
          style={{ marginBottom: "30px", textAlign: "center" }}
        >
          {bigProjects.map((project, i) => (
            <ProjectCard project={project} />
          ))}
        </SimpleGrid>
        <Title order={2} style={{ marginBottom: "30px" }}>
          Experiments
        </Title>
        <SimpleGrid
          cols={3}
          spacing="md"
          style={{ marginBottom: "30px", textAlign: "center" }}
        >
        <ProjectCard project={{
          header: "Experiments",
          description: "Click here to see some mini projects that I worked on.",
          href: "/experiments",
          imageSrc: "/assets/portfolio/beaker.svg"
        }} />
        </SimpleGrid>
        
        
      </Container>
    </section>
  );
};
