import React from "react";

const operations = ["+", "-"];

interface InputsProps {
  numbers: number[];
  handleNumPress: Function;
  handleOperationPress: Function;
  handleDelete: Function;
  handleSolve: Function;
  currentSquare: number;
  gameStateRow: string[];
  isWinner: boolean;
}

const hasPickedNum = (gameStateRow: string[] = [], num: number) => {
  for (let i = 0; i < gameStateRow.length; i++) {
    try {
      const parsedNum = parseInt(gameStateRow[i]);
      if (parsedNum === num) {
        return true;
      }
    } catch (e) {
      //ok
    }
  }
  return false;
};

const Inputs = ({
  handleNumPress,
  handleOperationPress,
  handleDelete,
  handleSolve,
  numbers,
  currentSquare,
  gameStateRow,
  isWinner,
}: InputsProps) => {
  return (
    <div>
      <div>
        {numbers.map((num) => (
          <button
            style={{
              margin: "5px",
            }}
            disabled={
              currentSquare % 2 !== 0 ||
              hasPickedNum(gameStateRow, num) ||
              isWinner
            }
            onClick={() => handleNumPress(num)}
          >
            {num}
          </button>
        ))}
      </div>
      <div>
        {operations.map((op) => (
          <button
            style={{
              margin: "5px",
            }}
            disabled={currentSquare % 2 === 0 || isWinner}
            onClick={() => handleOperationPress(op)}
          >
            {op}
          </button>
        ))}
      </div>
      <div>
        <button
          disabled={currentSquare !== 7 || isWinner}
          onClick={() => handleSolve()}
        >
          Solve
        </button>
      </div>
      <div>
        <button
          disabled={currentSquare === 0 || isWinner}
          onClick={() => handleDelete()}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default Inputs;
