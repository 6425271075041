import React from "react";

interface NewGameButtonProps {
  handleNewGameClick: Function;
}

const NewGameButton = ({ handleNewGameClick }: NewGameButtonProps) => {
  return (
    <button
      style={{
        padding: "5px",
        borderRadius: "5px",
        margin: "5px",
      }}
      onClick={() => handleNewGameClick()}
    >
      New Game
    </button>
  );
};

export default NewGameButton;
