import Axios from "axios";
import React, { useEffect, useState } from "react";
import { gen1Pokemon, Pokemon } from "../constants";
import { usePokemonMemory } from "../context/PokemonMemoryContext";
import PokemonCardButton from "./PokemonCardButton";

interface AllCardContainerProps {
  difficulty: string;
  currentSeconds: number;
  setCurrentSeconds: Function;
}

const AllCardContainer = ({
  difficulty,
  setCurrentSeconds,
  currentSeconds,
}: AllCardContainerProps) => {
  const [pokemonMemoryGameState, setPokemonMemoryGameState] = useState<
    Pokemon[]
  >([]);
  const [firstChoice, setFirstChoice] = useState<Pokemon | null>(null);
  const [isRevealingTwoCards, setIsRevealingTwoCards] =
    useState<boolean>(false);
  const [isCurrentlyPlaying, setIsCurrentlyPlaying] = useState<boolean>(false);
  const { gameState, updateGameState } = usePokemonMemory();

  let rows = 5;
  let cols = 4;

  if (difficulty === "Medium") {
    rows = 6;
    cols = 5;
  } else if (difficulty === "Hard") {
    rows = 7;
    cols = 6;
  }

  if (isCurrentlyPlaying) {
    setTimeout(() => {
      setCurrentSeconds(currentSeconds + 1);
    }, 1000);
  }

  const isGameOver = () => {
    for (let i = 0; i < pokemonMemoryGameState.length; i++) {
      if (!pokemonMemoryGameState[i].isMatched) {
        return false;
      }
    }
    return true;
  };

  //officially game over
  if (isCurrentlyPlaying && isGameOver()) {
    setIsCurrentlyPlaying(false);

    if (difficulty === "Easy") {
      updateGameState({
        ...gameState,
        isGameOver: true,
        easyBestTime:
          currentSeconds < gameState.easyBestTime
            ? currentSeconds
            : gameState.easyBestTime,
        currentTime: currentSeconds,
      });
    } else if (difficulty === "Medium") {
      updateGameState({
        ...gameState,
        isGameOver: true,
        mediumBestTime:
          currentSeconds < gameState.mediumBestTime
            ? currentSeconds
            : gameState.mediumBestTime,
        currentTime: currentSeconds,
      });
    } else {
      updateGameState({
        ...gameState,
        isGameOver: true,
        hardBestTime:
          currentSeconds < gameState.hardBestTime
            ? currentSeconds
            : gameState.hardBestTime,
        currentTime: currentSeconds,
      });
    }
    setCurrentSeconds(0);
  }

  useEffect(() => {
    const generateRandomPokemon = async () => {
      const totalSize = (rows * cols) / 2;
      const pokemonList: Pokemon[] = [];
      const gen1PokemonListCopy = [...gen1Pokemon];
      for (let i = 0; i < totalSize; i++) {
        const randomIndex = Math.floor(
          Math.random() * gen1PokemonListCopy.length
        );
        const pokemonName = gen1PokemonListCopy[randomIndex];
        const pokemonData = await Axios.get(
          `https://pokeapi.co/api/v2/pokemon/${pokemonName.toLowerCase()}`
        );
        const pokemon = {
          name: pokemonName,
          imageUrl: pokemonData.data?.sprites?.front_default,
          isRevealed: true,
          isMatched: false,
        };
        pokemonList.push({ ...pokemon }, { ...pokemon });
        gen1PokemonListCopy.splice(randomIndex, 1);
      }
      const shuffledPokemonList = shuffle(pokemonList);
      setPokemonMemoryGameState(shuffledPokemonList);
      setTimeout(() => {
        const copyShuffledPokemonList = [...shuffledPokemonList];
        for (let i = 0; i < copyShuffledPokemonList.length; i++) {
          copyShuffledPokemonList[i].isRevealed = false;
        }

        setPokemonMemoryGameState(copyShuffledPokemonList);
      }, 5000);
    };

    const shuffle = (list: any[]) => {
      const shuffledPokemon = [];
      while (list.length !== 0) {
        const randomIndex = Math.floor(Math.random() * list.length);
        shuffledPokemon.push({ ...list[randomIndex] });
        list.splice(randomIndex, 1);
      }
      return shuffledPokemon;
    };
    generateRandomPokemon();
  }, []);

  const revealCard = (index: number) => {
    const currentChoice = { ...pokemonMemoryGameState[index], index: index };
    const copyGameState = [...pokemonMemoryGameState];
    setFirstChoice(currentChoice);
    copyGameState[index] = {
      ...copyGameState[index],
      isRevealed: true,
      index: index,
    };
    setPokemonMemoryGameState(copyGameState);
  };

  const onClick = (index: number) => {
    if (!isCurrentlyPlaying) {
      setIsCurrentlyPlaying(true);
    }
    const copyCurrentChoice = {
      ...pokemonMemoryGameState[index],
      isMatched: true,
      isRevealed: true,
    };
    if (firstChoice === null) {
      revealCard(index);
    } else {
      if (firstChoice.name === pokemonMemoryGameState[index].name) {
        //match!

        const copyFirstChoice = {
          ...firstChoice,
          isMatched: true,
          isRevealed: true,
        };
        const copyGameState = [...pokemonMemoryGameState];
        copyGameState[index] = copyCurrentChoice;
        const firstChoiceIndex = copyFirstChoice?.index
          ? copyFirstChoice.index
          : 0;
        copyGameState[firstChoiceIndex] = copyFirstChoice;
        setPokemonMemoryGameState(copyGameState);
      } else {
        //not a match
        revealCard(index);
        setIsRevealingTwoCards(true);
        //reveal the card the user picked

        //reset the first one back to is disabled to false
        setTimeout(() => {
          setIsRevealingTwoCards(false);
          copyCurrentChoice.isRevealed = false;
          copyCurrentChoice.isMatched = false;
          const copyFirstChoice = {
            ...firstChoice,
            isRevealed: false,
          };
          const copyGameState = [...pokemonMemoryGameState];
          copyGameState[index] = copyCurrentChoice;
          const firstChoiceIndex = copyFirstChoice?.index
            ? copyFirstChoice.index
            : 0;
          copyGameState[firstChoiceIndex] = copyFirstChoice;
          setPokemonMemoryGameState(copyGameState);
        }, 600);
      }
      setFirstChoice(null);
    }
  };

  return (
    <>
      <div
        style={{
          textAlign: "center",
          height: "90vh",
          width: "90vh",
          display: "grid",
          gridTemplateColumns: `repeat(${rows}, 1fr)`,
          gridTemplateRows: `repeat(${cols}, 1fr)`,
        }}
      >
        {pokemonMemoryGameState.map((pokemon, index) => (
          <PokemonCardButton
            key={index}
            isMatched={pokemon.isMatched}
            isRevealingTwoCards={isRevealingTwoCards}
            index={index}
            isRevealed={pokemon.isRevealed}
            imageUrl={pokemon.imageUrl}
            name={pokemon.name}
            onClickHandler={onClick}
          />
        ))}
      </div>
    </>
  );
};

export default AllCardContainer;
