import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { Portfolio } from "./projects/portfolio/Portfolio";
import Hangman from "./projects/hangman/Hangman";
import WhosThatPokemon from "./projects/whos-that-pokemon/WhosThatPokemon";
import MarketCapComparer from "./projects/market-cap-comparer/market-cap-comparer";
import PokemonMemoryGame from "./projects/pokemon-memory-game/PokemonMemoryGame";
import { NotificationsProvider } from "@mantine/notifications";
import Plotle from "./projects/plotle/Plotle";

import { configureAnalytics } from "./configure-analytics";
import Countdown from "./projects/countdown/Countdown";
import FiftyStates from "./projects/fifty-states/FiftyStates";
import Hilo from "./projects/hilo/Hilo";
import Experiments from "./projects/portfolio/Experiments";


configureAnalytics(window.location.hostname);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <MantineProvider withGlobalStyles withNormalizeCSS>
    <NotificationsProvider position="top-center">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Portfolio />} />
          <Route path="/hangman" element={<Hangman />} />
          <Route path="/whos-that-pokemon" element={<WhosThatPokemon />} />
          <Route path="/market-cap-comparer" element={<MarketCapComparer />} />
          <Route path="/pokemon-memory-game" element={<PokemonMemoryGame />} />
          <Route path="/plotle" element={<Plotle />} />
          <Route path="/countdown" element={<Countdown />} />
          <Route path="/50states" element={<FiftyStates />} />
          <Route path="/hilo" element={<Hilo />} />
          <Route path="/experiments" element={<Experiments />} />
        </Routes>
      </BrowserRouter>
    </NotificationsProvider>
  </MantineProvider>
);
