import React from "react";

interface StreaksProps {
  streakNumber: number;
}
const Streaks = ({ streakNumber }: StreaksProps) => {
  return (
    <div>
      <h1 data-testid="currentStreakDisplay">{streakNumber}/151</h1>
    </div>
  );
};

export default Streaks;
