import React from "react";
import Winner from "./Winner";

interface MainGameProps {
  bestStreak: number;
  imgSrc: string;
  streak: number;
  isGameOver: boolean;
  stateInput: string;
  textChange: Function;
  giveUpHandler: Function;
}

const MainGame = ({
  bestStreak,
  imgSrc,
  streak,
  isGameOver,
  stateInput,
  textChange,
  giveUpHandler,
}: MainGameProps) => {
  if (streak === 50) {
    return (
      <Winner />
    );
  }
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <h1>Fifty States</h1>
      <h2>Best Streak : {bestStreak}</h2>
      <div>
        <img
          style={{
            height: "auto",
            width: "25%",
          }}
          src={`${imgSrc}`}
          alt="state here"
        />
      </div>
      <div>{streak}/50</div>
      {!isGameOver && (
        <>
          <input
            autoFocus
            value={stateInput}
            onChange={(e) => textChange(e.target.value)}
          />
          <div
            style={{
              margin: "1em",
            }}
          >
            <button onClick={() => giveUpHandler()}>Give up?</button>
          </div>
        </>
      )}
    </div>
  );
};

export default MainGame;
