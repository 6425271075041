import React from "react";

const WinnerScreen = () => {
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <h1>You're the pokemon master!</h1>
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/rg6CiPI6h2g?autoplay=1`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

export default WinnerScreen;
