import React from "react";
import { Card, Image, Text } from "@mantine/core";
import { Project } from "./constants";

interface ProjectCardProps {
  project: Project;
}

const ProjectCard = ({ project } : ProjectCardProps) => {
  return (
    <Card
      component="a"
      href={project.href}
      p="xl"
      target="_blank"
      shadow="sm"
      radius="md"
      withBorder
    >
      <Card.Section>
        <Image width="100%" src={project.imageSrc} alt="Project" />
      </Card.Section>
      <Card.Section>
        <Text weight={500}>{project.header}</Text>
      </Card.Section>
      <Card.Section>
        <Text size={"sm"} color="dimmed">
          {project.description}
        </Text>
      </Card.Section>
    </Card>
  );
};

export default ProjectCard;
